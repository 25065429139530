import { API, graphqlOperation } from 'aws-amplify';
import { invokeCopyStepFunctions } from './graphql/mutations';

export const startCopyStepFunctions = async (
  classId,
  submissionId,
  userId,
  cloud_recordings,
  recordingRoomId
) => {
  if (
    classId === undefined ||
    submissionId === undefined ||
    userId === undefined ||
    cloud_recordings === undefined ||
    cloud_recordings.length === 0 ||
    recordingRoomId === undefined
  ) {
    throw new Error(
      'Missing one or more required parameters: classId, submissionId, userId, cloud_recordings'
    );
  }

  const { data } = await API.graphql(
    graphqlOperation(invokeCopyStepFunctions, {
      input: {
        classId,
        submissionId,
        userId,
        cloud_recordings,
        recordingRoomId,
      },
    })
  );

  return data;
};
