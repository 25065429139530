import { API, graphqlOperation } from 'aws-amplify';
import { getUserGiftSubscriptions } from './graphql/queries';
import { modifyGiftSubscription } from './graphql/mutations';

export const fetchPurchasedGifts = async () => {
  const { data } = await API.graphql(graphqlOperation(getUserGiftSubscriptions));
  return data.getUserGiftSubscriptions;
};

export const updateGiftDetails = async (giftUpdated) => {
  const { data } = await API.graphql(graphqlOperation(
    modifyGiftSubscription,
    {
      input: giftUpdated,
    })
  );
  return data.modifyGiftSubscription;
};
