import React from 'react';

export const HeartIcon = ({ className, width, height, active }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 34 31"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <path
        d="M634.500217,21 C634.500217,21 646,13.2036203 646,5.82564414 C646,-1.55271237 635.796212,-2.12021974 634.500217,5.25851714 C633.203788,-2.12021974 623,-1.55271237 623,6.39353188 C623,14.3397761 634.500217,21 634.500217,21 Z"
        id="path-1"
      />
      <filter
        x="-33.1%"
        y="-31.5%"
        width="166.2%"
        height="172.5%"
        filterUnits="objectBoundingBox"
        id="filter-2"
      >
        <feMorphology
          radius="1.11375"
          operator="dilate"
          in="SourceAlpha"
          result="shadowSpreadOuter1"
        />
        <feOffset dx="0" dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feMorphology radius="1.11375" operator="erode" in="SourceAlpha" result="shadowInner" />
        <feOffset dx="0" dy="1" in="shadowInner" result="shadowInner" />
        <feComposite
          in="shadowOffsetOuter1"
          in2="shadowInner"
          operator="out"
          result="shadowOffsetOuter1"
        />
        <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.3 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
    </defs>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinejoin="round"
    >
      <g id="Header" transform="translate(-655.000000, -16.000000)">
        <g id="Video-Data">
          <g transform="translate(37.000000, 20.000000)">
            <g id="heart">
              <use fill="black" fillOpacity="1" filter="url(#filter-2)" xlinkHref="#path-1" />
              <use
                stroke="#FFFFFF"
                fill={active ? '#FFFFFF' : 'none'}
                strokeWidth="2.2275"
                xlinkHref="#path-1"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
