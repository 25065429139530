import { types } from 'mobx-state-tree';
import { fetchLinkPreview } from 'api';
import { LinkPreview } from './models';

export const LinkPreviewStore = types
  .model('LinkPreviewStore', {
    links: types.optional(types.array(types.maybeNull(LinkPreview)), []),
    loading: types.optional(types.array(types.boolean), []),
  })
  .actions(self => ({
    addNewLink() {
      const index = self.loading.length;
      self.loading.push(false);
      self.links.push(null);
      return index;
    },

    removeLink(index) {
      self.loading.splice(index, 1);
      self.links.splice(index, 1);
    },

    async fetchLink(index, url) {
      self.loading[index] = true;
      try {
        const response = await fetchLinkPreview(url);
        self.fetchLinkPreviewSuccess(response, index);
      } catch (error) {
        self.fetchLinkPreviewFailure(index);
      }
    },

    fetchLinkPreviewSuccess(data, index) {
      self.loading[index] = false;
      self.links[index] = data;
    },

    fetchLinkPreviewFailure(index) {
      self.loading[index] = false;
    },
  }));
