import { createContext, useContext } from 'react';
import { enableStaticRendering } from 'mobx-react-lite';
import { types } from 'mobx-state-tree';

import { ClassStore } from 'store/ClassStore';
// import { RecommendedClassStore } from 'store/RecommendedClassStore';
import { NotificationStore } from 'store/NotificationStore';
import { CommunityStore } from 'store/CommunityStore';
import { QuestionStore } from 'store/QuestionStore';
import { LinkPreviewStore } from 'store/LinkPreviewStore';
import { VideoStore } from 'store/VideoStore';
import { QAStore } from 'store/QAStore';
import { ClassDetailsStore } from 'store/ClassDetailsStore';
import { AssignmentsStore } from 'store/AssignmentsStore';
import { DashboardStore } from 'store/DashboardStore';
import { QuizStore } from 'store/QuizStore';
import { InstrAssignmentStore } from 'store/InstrAssignmentStore';
import { UserStore } from 'store/UserStore';
import { GiftsStore } from 'store/GiftsStore';
import { LayoutStore } from 'store/LayoutStore';
import { VideoAssignmentSubmissionStore } from 'store/VideoAssignmentSubmissionStore';
import { HccAiStore } from 'store/HccAiStore';

const RootModel = types.model({
  classStore: types.optional(ClassStore, {}),
  dashboardStore: types.optional(DashboardStore, {}),
  // recommendedClassStore: types.optional(RecommendedClassStore, {}),
  notificationStore: types.optional(NotificationStore, {}),
  communityStore: types.optional(CommunityStore, {}),
  questionStore: types.optional(QuestionStore, {}),
  linkPreviewStore: types.optional(LinkPreviewStore, {}),
  videoStore: types.optional(VideoStore, {}),
  qaStore: types.optional(QAStore, {}),
  classDetailsStore: types.optional(ClassDetailsStore, {}),
  assignmentsStore: types.optional(AssignmentsStore, {}),
  quizStore: types.optional(QuizStore, {}),
  instrAssignmentStore: types.optional(InstrAssignmentStore, {}),
  userStore: types.optional(UserStore, {}),
  giftsStore: types.optional(GiftsStore, {}),
  layoutStore: types.optional(LayoutStore, {}),
  videoAssignmentSubmissionStore: types.optional(VideoAssignmentSubmissionStore, {}),
  hccAiStore: types.optional(HccAiStore, {}),
});

enableStaticRendering(typeof window === 'undefined');

export const rootStore = RootModel.create();

const StoreContext = createContext();
export const { Provider } = StoreContext;

export const useStore = () => {
  const store = useContext(StoreContext);
  return store;
};
