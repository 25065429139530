import { types } from 'mobx-state-tree';

const FreeTrialInfo = types
  .model({
    activated: types.boolean,
    totalSecondsConsumed: types.number,
    token: types.maybeNull(types.string),
    freeTrialLimit: 0,
  })
  .views(self => ({
    get expired() {
      return self.totalSecondsConsumed >= self.freeTrialLimit;
    },

    get secondsRemaining() {
      return Math.max(0, self.freeTrialLimit - self.totalSecondsConsumed);
    },
  }));

export default FreeTrialInfo;
