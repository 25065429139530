import React from 'react';

export const HexagonIcon = ({ width, height }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 41" width={width} height={height}>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-1184 1)" stroke="currentColor" strokeWidth="1.25">
        <g>
          <g transform="translate(1183)">
            <polygon points="19.5 0 36.387 9.75 36.387 29.25 19.5 39 2.6125 29.25 2.6125 9.75" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
