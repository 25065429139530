import { types } from 'mobx-state-tree';

const LessonInstance = types.model({
  id: types.string,
  link: types.maybeNull(types.string),
  host: types.maybeNull(types.string),
  startDate: types.maybeNull(types.string),
  videoId: types.maybeNull(types.string),
  mediaUrl: types.maybeNull(types.string),
});

export default LessonInstance;
