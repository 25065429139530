import React from 'react';

export const IconFood = ({ width, height, title }) => (
  <svg viewBox="0 0 16.69 16" xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
    {title && <title>{title}</title>}
    <path
      fill="currentColor"
      d="M16.69 9.64A4.78 4.78 0 0112 14.5a4.5 4.5 0 01-1.75-.35.2.2 0 00-.23.06A4.55 4.55 0 016.41 16a4.63 4.63 0 01-2-.48 4.93 4.93 0 01-2.51-2.74 5 5 0 01-.21-2.73 2.84 2.84 0 00-.18-1.74l-.17-.37a.27.27 0 01.14-.36l.74-.33a.27.27 0 01.36.14l.17.37A4.19 4.19 0 013 10.32a3.62 3.62 0 00.16 2A3.58 3.58 0 005 14.3a3.23 3.23 0 002.55.14A3.43 3.43 0 009.43 10a3.6 3.6 0 00-1.32-1.72 4.13 4.13 0 01-1.63-2.4 4 4 0 00-.13-.43c-.57-1.53-1.92-2.44-3-2a1.75 1.75 0 00-1.18 1.64 2.28 2.28 0 01-.07 1A1.45 1.45 0 01.76 7H.35a.27.27 0 01-.29-.25L0 6a.27.27 0 01.25-.29h.51a.26.26 0 00.08-.19v-.34a3.09 3.09 0 012-3c1.82-.7 3.96.57 4.78 2.82a5.41 5.41 0 01.18.57 2.77 2.77 0 001.08 1.6 5 5 0 011.9 5.58.15.15 0 00.08.19 3.16 3.16 0 001.14.21 3.43 3.43 0 003.33-3.51 3.6 3.6 0 00-.64-2.07A4.13 4.13 0 0114 4.76a4.07 4.07 0 000-.45c0-1.63-1-3-2.12-3s-2.12 1.33-2.12 3a4 4 0 000 .59 6.9 6.9 0 010 1.17l-.06.37a.18.18 0 01-.3.13L8.65 6a.48.48 0 01-.19-.38 4.47 4.47 0 000-.53 5.4 5.4 0 01-.06-.79C8.42 1.93 10 0 11.9 0s3.48 1.93 3.48 4.31a5.45 5.45 0 010 .6 2.77 2.77 0 00.46 1.89 5 5 0 01.85 2.84zM4 11.61a4.1 4.1 0 01-.21-2.24 2.08 2.08 0 011.29-1.59c1.22-.45 2.66.54 3.29 2.22s.17 3.39-1 3.84a1.74 1.74 0 01-.6.11 2.29 2.29 0 01-1.38-.51A4.1 4.1 0 014 11.61zm1.27-.47a2.77 2.77 0 00.85 1.23.78.78 0 00.69.21.85.85 0 00.41-.71 2.84 2.84 0 00-.17-1.39C6.76 9.57 6.12 9 5.69 9h-.14a.78.78 0 00-.39.61 2.78 2.78 0 00.15 1.54z"
    />
  </svg>
);
