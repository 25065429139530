import React from 'react';

export const IconMeditation = ({ width, height, title }) => (
  <svg viewBox="0 0 19.27 16" xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
    {title && <title>{title}</title>}
    <path
      fill="currentColor"
      d="M18.37 6.83l-.49-.19a.28.28 0 00-.35.14l-.34.73a.24.24 0 00.14.34l.47.15-.07.09A5.41 5.41 0 0113.6 10h-.06a5.73 5.73 0 002.23-1.54 6.41 6.41 0 001.45-4.56.67.67 0 00-.65-.6 10.05 10.05 0 00-3.71.7.08.08 0 01-.07 0 .08.08 0 010-.07C12.47 1.58 10.06.15 10 .09a.66.66 0 00-.57 0 4.65 4.65 0 00-2.38 2.19l-.18.37A.26.26 0 007 3l.73.35a.26.26 0 00.35-.12l.18-.37a3.34 3.34 0 011.19-1.34.24.24 0 01.25 0 3.74 3.74 0 011.73 3.17v.1a.46.46 0 01-.14.18 4.72 4.72 0 00-.52.52 4.45 4.45 0 00-.87 1.58A4.75 4.75 0 008.6 4.9C6.55 3 3.24 3.3 3.1 3.31a.67.67 0 00-.58.53A5.41 5.41 0 002.42 5a.2.2 0 01-.14.18l-.53.18a3.34 3.34 0 01-1.08.27H.26a.26.26 0 00-.26.29v.81A.26.26 0 00.26 7h.41a4.4 4.4 0 001.55-.36l.32-.12a.14.14 0 01.17.09 3.63 3.63 0 00.57 1 4.66 4.66 0 003.84 1.5h.41a.26.26 0 00.26-.26V8a.26.26 0 00-.26-.26h-.42a3.42 3.42 0 01-2.8-1 3.06 3.06 0 01-.56-2 .15.15 0 01.14-.13 5.68 5.68 0 013.79 1.26 4.24 4.24 0 011.1 3.5.5.5 0 01-.15.07c-3.63 1.19-5.5-.2-6.48-1.57a.42.42 0 00-.37-.16l-.94.12a.18.18 0 00-.13.29l.22.34a6.18 6.18 0 002.36 2.13 6.31 6.31 0 002.88.66 7.8 7.8 0 00.83-.06v.05a2.41 2.41 0 00-.22 1 3.72 3.72 0 001.43 2.66 8.28 8.28 0 001.33 1 .67.67 0 00.57 0 4.63 4.63 0 002.71-3l.1-.52a.27.27 0 00-.21-.31L12 12a.25.25 0 00-.31.2l-.1.52a3 3 0 01-1.47 1.8.27.27 0 01-.26 0c-.39-.28-1.68-1.27-1.68-2.24 0-.56.47-1.12 1.4-1.65l.12-.07.12-.07.16.05.17.07a7.7 7.7 0 006.76-.14 6.61 6.61 0 002.24-2 .67.67 0 00.1-.32 1.38 1.38 0 00-.88-1.32zm-2.6-2.16a.1.1 0 01.1.11 4.81 4.81 0 01-1.11 2.76 5.31 5.31 0 01-3.61 1.61A.13.13 0 0111 9a3.59 3.59 0 01.76-2.68 6.15 6.15 0 014-1.68z"
    />
  </svg>
);
