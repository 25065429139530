import { types, getRoot } from 'mobx-state-tree';
import {
  fetchClassesTeaching,
  fetchIsUserAdmin,
  fetchUserClasses,
  fetchUserClassesToPurchase,
  fetchUserClassesToUnlock,
} from 'api';
import handleApiError from 'utils/handleApiError';
import { ApiError, FavoriteLesson, PurchaseClass, TeacherClass, Notification } from './models';

export const DashboardStore = types
  .model('DashboardStore', {
    classIds: types.optional(types.array(types.number), []),
    error: types.maybeNull(ApiError),
    loading: false,
    areClassesTeachingLoading: false,
    isIsAdminLoading: false,
    loaded: false,
    loadedExtra: false, // true, if classesToUnlock/Purchase is loaded
    libraryAccess: false,
    isAdmin: false, // true, if a logged-in user is an admin
    favoriteLessons: types.optional(types.array(FavoriteLesson), []),
    favoriteLessonCIds: types.optional(types.array(types.number), []),
    classesToUnlock: types.maybeNull(types.array(PurchaseClass)),
    classesToPurchase: types.maybeNull(types.array(PurchaseClass)),
    classesTeaching: types.maybeNull(types.array(TeacherClass)),
    notifications: types.maybeNull(
      types.model({
        read: types.maybeNull(types.array(Notification)),
        unread: types.maybeNull(types.array(Notification)),
      })
    ),
  })
  .views((self) => ({
    get classes() {
      const { classStore } = getRoot(self);
      return self.classIds.map((classId) => classStore.classesById.get(classId));
    },
    get favoriteLessonClasses() {
      const { classStore } = getRoot(self);
      return self.favoriteLessonCIds.map((classId) =>
        classStore.favoriteLessonClasses.get(classId)
      );
    },
    get isInstructor() {
      return self.classesTeaching ? self.classesTeaching.length > 0 : undefined;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      /* methods to call when the app mounts */
      self.fetchClassesTeaching();
      self.fetchIsAdmin();
    },

    async fetchClassesTeaching() {
      self.setAreClassesTeachingLoading(true);

      try {
        const response = await fetchClassesTeaching();
        if (response) {
          self.fetchClassesTeachingSuccess(response);
        }
      } catch (error) {
        console.error(error);
        self.fetchDataError(error);
      } finally {
        self.setAreClassesTeachingLoading(false);
      }
    },

    fetchClassesTeachingSuccess({ classesTeaching, favoriteLessons }) {
      const { classStore } = getRoot(self);

      classesTeaching.forEach((cls) => {
        cls.favoriteLessons = favoriteLessons.filter((l) => l.classId === cls.id);
        classStore.putFavoriteLessonClass(cls);
        self.favoriteLessonCIds.push(cls.id);
      });
      self.classesTeaching = classesTeaching;
    },

    async fetchIsAdmin() {
      self.setIsAdminLoading(true);

      try {
        // const isAdmin = await fetchIsUserAdmin();
        // self.setIsAdmin(isAdmin);
      } catch (error) {
        console.error(error);
        self.fetchDataError(error);
      } finally {
        self.setIsAdminLoading(false);
      }
    },

    setIsAdmin(isAdmin) {
      self.isAdmin = isAdmin;
    },

    async fetchData() {
      self.loading = true;
      try {
        const response = await fetchUserClasses();
        self.fetchDataSuccess(response);
      } catch (error) {
        console.error(error);
        self.fetchDataError(error);
      }
    },

    fetchDataSuccess({ classesTaking, favoriteLessons, libraryAccess, notifications }) {
      const { classStore } = getRoot(self);
      console.log('hre');
      classesTaking.forEach((cls) => {
        cls.favoriteLessons = favoriteLessons.filter((l) => l.classId === cls.id);
        classStore.putClass(cls);
        self.classIds.push(cls.id);
        classStore.putFavoriteLessonClass(cls);
        self.favoriteLessonCIds.push(cls.id);
      });

      self.notifications = notifications;
      self.libraryAccess = libraryAccess;
      self.favoriteLessons = favoriteLessons;
      self.error = null;
      self.loading = false;
      self.loaded = true;
    },

    fetchDataError(error) {
      self.error = handleApiError(error);
      self.loading = false;
    },

    async fetchUserClassesToUnlock() {
      try {
        if (self.libraryAccess || self.isAdmin) {
          const data = await fetchUserClassesToUnlock();
          this.setClassesToUnlockSuccess(data);
          return data;
        }
        return [];
      } catch (error) {
        console.error(error);
        // self.fetchDataError(error);
        return [];
      }
    },

    setClassesToUnlockSuccess(data) {
      self.classesToUnlock = data;
      self.loadedExtra = true;
    },

    async fetchUserClassesToPurchase() {
      try {
        const data = await fetchUserClassesToPurchase();
        this.setClassesToPurchaseSuccess(data);
        return data;
      } catch (error) {
        console.error(error);
        return [];
      }
    },

    setClassesToPurchaseSuccess(data) {
      try {
        const { classStore } = getRoot(self);
        data.forEach((cls) => {
          cls.favoriteLessons = self.favoriteLessons.filter((l) => cls.id === l.classId);
          classStore.putFavoriteLessonClass(cls);
          self.favoriteLessonCIds.push(cls.id);
          // admins have access to all classes, so store these in the classes map to avoid re-fetching them
          if (self.isAdmin) classStore.putClass(cls);
        });
      } catch (err) {
        // ignore
        console.error(err);
      }

      self.classesToPurchase = data;
      self.loadedExtra = true;
    },

    addToMyClasses(cls) {
      const isAdded = self.classIds.includes(cls.id);
      if (isAdded) return;
      self.classIds.unshift(cls.id);
      cls.setClassStarted();
    },

    pushFavoriteLesson(lesson) {
      self.favoriteLessons.push(lesson);
    },

    removeFavoriteLesson(lesson) {
      self.favoriteLessons = self.favoriteLessons.filter((l) => l.id !== lesson.id);
    },

    clearUnreadNotifications() {
      if (!self.notifications) return;

      if (!self.notifications.read) {
        self.notifications.read = [];
      }

      self.notifications.read.concat([...self.notifications.unread]);
      self.notifications.unread = [];
    },

    setAreClassesTeachingLoading(nextState) {
      self.areClassesTeachingLoading = nextState;
    },

    setIsAdminLoading(nextState) {
      self.isAdminLoading = nextState;
    },
  }));
