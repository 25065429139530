import { types } from 'mobx-state-tree';

const Transcript = types.model({
  id: types.string,
  question: types.string,
  response: types.maybeNull(types.string),
  userFeedback: types.maybeNull(types.enumeration('Feedback', ['positive', 'negative'])),
});

const ChatSession = types.model({
  sessionId: types.maybeNull(types.string),
  userId: types.maybeNull(types.number),
  email: types.maybeNull(types.string),
  transcript: types.array(Transcript),
  responses: types.number,
  likes: types.number,
  dislikes: types.number,
  botName: types.maybeNull(types.string),
  environment: types.maybeNull(types.string),
});

export default ChatSession;
