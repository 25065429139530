import { types } from 'mobx-state-tree';

const Instructor = types
  .model({
    id: types.number,
    name: types.model({
      credentials: types.maybeNull(types.string),
      first: types.maybeNull(types.string),
      last: types.maybeNull(types.string),
      nickname: types.maybeNull(types.string),
      fullName: types.maybeNull(types.string),
    }),
    image: types.maybeNull(types.string),
  })
  .views(self => ({
    get fullName() {
      if(self.name.fullName){
        return `${self.name.fullName}`;
      }

      if(!self.name.first && !self.name.last) {
        return `${self.name.nickname}` || '';
      }

      return `${self.name.first} ${self.name.last || ''}`;
    },
  }));

export default Instructor;
