import { types } from 'mobx-state-tree';

export default types
  .model({
    id: types.number,
    urlKey: types.string,
    title: types.string,
    image: types.model({
      path: types.string,
    }),
    type: types.string,
    unreadAssignmentsCount: types.maybeNull(types.number),
  })
  .views(self => ({
    get imageUrl() {
      return `https://res.mindbodygreen.com/${self.image.path}`;
    },
  }));
