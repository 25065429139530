import { API, graphqlOperation } from 'aws-amplify';
import { getQuestions } from './graphql/queries';
import axios from './axios';

export const fetchQuestionsForClass = async (classId, sortBy, moduleId, cursor) => {
  const { data } = await API.graphql(
    graphqlOperation(getQuestions, { classId, sortBy, size: 10, moduleId, cursor })
  );

  return data.getQuestions;
};

export const fetchAnswersForQuestion = async (classSlug, questionId) => {
  const { data } = await axios(
    `${process.env.API_URL}/classes/${classSlug}/questions/${questionId}/answers`
  );
  return data;
};

export const createQuestionForClass = async (slug, body) => {
  const { data } = await axios.post(`${process.env.API_URL}/classes/${slug}/questions`, body);
  return data;
};

export const replyToQuestion = async (slug, questionId, body) => {
  const { data } = await axios.post(
    `${process.env.API_URL}/classes/${slug}/questions/${questionId}/answers`,
    body
  );

  return data;
};
