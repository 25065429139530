import styled from 'styled-components';
import { media } from 'utils';

export const Container = styled.footer`
  background: ${props => props.theme.colors.standard.background.footer};
  color: ${props => props.theme.colors.standard.text.inverted};
  text-align: center;
  padding: 4rem 4rem 1rem 4rem;
  max-width: 144rem;
  margin: 0 auto;

  ${media.mobile`
    padding: 6rem 2rem 1rem 2rem;
  `}
`;

export const Links = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 2rem 0;

  a {
    text-transform: uppercase;
    text-decoration: none;
    color: #bbb;
    font-size: 1.2rem;
    font-family: Maax, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 500;
    line-height: 1.2rem;
    margin: 1rem 2rem;
  }

  ${media.desktop`
    max-width: 144rem;
  `}
`;

export const UtilityLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 2rem 0;

  a {
    text-decoration: none;
    color: #bbb;
    font-size: 1.1rem;
    font-family: Maax, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 140%;
    margin: 1rem 2rem;
  }

  ${media.desktop`
    max-width: 144rem;
  `}
`;

export const MBGLogo = styled.div`
  font-family: Monoline, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 2.7rem;
  ::after {
    content: 'Mindbodygreen';
  }
`;
