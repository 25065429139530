import { css } from 'styled-components';
import appTheme from '../appTheme';

// https://www.styled-components.com/docs/advanced#media-templates
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const media: any = Object.keys(appTheme.breakpoints).reduce((acc, label: string) => {
  acc[label] = (...args) => css`
    @media (max-width: ${appTheme.breakpoints[label] / 16}em) {
      ${css(...(args as [TemplateStringsArray, ...string[]]))}
    }
  `;

  return acc;
}, {});

export default media;
