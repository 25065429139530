import { types, getRoot } from 'mobx-state-tree';
import { fetchAssessmentProgress, saveAssessmentAnswer, markAssessmentCompleted } from 'api';
import Router from 'next/router';

import { AssessmentProgress } from './models';

export const QuizStore = types
  .model('QuizStore', {
    quizLessonIdx: -1,
    moduleIndex: -1,
    nestedModuleIndex: -1,
    currentLessonId: types.maybeNull(types.string),
    assessmentProgress: types.maybeNull(AssessmentProgress),
    loadingProgress: false,
    currentQuestionIdx: -1,
    savingAnswer: false,
    isQuizActive: false,
    currentQuizToRefetch: types.maybeNull(types.string),
  })
  .views((self) => ({
    get quizLesson() {
      if (self.quizLessonIdx < 0) {
        return null;
      }
      const {
        classDetailsStore: { activeClass },
      } = getRoot(self);

      return activeClass.allLessons[self.quizLessonIdx];
    },

    get currentQuestion() {
      if (self.currentQuestionIdx < 0) {
        return null;
      }

      return self.quizLesson.assessmentQuestions[self.currentQuestionIdx];
    },

    get currentQuestionCompleted() {
      const { currentQuestion, assessmentProgress } = self;
      if (!currentQuestion || !assessmentProgress) {
        return false;
      }

      return assessmentProgress.progressMap.find((p) => p.questionId === currentQuestion.id);
    },
  }))
  .actions((self) => ({
    updateDetails(newIdx) {
      const { classDetailsStore } = getRoot(self);
      self.quizLessonIdx = newIdx;

      const lesson = classDetailsStore.activeClass.allLessons[newIdx];

      lesson.randomizeQuizAnswers();

      self.moduleIndex = classDetailsStore.activeClass.modules.findIndex((m) =>
        m.containsLessonId(lesson.id)
      );
      const currentModule =
        self.moduleIndex !== -1 ? classDetailsStore.activeClass.modules[self.moduleIndex] : null;

      self.nestedModuleIndex = currentModule?.modules?.length
        ? currentModule.modules.findIndex((m) => m.containsLessonId(lesson.id))
        : -1;
      self.currentLessonId = lesson.id;
    },

    init(initialLessonId) {
      const {
        classDetailsStore: { activeClass },
      } = getRoot(self);

      let idx = activeClass.nextQuizIdx;
      if (initialLessonId) {
        if (activeClass.isLessonViewableMap[initialLessonId]) {
          idx = activeClass.allLessons.findIndex((l) => l.id === initialLessonId);
        } else {
          Router.replace('/user/classes/[slug]/quiz', `/user/classes/${activeClass.urlKey}/quiz`, {
            shallow: true,
          });
        }
      }

      if (idx < 0) {
        Router.replace('/user/classes/[slug]', `/user/classes/${activeClass.urlKey}`);
      } else {
        self.updateDetails(idx);
      }
    },

    async fetchProgress(lessonId) {
      self.loadingProgress = true;
      const {
        classDetailsStore: { activeClass },
      } = getRoot(self);

      try {
        const data = await fetchAssessmentProgress(
          activeClass.id,
          lessonId,
          activeClass.term ? activeClass.term.id : undefined
        );
        self.fetchSuccess(data);
      } catch (error) {
        console.error(error);
        self.fetchFailure();
      }
    },

    fetchSuccess(data) {
      self.loadingProgress = false;
      self.assessmentProgress = data;
    },

    fetchFailure() {
      self.loadingProgress = false;
    },

    nextQuestion() {
      self.currentQuestionIdx++; // eslint-disable-line
    },

    prevQuestion() {
      self.currentQuestionIdx--; // eslint-disable-line
    },

    restoreLastQuestion() {
      self.currentQuestionIdx = self.assessmentProgress.progressMap.length;
    },

    finish() {
      self.currentQuestionIdx = -1;
    },

    async saveAnswer(answerId) {
      const {
        classDetailsStore: { activeClass },
      } = getRoot(self);

      self.savingAnswer = true;

      try {
        const data = await saveAssessmentAnswer(
          activeClass.id,
          self.quizLesson.moduleId,
          self.quizLesson.id,
          self.currentQuestion.id,
          answerId,
          activeClass.term ? activeClass.term.id : undefined,
          self.assessmentProgress ? self.assessmentProgress.id : null
        );

        // if last question, get the overall score by fetching progress again
        if (self.currentQuestionIdx >= self.quizLesson.assessmentQuestions.length - 1) {
          const result = await fetchAssessmentProgress(
            activeClass.id,
            self.quizLesson.id,
            activeClass.term ? activeClass.term.id : undefined
          );

          if (result.passed) {
            const response = await markAssessmentCompleted(
              activeClass.id,
              self.quizLesson.id,
              activeClass.term ? activeClass.term.id : undefined
            );

            // mark quiz as completed
            activeClass.markLessonCompleted(self.quizLessonIdx);

            // mark quiz as current lesson
            activeClass.changeCurrentLesson(response.currentSessionId);

            // update class progress
            activeClass.updateProgress(response.totalProgress);

            // add new notification if needed
            if (response.newUnreadNotification) {
              activeClass.addUnreadNotification(response.newUnreadNotification);
            }
          }

          self.fetchSuccess(result);
        }

        self.saveAnswerSuccess(data);
      } catch (error) {
        console.error(error);
      }
    },

    saveAnswerSuccess({ id, correctAnswerId, questionId, userAnswerId }) {
      self.savingAnswer = false;

      // we get updated progress map so don't add the last question
      if (self.currentQuestionIdx >= self.quizLesson.assessmentQuestions.length - 1) return;

      if (!self.assessmentProgress) {
        self.assessmentProgress = {
          id,
          progressMap: [{ questionId, correctAnswerId, userAnswerId }],
        };
      } else {
        self.assessmentProgress.addProgressEntry({ questionId, userAnswerId, correctAnswerId });
      }
    },

    saveAnswerFailure() {
      self.savingAnswer = false;
    },

    retakeQuiz() {
      self.assessmentProgress = null;
      self.currentQuestionIdx = 0;
    },

    setQuizActiveStatus(status) {
      self.isQuizActive = status;
    },

    setQuizToRefetch(lessonId) {
      self.currentQuizToRefetch = lessonId ? lessonId : null;
    },
  }));
