import { types, getRoot, detach } from 'mobx-state-tree';
import { compareDesc } from 'date-fns';
import {
  fetchVideoAssignmentSubmissions,
  addVideoAssignmentSubmission,
  editVideoAssignmentSubmission,
  addVideoFromCloudinaryToSubmission as addVideoFromCloudinary,
} from 'api';
import { segmentEvent } from 'utils/segmentTrackers';
import VideoAssignmentSubmission from './models/VideoAssignmentSubmission';

export const VideoAssignmentSubmissionStore = types
  .model('VideoAssignmentSubmissionStore', {
    loading: false,
    submitting: false,
    submitSuccess: false,
    submitFailure: false,
    videoAssignmentSubmissions: types.maybeNull(types.array(VideoAssignmentSubmission)),
  })
  .actions((self) => ({
    // async submit(moduleId, lessonId, questionId, response) {
    //   const {
    //     classDetailsStore: { activeClass },
    //   } = getRoot(self);
    //   self.submitting = true;
    //   self.submitSuccess = false;
    //   self.submitFailure = false;

    //   try {
    //     const { id, currentSessionId, newUnreadNotification, totalProgress } =
    //       await submitAssignment(
    //         activeClass.id,
    //         moduleId,
    //         lessonId,
    //         questionId,
    //         activeClass.term ? activeClass.term.id : undefined,
    //         response
    //       );
    //     activeClass.changeCurrentLesson(currentSessionId);
    //     activeClass.updateProgress(totalProgress);
    //     if (newUnreadNotification) activeClass.addUnreadNotification(newUnreadNotification);

    //     self.onSubmitSuccess(activeClass.id, moduleId, lessonId, questionId, id, response);
    //   } catch (error) {
    //     self.onSubmitFailure();
    //     console.error(error);
    //   }
    // },

    /* eslint-disable no-shadow */
    // onSubmitSuccess(classId, moduleId, lessonId, questionId, assignmentId, response) {
    //   self.submitting = false;
    //   self.submitSuccess = true;

    //   // Segment Analytics
    //   segmentEvent('Class Assignment Submitted', {
    //     classId,
    //     sessionId: lessonId,
    //   });

    //   // mark lesson as completed
    //   const {
    //     classDetailsStore: { activeClass },
    //   } = getRoot(self);
    //   const idx = activeClass.allLessons.findIndex((l) => l.id === lessonId);
    //   activeClass.markLessonCompleted(idx);

    //   // add to submitted assignments
    //   if (!self.submittedAssignments) {
    //     self.submittedAssignments = [];
    //   }

    //   self.submittedAssignments.push({
    //     id: assignmentId,
    //     status: 'submitted',
    //     response,
    //     moduleId,
    //     createDate: new Date().toISOString(),
    //     questionTitle: activeClass.allLessons[idx].assignmentQuestions.find(
    //       (aq) => aq.id === questionId
    //     ).questionText,
    //     user: { username: 'me' },
    //     termId: activeClass.term ? activeClass.term.id : undefined,
    //     feedback: [],
    //     readByFaculty: false,
    //   });
    // },

    // onSubmitFailure() {
    //   self.submitting = false;
    //   self.submitFailure = true;
    // },

    async loadVideoAssignmentSubmissions() {
      try {
        self.loading = true;
        self.refetchVideoAssignmentSubmission();
      } catch (error) {
        console.error(error);
      }
    },

    async refetchVideoAssignmentSubmission() {
      const {
        classDetailsStore: { activeClass },
      } = getRoot(self);
      const data = await fetchVideoAssignmentSubmissions(activeClass.id);
      self.submittedVideoAssignmentSubmissionSuccess(data);
    },

    submittedVideoAssignmentSubmissionSuccess(data) {
      if (self.videoAssignmentSubmissions) {
        detach(self.videoAssignmentSubmissions);
      }
      self.videoAssignmentSubmissions = data;
      self.loading = false;
    },

    // submittedAssignmentsByModule(moduleId) {
    //   if (!self.submittedAssignments) return [];

    //   return self.submittedAssignments
    //     .filter((a) => a.moduleId === moduleId)
    //     .sort((a, b) => compareDesc(new Date(a.createDate), new Date(b.createDate)));
    // },

    // // userId is not sent, so that the current user is used
    // async addFeedback(assignmentId, feedback) {
    //   self.savedAssignmentId = null;
    //   self.savingAssignmentId = assignmentId;

    //   const {
    //     classDetailsStore: { activeClass },
    //   } = getRoot(self);

    //   try {
    //     await addAssignmentFeedback(activeClass.id, assignmentId, feedback, undefined);
    //     self.addFeedbackSuccess(assignmentId, feedback);
    //   } catch (error) {
    //     console.error(error);
    //     self.addFeedbackFailure();
    //   }
    // },

    async addVideoAssignmentSubmission(classId, assignmentId) {
      try {
        await addVideoAssignmentSubmission(classId, assignmentId);
      } catch (error) {
        console.error(error);
      }
    },

    async addVideoToVideoAssignmentSubmission(submittedVideoId, submissionId) {
      try {
        await editVideoAssignmentSubmission(submittedVideoId, submissionId);
      } catch (error) {
        console.error(error);
      }
    },

    async addVideoFromCloudinaryToSubmission(video, submissionId, userId) {
      try {
        await addVideoFromCloudinary(video, submissionId, userId);
      } catch (error) {
        console.error(error);
      }
    },

    // addFeedbackFailure() {
    //   self.savedAssignmentId = null;
    //   self.savingAssignmentId = null;
    // },
  }));
