import { types } from 'mobx-state-tree';
import { fetchPurchasedGifts } from 'api';
import PurchasedGift from './models/PurchasedGift';

export const GiftsStore = types
  .model('GiftsStore', {
    loaded: false,
    gifts: types.optional(types.array(PurchasedGift), []),
  })
  .actions(self => ({
    async fetchGifts() {
      try {
        const data = await fetchPurchasedGifts();
        self.fetchGiftsSuccess(data);
      } catch (error) {
        if (error.response.status === 401) {
          window.location.href = error.response.data.redirect;
        }
        console.error(error);
      }
    },

    fetchGiftsSuccess(data) {
      self.gifts = data;
      self.loaded = true;
    },
  }));
