import React from 'react';

export const PlayerMenuIcon = ({ width, height }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 30 25">
    <defs>
      <linearGradient id="a" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#FFF" stopOpacity=".5" />
        <stop offset="100%" stopOpacity=".5" />
      </linearGradient>
    </defs>
    <path
      fill="url(#a)"
      fillRule="evenodd"
      stroke="#828282"
      strokeLinecap="square"
      strokeWidth="3.5"
      d="M896.3 22.75h20.2-20.2zm24.5 0h4.7-4.7zm-24.5 21.5h20.2-20.2zm24.5 0h4.7-4.7zM896.3 33.5h20.2-20.2zm24.5 0h4.7-4.7z"
      transform="translate(-896 -21)"
    />
  </svg>
);
