import { types } from 'mobx-state-tree';

const Resource = types.model({
  id: types.string,
  url: types.string,
  title: types.string,
  termId: types.maybeNull(types.number),
  featureOnDashboard: types.maybeNull(types.boolean),
});

export default Resource;
