import { types, getRoot } from 'mobx-state-tree';
import { fetchQuestionsForClass, createQuestionForClass, replyToQuestion } from 'api';
import handleApiError from 'utils/handleApiError';
import { Question, ApiError } from './models';

export const QuestionStore = types
  .model('QuestionStore', {
    questions: types.maybeNull(types.array(Question)),
    loading: false,
    error: types.maybeNull(ApiError),
    cursor: types.maybeNull(types.string),
    hasMoreQuestions: false,
    questionsFilter: types.maybeNull(types.string),
    questionsSort: 'recommended',
    saving: false,
    saveError: types.maybeNull(ApiError),
    replying: false,
  })
  .actions(self => ({
    async loadMoreQuestions() {
      const {
        classDetailsStore: { activeClass },
      } = getRoot(self);

      self.loading = true;
      try {
        const response = await fetchQuestionsForClass(
          activeClass.id,
          self.questionsSort,
          self.questionsFilter,
          self.cursor
        );
        self.fetchQuestionsSuccess(response);
      } catch (error) {
        self.fetchQuestionsError(error);
      }
    },

    fetchQuestionsSuccess({ questions, paginationCursor }) {
      self.cursor = paginationCursor.questionId;
      self.hasMoreQuestions = paginationCursor.hasAfter;
      self.questions = self.questions.concat(questions);
      self.error = null;
      self.loading = false;
    },

    fetchQuestionsError(error) {
      self.error = handleApiError(error);
      self.loading = false;
    },

    reloadQuestions() {
      self.cursor = null;
      self.questions = [];
      return self.loadMoreQuestions();
    },

    updateFilter(filter) {
      self.questionsFilter = filter === 'all' ? null : filter;
      self.reloadQuestions();
    },

    updateSort(sort) {
      self.questionsSort = sort;
      self.reloadQuestions();
    },

    async addNewQuestion({ question, comments, module, linkIndex }) {
      const { classDetailsStore } = getRoot(self);
      self.saving = true;

      try {
        const data = await createQuestionForClass(classDetailsStore.activeClass.urlKey, {
          title: question,
          text: comments,
          moduleId: module,
        });
        self.createQuestionSuccess(data, linkIndex);
      } catch (error) {
        self.createQuestionError(error);
      }
    },

    createQuestionSuccess(data, linkIndex) {
      self.saving = false;
      self.questions.unshift({ ...data, linkIndex });
    },

    createQuestionError(error) {
      self.saving = false;
      self.saveError = error;
    },

    async addReply(questionId, content) {
      const { classDetailsStore } = getRoot(self);
      self.replying = true;

      try {
        const response = await replyToQuestion(classDetailsStore.activeClass.urlKey, questionId, {
          content,
        });
        self.addReplySuccess(questionId, response);
      } catch (error) {
        self.addReplyError(error);
      }
    },

    addReplySuccess(questionId, response) {
      const { answerStore } = getRoot(self);
      self.replying = false;
      answerStore.addAnswer(questionId, response);

      const qIdx = self.questions.findIndex(q => q.id === questionId);
      if (self.questions[qIdx].totalAnswers < 1) {
        self.questions[qIdx].topAnswer = response;
      }

      self.questions[qIdx].totalAnswers++; // eslint-disable-line
    },

    addReplyError() {
      self.replying = false;
    },
  }));
