import styled from 'styled-components';
import { media, when } from 'utils';

export const NavBar = styled.nav`
  background: ${(props) => (props.hcc ? '#fff' : props.theme.colors.classesTeal)};
  color: ${(props) => (props.hcc ? '#222' : props.theme.colors.standard.text.inverted)};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  width: 100%;
  max-width: 144rem;
  margin: 0 auto;
  padding: 1.8rem 3.8rem;

  @media (min-width: 1440px) {
    left: calc(50% - 1440px / 2);
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  > :first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${media.mobile`
      display: none;
    `}
  }

  ${media.mobile`
    background: ${(props) => props.theme.colors.standard.background.page};
    color: ${(props) => props.theme.colors.standard.text.primary};
    border-bottom: 1px solid #e5e5e5;
    padding: 1.8rem;
  `}
`;

export const Logo = styled.div`
  font-size: 2.8rem;
  font-family: Monoline, 'Helvetica Neue', Helvetica, Arial, sans-serif;

  ::before {
    opacity: ${(props) => (props.isHCC ? 1 : 0.5)};
    color: ${(props) => (props.isHCC ? '#164036' : 'inherit')};
    content: 'Mbg';
  }

  ::after {
    content: 'Classes';
  }

  ${media.mobile`
    ::before {
      opacity: 1.0;
    }

    ::after {
      content: "";
    }
  `}
`;

export const Right = styled.div`
  ${media.tablet`
    > :first-child {
      display: none;
    }
  `}

  ${media.mobile`
    display: none;
  `}

  display: flex;
  align-items: center;
  height: 100%;

  > a {
    color: inherit;
    font-family: Monoline, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  svg {
    vertical-align: bottom;
  }

  > * {
    margin-right: 1.6rem;
  }

  :last-child {
    margin-right: 0;
  }
`;

export const AccountSection = styled.div`
  display: flex;
  margin-left: 7rem;

  > * {
    margin-right: 2rem;
  }

  :last-child {
    margin-right: 0;
  }

  ${media.mobile`
    display: none;
  `}
`;

export const MenuLink = styled.a`
  position: relative;
`;

export const MenuDiv = styled.div`
  position: relative;
  cursor: pointer;
`;

export const Menu = styled.ul`
  width: 17rem;
  background: #fff;
  box-shadow: 0 3px 6px 0 rgba(145, 145, 145, 0.35);
  padding: 0.5rem 0;

  /* remove list properties */
  margin: 0;
  padding: 0;
  list-style: none;

  /* position */
  position: absolute;
  top: 3rem;
  left: 0;
  min-width: 100%;

  /* closed by default */
  max-height: 0;
  overflow-y: hidden;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);

  ${when('expanded')`
    max-height: 20rem;
    overflow-y: visible;
  `}

  /* items styling */
  li {
    color: #767676;
    font-size: 1.2rem;
    text-transform: uppercase;
    padding: 0.7rem 1rem;

    &:last-child:hover {
      text-decoration: underline;
    }

    a:hover {
      text-decoration: underline;
    }
  }
`;

export const AccountText = styled.span`
  margin-left: 0.5rem;
  font-size: 1.4rem;
`;

export const MobileNav = styled.div`
  display: none;
  // align-items: center;
  justify-content: space-between;

  ${media.mobile`
    display: flex;
  `}
`;

export const MobileNavLeft = styled.div`
  display: flex;
`;

export const LogoMobile = styled.div`
  display: flex;
  font-size: 2.5rem;
  font-family: Monoline, 'Helvetica Neue', Helvetica, Arial, sans-serif;

  ::before {
    opacity: 1;
    content: 'Mbg';
  }

  ::after {
    content: 'Classes';
    color: ${(props) => (props.isHCC ? '#164036' : props.theme.colors.classesTeal)};
  }
`;

export const MobileNavRight = styled.div`
  display: flex;
  padding-top: 4px;
`;

export const HamburgerToggle = styled.button`
  display: inline-block;
  padding: 0;
  outline: none;

  span {
    display: block;
    background: currentColor;
    width: 2.2rem;
    height: 0.2rem;
    margin: 0.4rem;
    transition: all 0.15s ease-in-out;
  }

  ${when('open')`
    span:first-child {
      transform: translateY(8px) rotate(45deg);
    }

    span:nth-child(2) {
      opacity: 0;
    }

    span:last-child {
      transform: translateY(-4px) rotate(-45deg);
    }
  `}
`;

export const MobileMenu = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.standard.background.page};
  overflow-y: auto;
  z-index: 9;
  padding-top: 62px;
  display: none;
  ${media.mobile`
    display: block;
  `}
`;

export const MobileMenuItems = styled.ul`
  margin: 10px 0 0 0;
  padding: 0;
  list-style: none;
`;

export const MobileMenuItem = styled.li`
  padding: 1.2rem 1.9rem;
  border-bottom: 1.2px solid #f1f1f1;

  ${when('dividerTop')`
    border-top: 1.2px solid #cccccc;
  `}

  span {
    font-size: 1.3rem;
    text-transform: uppercase;
    font-weight: 500;
    font-family: Maax, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: bold;
    letter-spacing: 2.18px;
    color: ${({ theme }) => theme.colors.standard.text.primary};
    cursor: pointer;

    ${when('active')`
      color: ${(props) => props.theme.colors.classesTeal};
    `}
  }

  a {
    font-family: Monoline, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 2.2rem;
    letter-spacing: normal;
    color: ${({ theme }) => theme.colors.standard.text.primary};

    ${when('active')`
      color: ${(props) => props.theme.colors.classesTeal};
    `}
  }
`;
