import { types } from 'mobx-state-tree';
import { parseISO, format, compareAsc, formatDistance } from 'date-fns';
import User from './User';

const Assignment = types
  .model('Assignment', {
    id: types.string,
    createDate: types.string,
    feedback: types.maybeNull(
      types.array(
        types
          .model({
            assignmentId: types.string,
            feedback: types.string,
            createDate: types.string,
            user: types.maybeNull(User),
          })
          .views(self => ({
            get date() {
              return format(parseISO(self.createDate), 'MM/dd/yyyy');
            },
          }))
      )
    ),
    questionTitle: types.maybeNull(types.string),
    response: types.string,
    user: User,
    termId: types.maybeNull(types.number),
    termTitle: types.maybeNull(types.string),
    readByFaculty: types.maybeNull(types.boolean),
    moduleId: types.string,
    active: true,
  })
  .views(self => ({
    get date() {
      return format(parseISO(self.createDate), 'MM/dd/yyyy');
    },

    get sortedFeedback() {
      return (self.feedback || [])
        .slice()
        .sort((a, b) => compareAsc(parseISO(a.createDate), parseISO(b.createDate)));
    },

    get read() {
      return !!self.readByFaculty;
    },

    get submittedAgo() {
      return formatDistance(new Date(), new Date(self.createDate));
    },
  }))
  .actions(self => ({
    addFeedbackItem(item) {
      self.feedback = (self.feedback || []).concat([item]);
    },

    markAsRead() {
      self.readByFaculty = true;
    },

    changeActiveStatus(status) {
      self.active = status;
    },
  }));

export default Assignment;
