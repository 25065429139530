import { types, getRoot } from 'mobx-state-tree';
import {
  fetchSingleClass,
  activateFreeTrial,
  markFavoriteLesson,
  unmarkFavoriteLesson,
  handle404,
} from 'api';
import handleApiError from 'utils/handleApiError';
import { ApiError } from './models';

export const ClassDetailsStore = types
  .model('ClassDetailsStore', {
    activeClassId: types.maybeNull(types.number),
    loading: false,
    error: types.maybeNull(ApiError),
    activatingTrial: false,
    preloaded: false,
  })
  .views((self) => ({
    get activeClass() {
      const { classStore } = getRoot(self);

      if (self.activeClassId && self.activeClassId === classStore.classTermVersion?.id) {
        return classStore.classTermVersion;
      }

      return classStore.classesById.get(self.activeClassId);
    },
    get doesUserTeachActiveClass() {
      const { dashboardStore } = getRoot(self);

      if (!self.activeClass) {
        return false;
      }
      return dashboardStore.classesTeaching?.some((c) => c.id === self.activeClass.id);
    },
    get isHCC() {
      return this.activeClass?.curriculumType === 'Guided';
    },

    get featureToolUrl() {
      const featureTool = this.activeClass?.resources?.find((r) => r.featureOnDashboard);
      return featureTool ? featureTool.url : undefined;
    },
  }))
  .actions((self) => ({
    clearPreloaded() {
      self.preloaded = false;
    },

    reset() {
      self.activeClassId = null;
    },

    async fetch(slug) {
      // class details preloaded from the dashboard page?
      const { classStore } = getRoot(self);
      const cls = classStore.getBySlug(slug);
      if (cls) {
        self.activeClassId = cls.id;

        // assignments are loaded fresh regardless of class
        const { assignmentsStore, videoAssignmentSubmissionStore } = getRoot(self);
        assignmentsStore.loadSubmittedAssignments();
        videoAssignmentSubmissionStore.loadVideoAssignmentSubmissions();

        return;
      }

      // need to fetch single class
      self.loading = true;
      try {
        const response = await fetchSingleClass({ slug });
        self.fetchSuccess(response);
      } catch (error) {
        self.fetchFailure(error);
        handle404(error);
      }
    },

    async refetchClass(slug) {
      try {
        const response = await fetchSingleClass({ slug });
        self.fetchSuccess(response);
      } catch (error) {
        self.fetchFailure(error);
        handle404(error);
      }
    },

    fetchSuccess(data) {
      self.loading = false;
      const { classStore, quizStore } = getRoot(self);
      classStore.putClass({ ...data });
      quizStore.setQuizToRefetch(null);
      self.activeClassId = data.id;

      const { assignmentsStore } = getRoot(self);
      assignmentsStore.loadSubmittedAssignments();

      // const { questionStore } = getRoot(self);
      // questionStore.reloadQuestions();
    },

    fetchFailure(error) {
      self.loading = false;
      self.error = handleApiError(error);
    },

    // setActiveClassClientside(classId) {
    //   self.activeClassId = classId;
    //
    //   const { assignmentsStore } = getRoot(self);
    //   assignmentsStore.loadSubmittedAssignments();
    //
    //   // const { questionStore } = getRoot(self);
    //   // questionStore.reloadQuestions();
    // },

    async activateFreeTrial() {
      self.activatingTrial = true;
      try {
        await activateFreeTrial(self.activeClass.id, self.activeClass.freeTrialInfo.token);
        self.activateTrialSuccess();
      } catch (error) {
        console.error(error);
        self.activateTrialFailure(error);
      }
    },

    activateTrialSuccess() {
      self.activatingTrial = false;
      self.activeClass.activateFreeTrial();
    },

    activateTrialFailure() {
      self.activatingTrial = false;
    },

    async toggleFavoriteLesson(lesson) {
      const { dashboardStore, classStore } = getRoot(self);
      if (lesson.type !== 'Video') return;

      try {
        if (self.activeClass.favoriteLessons.find((l) => l.id === lesson.id)) {
          dashboardStore.removeFavoriteLesson(lesson);
          self.activeClass.removeFavoriteLesson(lesson);
          await unmarkFavoriteLesson(self.activeClass.id, lesson.id);
        } else {
          classStore.putFavoriteLessonClass(self.activeClass.toJSON());
          dashboardStore.pushFavoriteLesson(lesson.toJSON());
          self.activeClass.pushFavoriteLesson(lesson.toJSON());
          await markFavoriteLesson(self.activeClass.id, lesson.id);
        }
      } catch (error) {
        console.error(error);
      }
    },
  }));
