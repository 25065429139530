import { types } from 'mobx-state-tree';
import { Class } from './models';

/**
 * This store keeps all classes ever loaded in a key-value store, where the keys
 * are class IDs. This is helpful because we can re-use the classes rather than load
 * them again (e.g. if class was loaded on the dashboard page, we can re-use it on
 * the class details page).
 */
export const ClassStore = types
  .model('ClassStore', {
    classesById: types.map(Class),
    classTermVersion: types.maybe(Class),
    classesForFavoriteLessons: types.map(Class),
  })
  .actions((self) => ({
    putClass(cls) {
      self.classesById.put(cls);
    },
    putFavoriteLessonClass(cls) {
      const added = [...self.classesForFavoriteLessons.values()].find((c) => c.id === cls.id);
      if (!added) {
        self.classesForFavoriteLessons.put({ ...cls });
      }
    },
    getBySlug(slug) {
      const all = [...self.classesById.values()];
      return all.find((cls) => cls.urlKey === slug);
    },
    setClassTermVersion(cls) {
      self.classTermVersion = cls;
    },
  }));
