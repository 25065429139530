import { types } from 'mobx-state-tree';
import { type } from 'os';

const Clip = types.model({
  path: types.string,
  ordinal: types.maybeNull(types.integer),
  thumbnail: types.string,
});
const Video = types.model({
  id: types.string,
  createDate: types.string,
  clips: types.array(Clip),
  failed: types.boolean,
  recordingRoomId: types.maybeNull(types.string),
});

const StatusEvent = types.model({
  event: types.string,
  videoId: types.string,
  createDate: types.string,
});

const VideoAssignmentSubmission = types.model({
  id: types.string,
  assignmentId: types.string,
  objectType: types.string,
  createDate: types.string,
  status: types.string,
  submittedVideoId: types.maybeNull(types.string),
  statusHistory: types.array(StatusEvent),
  videos: types.array(Video),
  roomId: types.maybeNull(types.string),
  recordingRoomId: types.maybeNull(types.string),
  submittedDate: types.maybeNull(types.string),
});

export default VideoAssignmentSubmission;
