import { types } from 'mobx-state-tree';
// import { searchQuestions } from 'api';
import handleApiError from 'utils/handleApiError';
import { Question, ApiError } from './models';

export const CommunityStore = types
  .model('CommunityStore', {
    lastSearch: types.optional(types.string, ''),
    searchResults: types.optional(types.array(Question), []),
    featuredQuestions: types.optional(types.array(Question), []),
    error: types.maybeNull(ApiError),
    loadingFeaturedQuestions: false,
    loadingSearchResults: false,
  })
  .actions(self => ({
    // Fetch featured community questions
    async fetchFeaturedQuestions() {
      self.loadingFeaturedQuestions = true;
      try {
        // const response = await fetchFeaturedQuestions();
        // self.fetchFeaturedQuestionsSuccess(response);
      } catch (error) {
        self.fetchFeaturedQuestionsError(error);
      }
    },

    fetchFeaturedQuestionsSuccess(data) {
      self.featuredQuestions = data;
      self.error = null;
      self.loadingFeaturedQuestions = false;
    },

    fetchFeaturedQuestionsError(error) {
      self.error = handleApiError(error);
      self.loadingFeaturedQuestions = false;
    },

    // Search community questions by term
    async searchQuestions(term) {
      self.loadingSearchResults = true;
      self.searchResults = [];
      try {
        // const response = await searchQuestions(term);
        // self.searchQuestionsSuccess(response, term);
      } catch (error) {
        self.searchQuestionsError(error, term);
      }
    },

    searchQuestionsSuccess(data, term) {
      self.searchResults = data;
      self.error = null;
      self.loadingSearchResults = false;
      self.lastSearch = term;
    },

    searchQuestionsError(error, term) {
      self.error = handleApiError(error);
      self.loadingSearchResults = false;
      self.lastSearch = term;
    },
  }));
