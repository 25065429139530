import React from 'react';

export const IconPersonalGrowth = ({ width, height, title }) => (
  <svg viewBox="0 0 13.44 16" xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
    {title && <title>{title}</title>}
    <path
      fill="currentColor"
      d="M12.91.28a.72.72 0 00-.52-.28 5.25 5.25 0 00-4.88 3 .47.47 0 000 .43l.49.75a.17.17 0 00.32 0 11.76 11.76 0 01.83-1.44 3.27 3.27 0 012.57-1.34.2.2 0 01.19.14 3.51 3.51 0 01-.23 2.55c-.49.76-1.52 1.13-3.05 1.08a1.6 1.6 0 00-.72.08.17.17 0 01-.21-.07c-.47-.87-1.7-2.9-3.39-3.05a2.74 2.74 0 00-2.48 1.41l-.55.93c-.62 1-.73 1.22-1 1.25A.29.29 0 000 6v.84a.28.28 0 00.09.2.26.26 0 00.2.07c1.07-.06 1.39-.61 2.18-1.94L3 4.33c.41-.57.81-.84 1.2-.81C5.05 3.6 6 5 6.24 5.44a.11.11 0 010 .09.11.11 0 01-.07.07 4.42 4.42 0 01-3 .24.32.32 0 00-.39.1l-.48.69a.27.27 0 000 .22.25.25 0 00.11.15 4.29 4.29 0 001.81.48 5.8 5.8 0 001.83-.34 7.85 7.85 0 00-.69 2.36 29.19 29.19 0 00.23 6.23.28.28 0 00.27.24l.83-.12a.28.28 0 00.31-.28 28.22 28.22 0 01-.23-5.85 4.72 4.72 0 011.37-3 .5.5 0 01.31-.13h.09c2.81.09 4-1.05 4.48-2a5.16 5.16 0 00-.11-4.31z"
    />
  </svg>
);
