import { types } from 'mobx-state-tree';

export const LayoutStore = types
  .model('LayoutStore', {
    noNavigation: false,
    fullScreen: false,
  })
  .actions(self => ({
    goFullScreen() {
      self.noNavigation = true;
      self.fullScreen = true;
    },
    revertFullScreen() {
      self.noNavigation = false;
      self.fullScreen = false;
    },
  }));
