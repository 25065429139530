const appTheme = {
  colors: {
    standard: {
      background: {
        page: '#ffffff',
        light: '#f3f3f3',
        medium: '#e8e8e8',
        inverted: '#646769',
        footer: '#222222',
      },
      text: {
        primary: '#222222',
        light: '#898989',
        medium: '#707070',
        inverted: '#ffffff',
      },
      lines: {
        light: '#ddd',
        medium: '#bcbfc2',
        dark: '#979797',
      },
      gradients: {
        heroImageFade: 'linear-gradient(to right, rgba(243, 243, 243, 0), #f3f3f3)',
        largeImageOverlay:
          'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5) 54%, rgba(0, 0, 0, 0.7))',
        smallImageOverlay:
          'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2) 28%, rgba(0, 0, 0, 0.4) 52%, rgba(0, 0, 0, 0.5))',
        practicesBrandGradient:
          'linear-gradient(to bottom,rgba(45,123,158,0.9),rgba(62,198,194,0.9))',
      },
      notifications: {
        indicator: '#f58888',
      },
    },
    player: {
      background: {
        page: '#000000',
        dark: '#202020',
        medium: '#333333',
        light: '#4d4d4d',
        overlay: 'rgba(0,0,0,0.67)',
      },
      text: {
        primary: '#e6e6e6',
        medium: '#999999',
        highlighted: '#4ba6db',
      },
      lines: {
        light: '#676767',
        medium: '#444444',
      },
    },
    classesTeal: '#00a3b7',
    categories: {
      Fitness: '#a35ca2',
      Health: '#c14e78',
      Home: '#ff8770',
      Meditation: '#6058bc',
      Nutrition: '#7d5bae',
      'Personal Growth': '#6058bc',
      Relationships: '#f36868',
      Spirituality: '#6058bc',
      Yoga: '#a35ca2',
    },
  },
  shadows: {
    primary: '0 2px 7px 0 rgba(145, 145, 145, 0.35)',
  },
  breakpoints: {
    desktopL: 1200,
    desktop: 992,
    tablet: 768,
    mobileL: 620,
    mobile: 576,
  },
};
export default appTheme;
