import { API, graphqlOperation } from 'aws-amplify';
import {
  getSendbirdRoom as getSendbirdRoomQuery,
  getSendbirdUser as getSendbirdUserQuery
} from './graphql/queries';
import {
  createSendbirdUser as createSendbirdUserMutation,
  updateSendbirdCustomItems as updateSendbirdCustomItemsMutation,
} from './graphql/mutations';

export const getSendbirdUser = async (userId) => {
  const { data } = await API.graphql(
    graphqlOperation(getSendbirdUserQuery, {
      input: {
        userId,
      },
    })
  );

  return data.getSendbirdUser;
};

export const createSendbirdUser = async (body) => {
  const { data } = await API.graphql(
    graphqlOperation(createSendbirdUserMutation, {
      input: {
        user_id: body.user_id,
        nickname: body.nickname,
        profile_url: body.profile_url,
      },
    })
  );

  return data.createSendbirdUser;
};

export const getSendbirdRoom = async (roomId) => {
  const { data } = await API.graphql(
    graphqlOperation(getSendbirdRoomQuery, {
      input: {
        roomId,
      },
    })
  );

  return data.getSendbirdRoom;
};

export const updateSendbirdCustomItems = async (roomId, customItems) => {
  const { data } = await API.graphql(
    graphqlOperation(updateSendbirdCustomItemsMutation, {
      input: {
        roomId,
        customItems,
      },
    })
  );

  return data.updateSendbirdCustomItems;
};
