export const IconNotification = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 19 25"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-918.000000, -87.000000)" fill="currentColor" fillRule="nonzero">
        <g transform="translate(918.000000, 80.000000)">
          <g transform="translate(0.000000, 7.000000)">
            <path d="M11.5225806,22.1176471 C11.3080645,22.1176471 11.1241935,22.2352941 10.9709677,22.3823529 C10.633871,22.7941176 10.0822581,23.0588235 9.5,23.0588235 C8.91774194,23.0588235 8.36612903,22.7941176 8.02903226,22.3823529 C7.90645161,22.2352941 7.69193548,22.1176471 7.47741935,22.1176471 C7.26290323,22.1176471 7.0483871,22.1176471 6.83387097,22.1176471 C6.28225806,22.1176471 5.94516129,22.6764706 6.22096774,23.1176471 C6.89516129,24.1764706 8.09032258,24.8823529 9.5,24.8823529 C10.9096774,24.8823529 12.1048387,24.1764706 12.7790323,23.1176471 C13.0548387,22.6764706 12.7177419,22.0882353 12.166129,22.1176471 C11.9516129,22.1176471 11.7370968,22.1176471 11.5225806,22.1176471 Z" />
            <path d="M17.1306452,16.0294118 L17.1306452,16.0294118 L17.0693548,16 C16.8548387,15.8529412 15.4758065,14.7352941 15.4758065,9.94117647 C15.4758065,8.29411765 15.2,6.97058824 14.6177419,6 C14.3112903,5.47058824 13.5145161,5.35294118 13.0854839,5.82352941 C13.0854839,5.82352941 13.0854839,5.82352941 13.0854839,5.85294118 C12.8096774,6.14705882 12.7790323,6.58823529 12.9629032,6.94117647 C13.3612903,7.58823529 13.5451613,8.55882353 13.5451613,9.97058824 C13.5451613,16.0588235 15.6596774,17.4117647 16.2419355,17.6764706 C16.916129,18.0294118 16.916129,18.3235294 16.916129,18.4705882 C16.916129,18.5882353 16.7935484,18.8529412 16.7016129,18.9411765 C16.7016129,18.9411765 15.5677419,19.3823529 9.5,19.3823529 C3.30967742,19.3823529 2.2983871,18.9411765 2.2983871,18.9411765 C2.20645161,18.8529412 2.08387097,18.5882353 2.08387097,18.4705882 C2.08387097,18.3235294 2.08387097,18.0294118 2.75806452,17.6764706 C3.2483871,17.4411765 5.17903226,16.0882353 5.17903226,9.97058824 C5.17903226,7.17647059 5.88387097,5.58823529 9.5,5.58823529 C10.6032258,5.58823529 11.583871,4.97058824 12.0435484,4.08823529 C11.3080645,3.88235294 10.45,3.76470588 9.5,3.76470588 C5.24032258,3.76470588 3.27903226,5.73529412 3.27903226,9.97058824 C3.27903226,14.9117647 1.96129032,15.9705882 1.86935484,16.0588235 C0.735483871,16.6470588 0.183870968,17.4705882 0.183870968,18.4705882 C0.183870968,19.2058824 0.643548387,20.2941176 1.56290323,20.6470588 C2.08387097,21 3.55483871,21.2352941 9.53064516,21.2352941 C15.5064516,21.2352941 17.0080645,21 17.4983871,20.6470588 C18.3870968,20.2941176 18.8774194,19.2058824 18.8774194,18.4705882 C18.8467742,17.4411765 18.2645161,16.6176471 17.1306452,16.0294118 Z" />
            <path d="M9.5,1.91176471 C10.0209677,1.91176471 10.45,2.32352941 10.45,2.82352941 C10.45,3.32352941 10.0209677,3.73529412 9.5,3.73529412 C8.97903226,3.73529412 8.55,3.32352941 8.55,2.82352941 C8.55,2.32352941 8.97903226,1.91176471 9.5,1.91176471 L9.5,1.91176471 Z M9.5,0.0588235294 C7.90645161,0.0588235294 6.61935484,1.29411765 6.61935484,2.82352941 C6.61935484,4.35294118 7.90645161,5.58823529 9.5,5.58823529 C11.0935484,5.58823529 12.3806452,4.35294118 12.3806452,2.82352941 C12.3806452,1.29411765 11.0935484,0.0588235294 9.5,0.0588235294 L9.5,0.0588235294 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
