import React from 'react';

export const ArrowRightLight = ({ width, height, color }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line x1="0.353553" y1="0.646447" x2="9.35355" y2="9.64645" stroke={`${color || '#000'}`} />
    <line x1="0.646447" y1="17.6464" x2="8.64645" y2="9.64645" stroke={`${color || '#000'}`} />
  </svg>
);
