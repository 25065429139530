import React from 'react';

export const IconRelationships = ({ width, height, title }) => (
  <svg viewBox="0 0 52.14 55.05" xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
    {title && <title>{title}</title>}
    <path
      fill="currentColor"
      d="M52.12 34.7a19.71 19.71 0 00-2.39-9.44c-2.14-3.67-6.77-4-10.15-4.23a18.51 18.51 0 01-2.83-.32l-.6-.16c-2.66-.68-5.18-1.33-6.85-2.91a6.84 6.84 0 01-1.81-3 7.87 7.87 0 011.16-6.82 8 8 0 016.11-3.19 8 8 0 016.12 3.19 7.87 7.87 0 011.16 6.82 9.1 9.1 0 01-1.7 2.41c-.45.5-.61.69-.42.87 0 0 .08.08.82.27l3.06.81a1.34 1.34 0 001.34-.47A8.94 8.94 0 0046.5 16a12.61 12.61 0 00-1.86-10.91A12.73 12.73 0 0034.78 0a12.71 12.71 0 00-9.88 5.09A12.61 12.61 0 0023 16a11.21 11.21 0 004.57 6.22l.11.09a.37.37 0 01-.13.06 19.12 19.12 0 00-3.47 1.4.44.44 0 01-.61-.17 11.72 11.72 0 00-.74-1.15 11.45 11.45 0 00-9.87-5.09 29.25 29.25 0 00-7.78 1.46 21 21 0 01-2.24.58H1a.93.93 0 00-.93.93v2.79a.93.93 0 00.93.93h1.88a14.22 14.22 0 003.54-.76A25.3 25.3 0 0112.9 22a6.78 6.78 0 016.1 3.21A7.87 7.87 0 0120.18 32a12.3 12.3 0 01-2.57 3.35c-.5.5-.65.66-.47.85s.08.09.82.28l3.18.83a1.18 1.18 0 00.3 0 1.44 1.44 0 001.07-.47 10.93 10.93 0 002.13-3.51 12.1 12.1 0 00.49-4.16 1 1 0 01.41-.87 37.64 37.64 0 018.26-2.74l1.14-.26.66-.14a21.1 21.1 0 003.68.46c2.6.18 5.55.39 6.45 1.93a15.39 15.39 0 011.77 7.32v.47a.6.6 0 00.21.41 1.05 1.05 0 00.76.22l2.78-.21c.52 0 .91-.34.89-.71z"
    />
    <path
      fill="currentColor"
      d="M16.69 38.51h-.08c-.65-.15-1.25-.28-1.73-.41-2.73-.71-4.66-1.3-6.52-3.07a6.84 6.84 0 01-1.81-3 7.87 7.87 0 01.06-4.63.75.75 0 00-.08-.68.72.72 0 00-.6-.28h-3a1.16 1.16 0 00-1.09.85 12.36 12.36 0 00.24 6.14 11.5 11.5 0 003.06 5 14.62 14.62 0 001.66 1.36c.1.07.12.12.12.13s0 .05-.14.1a10.52 10.52 0 00-4.88 3.41C.41 45.52-.05 50.5 0 54.12a1 1 0 00.95.93h2.79a.89.89 0 00.64-.27.91.91 0 00.26-.67c-.07-2.83.42-7.1 1.08-8 1.12-1.58 5.26-2.62 7.73-3.25l.33-.08a2.68 2.68 0 01.9.08l1 .22c2.19.5 7.32 1.67 8.28 3 .66.94 1.15 5.21 1.08 8a.91.91 0 00.26.67.89.89 0 00.64.27h2.79a1 1 0 00.95-.93c.06-3.62-.41-8.6-1.91-10.73-1.86-2.58-6.12-3.72-11.08-4.85z"
    />
  </svg>
);
