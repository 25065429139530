import React from 'react';

export const IconRoutines = ({ width, height, title }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.14 16" width={width} height={height}>
    {title && <title>{title}</title>}
    <path
      fill="currentColor"
      d="M15 2.55A7.41 7.41 0 009.57 0C4.94 0 2.29 1.84 1.44 5.58a.17.17 0 01-.14.12H.13c-.06 0-.1 0-.12.12v1.1c0 .09.13.15.21.17a11.48 11.48 0 001.86 0 .71.71 0 00.63-.59c.55-3.48 2.66-5 6.84-5.06A6 6 0 0114 3.5a6.59 6.59 0 01-5.4 11.06 6.24 6.24 0 01-5.52-4.19l.47.31A.3.3 0 004 10.6l.45-.67a.31.31 0 00-.08-.42l-2-1.32a.7.7 0 00-1 .18C1 9 .36 9.79.08 10.14a.34.34 0 000 .43l.53.6a.31.31 0 00.22.1.27.27 0 00.17-.09c.13-.15.33-.4.58-.73A7.6 7.6 0 008.47 16h.71a7.79 7.79 0 005-1.83 8.13 8.13 0 002.92-5.41A8 8 0 0015 2.55z"
    />
    <path
      fill="currentColor"
      d="M8.75 8.95h4.17a.31.31 0 00.3-.3v-.81a.31.31 0 00-.3-.3H9.21a.43.43 0 01-.26-.11l-1.85-2a.3.3 0 00-.43 0L6.08 6a.3.3 0 000 .43l2.16 2.29a.71.71 0 00.51.23z"
    />
  </svg>
);
