import { types, getRoot } from 'mobx-state-tree';
import { markNotificationAsRead } from 'api';

export const NotificationStore = types
  .model('NotificationStore', {
    dashboardView: true, // if we are currently displaying notifications for all classes on the dashboard page
  })
  .views(self => ({
    get readNotifications() {
      const { dashboardStore, classDetailsStore } = getRoot(self);
      if (!self.dashboardView) {
        const { read } = classDetailsStore.activeClass.notifications || { read: [] };
        return read;
      }
      return dashboardStore.notifications ? dashboardStore.notifications.read : [];
    },

    get unreadNotifications() {
      const { dashboardStore, classDetailsStore } = getRoot(self);
      if (!self.dashboardView) {
        const { unread } = classDetailsStore.activeClass.notifications || { unread: [] };
        return unread;
      }

      const unreadNotifications = dashboardStore.notifications
        ? dashboardStore.notifications.unread
        : [];

      // don't return past qna without recordedVideoLink or embedCode
      return unreadNotifications.filter(
        n =>
          !(
            n.type === 'qna' &&
            !n.isUpcomingQNA &&
            !n.officeHour?.recordedVideoLink &&
            !n.officeHour?.embedCode
          )
      );
    },

    get exposedNotification() {
      if (!self.unreadNotifications || self.unreadNotifications.length < 1) return null;
      if (self.unreadNotifications[0].type !== 'qna') {
        markNotificationAsRead(self.unreadNotifications[0].id);
      }
      return self.unreadNotifications[0];
    },
  }))
  .actions(self => ({
    async markAllAsRead() {
      if (!self.unreadNotifications) return;

      self.unreadNotifications.forEach(n => n.type !== 'qna' && markNotificationAsRead(n.id));
    },

    clearUnread() {
      const { dashboardStore, classDetailsStore } = getRoot(self);
      if (!self.dashboardView) {
        classDetailsStore.activeClass.clearUnreadNotifications();
      } else {
        dashboardStore.clearUnreadNotifications();
      }
    },

    setDashboardView(flag) {
      self.dashboardView = flag;
    },
  }));
