import { types } from 'mobx-state-tree';
import { fetchCartInfo } from 'api';

export const UserStore = types
  .model('UserStore', {
    cartCount: 0,
    cartLoaded: false,
  })
  .actions((self) => ({
    async fetchCart() {
      if (process.env.NODE_ENV === 'development') {
        /* no cart fething on locally run instance */
        return;
      }
      try {
        const data = await fetchCartInfo();
        self.fetchCartSuccess(data);
      } catch (error) {
        console.error(error);
      }
    },

    fetchCartSuccess(data) {
      self.cartLoaded = true;
      self.cartCount = data.cartCount;
    },
  }));
