import { useState, useEffect } from 'react';

// https://gist.github.com/joshuacerbito/ea318a6a7ca4336e9fadb9ae5bbb87f4#gistcomment-3059634
const useScroll = () => {
  const [scroll, setScroll] = useState({
    scrollX: process.browser ? window.scrollX : 0,
    scrollY: process.browser ? window.scrollY : 0,
    direction: 'down',
  });

  const listener = () => {
    // `prev` provides us the previous state: https://reactjs.org/docs/hooks-reference.html#functional-updates
    setScroll(prev => ({
      scrollX: window.scrollX,
      scrollY: window.scrollY,
      // Here we’re comparing the previous state to the current state to get the scroll direction
      direction: prev.scrollY > window.scrollY ? 'up' : 'down',
    }));
  };

  useEffect(() => {
    window.addEventListener('scroll', listener);
    return () => window.removeEventListener('scroll', listener);
  }, []);

  return scroll;
};

export default useScroll;
