import { types, getRoot } from 'mobx-state-tree';
import {
  fetchSubmittedAssignments,
  addAssignmentFeedback,
  changeAssignmentActiveStatus,
} from 'api';
import Assignment from './models/Assignment';

export const InstrAssignmentStore = types
  .model('InstrAssignmentStore', {
    assignments: types.optional(types.array(Assignment), []),
    loaded: false,
    loading: false,
    savingAssignmentId: types.maybeNull(types.string),
    savedAssignmentId: types.maybeNull(types.string),
    markAsReadId: types.maybeNull(types.string),
    activatingId: types.maybeNull(types.string),
  })
  .views(self => ({
    get uniqueTerms() {
      const map = {};
      return self.assignments.reduce((agg, next) => {
        if (!map[next.termId]) {
          map[next.termId] = true;
          return agg.concat([{ id: next.termId, text: next.termTitle }]);
        }

        return agg;
      }, []);
    },
  }))
  .actions(self => ({
    assignmentsForTerm(termId) {
      return self.assignments.filter(a => a.termId === termId);
    },

    async fetchClassAssignments(classId) {
      self.assignments = [];
      self.loading = true;

      try {
        const data = await fetchSubmittedAssignments(classId);
        self.fetchClassAssignmentsSuccess(data);
      } catch (error) {
        self.fetchClassAssignmentsError();
      }
    },

    fetchClassAssignmentsSuccess(data) {
      self.assignments = data;
      self.loading = false;
      self.loaded = true;
    },

    fetchClassAssignmentsError() {
      self.loading = false;
    },

    async addFeedback(classId, assignmentId, userId, feedback) {
      self.savedAssignmentId = null;
      self.savingAssignmentId = assignmentId;
      try {
        await addAssignmentFeedback(classId, assignmentId, feedback, userId);
        self.addFeedbackSuccess(assignmentId, feedback, userId);
      } catch (error) {
        console.error(error);
        self.addFeedbackFailure();
      }
    },

    addFeedbackSuccess(assignmentId, feedback, userId) {
      const {
        classDetailsStore: { activeClass },
      } = getRoot(self);
      const { instructors, teachingAssistants } = activeClass;
      const user = (instructors || []).concat(teachingAssistants || []).find(i => i.id === userId);

      self.assignments
        .find(a => a.id === assignmentId)
        .addFeedbackItem({
          feedback,
          assignmentId,
          createDate: new Date().toISOString(),
          user: { username: user ? user.fullName : 'me' },
        });
      self.savedAssignmentId = assignmentId;
      self.savingAssignmentId = null;
    },

    addFeedbackFailure() {
      self.savedAssignmentId = null;
      self.savingAssignmentId = null;
    },

    async markAsRead(classId, assignmentId) {
      self.markAsReadId = assignmentId;
      try {
        await addAssignmentFeedback(classId, assignmentId, null, null);
        self.markAsReadSuccess(assignmentId);
      } catch (error) {
        self.markAsReadFailure();
      }
    },

    markAsReadSuccess(assignmentId) {
      self.assignments.find(a => a.id === assignmentId).markAsRead();
      self.markAsReadId = null;
    },

    markAsReadFailure() {
      self.markAsReadId = null;
    },

    async changeActiveStatus(classId, assignmentId, status) {
      self.activatingId = assignmentId;
      try {
        await changeAssignmentActiveStatus(classId, assignmentId, status);
        self.changeActiveStatusSuccess(assignmentId, status);
      } catch (error) {
        self.changeActiveStatusFailure();
      }
    },

    changeActiveStatusSuccess(assignmentId, status) {
      self.assignments.find(a => a.id === assignmentId).changeActiveStatus(status);
      self.activatingId = null;
    },

    changeActiveStatusFailure() {
      self.activatingId = null;
    },
  }));
