import React from 'react';

import { Container, Links, UtilityLinks, MBGLogo } from './styles';

const Footer = () => {
  return (
    <Container>
      <a href="https://www.mindbodygreen.com/" aria-label="mind body green home page">
        <MBGLogo />
      </a>
      <Links>
        <a href="https://www.mindbodygreen.com/about" aria-label="About us">
          About us
        </a>
        <a href="https://www.mindbodygreen.com/contact" aria-label="Contact page">
          Contact
        </a>
        <a href="https://www.mindbodygreen.com/contribute" aria-label="Write for us">
          Write for us
        </a>
        <a href="https://www.mindbodygreen.com/jobs" aria-label="Jobs">
          Jobs
        </a>
        <a href="https://www.mindbodygreen.com/advertise" aria-label="Advertise">
          Advertise
        </a>
        <a href="https://www.mindbodygreen.com/affiliates" aria-label="Become an Affiliate">
          Become an Affiliate
        </a>
        <a href="https://www.mindbodygreen.com/collective" aria-label="Collective">
          Collective
        </a>
      </Links>
      <UtilityLinks>
        <a href="https://www.mindbodygreen.com/terms-of-sale" aria-label="Terms of sale">
          Terms of Sale
        </a>
        <a href="https://www.mindbodygreen.com/terms-of-use" aria-label="Terms of use">
          Terms of Use
        </a>
        <a href="https://www.mindbodygreen.com/disclaimer" aria-label="Disclaimer">
          Disclaimer
        </a>
        <a href="https://www.mindbodygreen.com/privacy" aria-label="Privacy">
          Privacy
        </a>
        <a href="https://www.mindbodygreen.com/data-collection" aria-label="Data collection">
          Data Collection
        </a>
      </UtilityLinks>
    </Container>
  );
};  

export default Footer;
