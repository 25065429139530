import React from 'react';

export const PlayerBackIcon = ({ width, height }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 7 10">
    <path
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="square"
      d="M0 5L6 .5v9z"
    />
  </svg>
);
