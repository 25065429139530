import { types, getRoot } from 'mobx-state-tree';

import Lesson from 'store/models/Lesson';

const Module = types
  .model({
    id: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    image: types.maybeNull(types.string),
    currentModule: types.maybeNull(types.boolean),
    lessons: types.maybeNull(types.array(Lesson)),
    modules: types.maybeNull(types.array(types.late(() => Module))),
  })
  .views(self => ({
    get completed() {
      const { classDetailsStore } = getRoot(self);

      // for HCC (guided classes) count only required lessons
      if (classDetailsStore?.isHCC) {
        return self.lessons?.length
          ? self.lessons.filter(l => !l.optional).every(l => l.completed)
          : false;
      }

      if (self.modules) return self.modules?.length ? self.modules.every(m => m.completed) : false;

      return self.lessons?.length ? self.lessons.every(l => l.completed) : false;
    },

    get active() {
      if (self.modules) return self.modules.some(m => m.active);
      return self.lessons.some(l => l.currentLesson);
    },

    get sessionCount() {
      if (self.modules) return self.modules.reduce((agg, next) => agg + next.sessionCount, 0);
      return self.lessons.length;
    },

    // returns all lessons as a single array, even if lessons are in nested modules
    get allLessons() {
      if (self.modules) {
        return self.modules.reduce((agg, next) => agg.concat(next.lessons), []);
      }

      return self.lessons;
    },

    get completedLessonsCount() {
      return self.allLessons.filter(l => l.completed).length;
    },

    get upcoming() {
      if (self.modules) return false;
      return !self.lessons?.length;
    },

    // ---- OLD
    // get materials() {
    //   if (self.modules) {
    //     return self.modules.reduce((agg, next) => agg.concat(next.materials), []);
    //   }

    //   return self.lessons.filter(l => l.resources && l.resources.length);
    // },

    get materials() {
      if (self.modules) {
        const all = self.modules.reduce((agg, next) => agg.concat(next.materials), []);
        return [...new Set(all.map(r => r.id))].map(id => {
          const resource = all.find(r => r.id === id);
          return {
            id,
            title: resource.title,
            url: resource.url,
          };
        });
      }

      return self.lessons.reduce((acc, currentVal) => {
        if (currentVal.resources?.length) {
          const all = acc.concat(currentVal.resources);
          return [...new Set(all.map(r => r.id))].map(id => {
            const resource = all.find(r => r.id === id);
            return {
              id,
              title: resource.title,
              url: resource.url,
            };
          });
        }

        return acc;
      }, []);
    },
  }))
  .actions(self => ({
    containsLessonId(id) {
      return !!self.allLessons.find(l => l.id === id);
    },

    changeCurrentLesson(lessonId) {
      if (self.modules) self.modules.forEach(mod => mod.changeCurrentLesson(lessonId));
      else self.lessons.forEach(lsn => lsn.markAsCurrentLesson(lsn.id === lessonId));
    },
  }));

export default Module;
