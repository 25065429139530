import { API, graphqlOperation } from 'aws-amplify';
import {
  uploadToZapier as uploadToZapierMutation,
  addChatRecord as addChatRecordMutation,
} from './graphql/mutations';

export const uploadToZapier = async (body) => {
  const { data } = await API.graphql(graphqlOperation(uploadToZapierMutation, body));

  return data;
};

export const addChatRecord = async (body) => {
  const { data } = await API.graphql(graphqlOperation(addChatRecordMutation, body));

  return data;
};
