import { types } from 'mobx-state-tree';
import isAfter from 'date-fns/isAfter';
import compareAsc from 'date-fns/compareAsc';
import durationHelper from 'utils/duration';

import AssignmentQuestion from './AssignmentQuestion';
import Resource from './Resource';
import AssessmentQuestion from './AssessmentQuestion';
import LessonInstance from './LessonInstance';

const Lesson = types
  .model({
    id: types.string,
    assessmentQuestions: types.maybeNull(types.array(AssessmentQuestion)),
    assignmentQuestions: types.maybeNull(types.array(AssignmentQuestion)),
    classId: types.number,
    completed: types.maybeNull(types.boolean),
    currentLesson: types.optional(types.maybeNull(types.boolean), false),
    description: types.maybeNull(types.string),
    image: types.maybeNull(types.string),
    instances: types.maybeNull(types.array(LessonInstance)),
    instructorIds: types.maybeNull(types.array(types.number)),
    mediaUrl: types.maybeNull(types.string),
    moduleId: types.maybeNull(types.string),
    optional: types.maybeNull(types.boolean),
    quizInProgress: types.maybeNull(types.boolean),
    resources: types.maybeNull(types.array(Resource)),
    termId: types.maybeNull(types.number),
    title: types.string,
    type: types.string,
    videoDurationInSec: types.maybeNull(types.number),
    videoId: types.maybeNull(types.string),
    videoPlayerHead: types.maybeNull(types.number),
    videoUrl: types.maybeNull(types.string),
  })
  .views(self => ({
    get isVideoLesson() {
      return self.type === 'Video';
    },

    get isLiveSession() {
      return self.type === 'Live Session';
    },

    get isUpcomingLiveSession() {
      const dateToCompare = new Date();
      dateToCompare.setHours(0, 0, 0, 0);

      return (
        self.type === 'Live Session' &&
        self.instances?.some(instance => isAfter(new Date(instance.startDate), dateToCompare))
      );
    },

    get firstLiveSessionInstance() {
      if (self.isUpcomingLiveSession) {
        const sortInstances = self.instances
          .slice()
          .sort((a, b) => compareAsc(new Date(a.startDate), new Date(b.startDate)));
        return sortInstances[0];
      }
      return null;
    },

    get videoDurationMinutes() {
      return durationHelper(self.videoDurationInSec || 0);
    },

    // checks if video lesson has the video ID
    get isValidLesson() {
      return !!((self.type === 'Video' && self.videoId) || self.type !== 'Video');
    },
  }))
  .actions(self => ({
    markCompleted() {
      self.completed = true;
    },

    randomizeQuizAnswers() {
      self.assessmentQuestions.forEach(aq => {
        aq.randomizeChoices();
      });
    },

    markAsCurrentLesson(bool) {
      self.currentLesson = bool;
    },

    setPlayerHead(value) {
      self.videoPlayerHead = parseInt(value, 10) || 0;
    },
  }));

export default Lesson;
