import React from 'react';

export const PlayVideoIconLight = ({ className, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 38 38"
    className={className}
  >
    <defs>
      <filter
        id="dji3gxwbea"
        width="148.5%"
        height="148.5%"
        x="-24.2%"
        y="-24.2%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5" />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0.568627451 0 0 0 0 0.568627451 0 0 0 0 0.568627451 0 0 0 0.35 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g
              filter="url(#dji3gxwbea)"
              transform="translate(-44 -686) translate(39 681) translate(8 7)"
            >
              <circle cx="16.038" cy="16.038" r="16.038" fill="#FFF" />
              <path
                fill="#646769"
                d="M17.71 11.6l4.963 7.917c.246.392.127.91-.265 1.155-.134.084-.288.128-.446.128h-9.925c-.462 0-.838-.375-.838-.838 0-.158.045-.312.128-.445L16.29 11.6c.246-.392.763-.51 1.155-.265.107.068.198.158.265.265z"
                transform="rotate(90 17 16)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
