import { API, graphqlOperation } from 'aws-amplify';
import {
  getUserClass,
  getAssignments,
  getAssessment,
  getUserAccessibleClasses,
  getUserInaccessibleClasses,
  getClassesTeaching,
  getIsUserAdmin,
  getLoggedInUser,
  getAllVideoAssignmentSubmissions,
  getByUserIdAndVideoAssignmentId,
  getVideoAssignmentByTermId as getVideoAssignmentByTermIdQuery,
  getByClassIdAndVideoAssignmentId,
  getTermsByClassId as getTermsByClassIdQuery,
} from './graphql/queries';
import { getUserClasses } from './graphqlCustom/queries';
import {
  saveProgressTracking,
  updateClassSubscription,
  createAssignment,
  saveFavoriteLesson,
  deleteFavoriteLesson,
  saveAssessmentUserAnswer,
  updateAssignment,
  createVideoAssignmentSubmission,
  updateVideoAssignmentSubmission,
  updateRoomId,
  addVideoFromCloudinaryToSubmission as addVideoFromCloudinaryToSubmissionMutation,
} from './graphql/mutations';

import axios from './axios';

const assignModuleIds = (cls) => {
  cls.modules.forEach((mod) => {
    if (mod.lessons)
      mod.lessons.forEach((l) => {
        l.moduleId = mod.id;
      });
    else if (mod.modules) {
      mod.modules.forEach((mod2) => {
        mod2.lessons.forEach((l) => {
          l.moduleId = mod.id;
        });
      });
    }
  });
};

export const fetchUser = async () => {
  try {
    const { data } = await API.graphql(graphqlOperation(getLoggedInUser));
    return data.getLoggedInUser;
  } catch (err) {
    console.log(err);
  }
};

export const getVideoAssignmentByTermId = async (termId) => {
  try {
    const { data } = await API.graphql(
      graphqlOperation(getVideoAssignmentByTermIdQuery, { termId })
    );
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const getTermsByClassId = async (classId) => {
  try {
    const { data } = await API.graphql(
      graphqlOperation(getTermsByClassIdQuery, { classId })
    );
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const fetchIsUserAdmin = async () => {
  const { data } = await API.graphql(graphqlOperation(getIsUserAdmin));

  return data.getIsUserAdmin;
};

export const fetchUserClasses = async () => {
  const { data } = await API.graphql(graphqlOperation(getUserClasses));

  // assign module ids to lessons
  data.getUserClasses.classesTaking.forEach(assignModuleIds);

  // filter out classes with no content
  data.getUserClasses.classesTaking = data.getUserClasses.classesTaking.filter(
    (cls) => cls.modules.length > 0
  );

  return data.getUserClasses;
};

export const fetchClassesTeaching = async () => {
  const { data } = await API.graphql(graphqlOperation(getClassesTeaching));

  return data.getClassesTeaching;
};

export const fetchUserClassesToUnlock = async () => {
  const { data } = await API.graphql(graphqlOperation(getUserAccessibleClasses));
  return data.getUserAccessibleClasses;
};

export const fetchUserClassesToPurchase = async () => {
  const { data } = await API.graphql(graphqlOperation(getUserInaccessibleClasses));
  return data.getUserInaccessibleClasses;
};

export const fetchRecommendedClasses = async () => {
  const { data } = await axios('/recommended-classes');
  return data;
};

/* either slug or classId must be defined */
export const fetchSingleClass = async ({ slug, classId, termId }) => {
  const { data } = await API.graphql(
    graphqlOperation(getUserClass, { classSlug: slug, classId, termId })
  );

  assignModuleIds(data.getUserClass);
  return data.getUserClass;
};

export const trackVideoProgress = async (
  classId,
  lessonId,
  termId,
  secondsPassed,
  totalSecondsConsumed
) => {
  const { data } = await API.graphql(
    graphqlOperation(saveProgressTracking, {
      input: {
        classId,
        playerOn: true,
        termId,
        totalSecondsConsumed,
        trackingInput: {
          playerHead: parseInt(secondsPassed, 10) || 0,
          sessionId: lessonId,
        },
      },
    })
  );

  return data.saveProgressTracking;
};

export const activateFreeTrial = async (classId, previewToken) =>
  API.graphql(
    graphqlOperation(updateClassSubscription, {
      input: {
        classId,
        type: 'preview',
        previewToken,
        activate: true,
      },
    })
  );

export const submitAssignment = async (
  classId,
  moduleId,
  lessonId,
  questionId,
  termId,
  response
) => {
  const { data } = await API.graphql(
    graphqlOperation(createAssignment, {
      input: {
        classId,
        moduleId,
        lessonId,
        questionId,
        termId,
        response,
      },
    })
  );

  const { data: progress } = await API.graphql(
    graphqlOperation(saveProgressTracking, {
      input: {
        classId,
        playerOn: false,
        termId,
        trackingInput: {
          sessionId: lessonId,
          assignmentSubmitted: true,
        },
      },
    })
  );

  return {
    ...data.createAssignment,
    currentSessionId: progress.saveProgressTracking.currentSessionId,
    newUnreadNotification: progress.saveProgressTracking.newUnreadNotification,
    totalProgress: progress.saveProgressTracking.totalProgress,
  };
};

export const markFavoriteLesson = async (classId, lessonId) => {
  await API.graphql(
    graphqlOperation(saveFavoriteLesson, {
      classId,
      lessonId,
    })
  );
};

export const unmarkFavoriteLesson = async (classId, lessonId) => {
  await API.graphql(
    graphqlOperation(deleteFavoriteLesson, {
      classId,
      lessonId,
    })
  );
};

export const fetchSubmittedAssignments = async (classId, termId) => {
  const { data } = await API.graphql(
    graphqlOperation(getAssignments, {
      classId,
      termId,
    })
  );

  return data.getAssignments;
};

export const fetchAssessmentProgress = async (classId, lessonId, termId) => {
  const { data } = await API.graphql(
    graphqlOperation(getAssessment, {
      classId,
      lessonId,
      termId,
    })
  );

  return data.getAssessment;
};

export const saveAssessmentAnswer = async (
  classId,
  moduleId,
  lessonId,
  questionId,
  answerId,
  termId,
  assessmentId = null
) => {
  const { data } = await API.graphql(
    graphqlOperation(saveAssessmentUserAnswer, {
      input: {
        classId,
        moduleId,
        lessonId,
        questionId,
        answerId,
        termId,
        assessmentId,
      },
    })
  );

  return data.saveAssessmentUserAnswer;
};

export const markAssessmentCompleted = async (classId, lessonId, termId) => {
  const { data } = await API.graphql(
    graphqlOperation(saveProgressTracking, {
      input: {
        classId,
        termId,
        trackingInput: {
          assessmentPassed: true,
          sessionId: lessonId,
        },
      },
    })
  );

  return data.saveProgressTracking;
};

export const addAssignmentFeedback = async (classId, assignmentId, feedback, feedbackUserId) => {
  await API.graphql(
    graphqlOperation(updateAssignment, {
      input: {
        classId,
        assignmentId,
        feedback,
        feedbackUserId,
      },
    })
  );
};

export const changeAssignmentActiveStatus = async (classId, assignmentId, active) => {
  await API.graphql(
    graphqlOperation(updateAssignment, {
      input: {
        classId,
        assignmentId,
        active,
      },
    })
  );
};

export const markDownloadLesson = async (classId, termId, lessonId) => {
  const { data } = await API.graphql(
    graphqlOperation(saveProgressTracking, {
      input: {
        classId,
        termId,
        trackingInput: {
          sessionId: lessonId,
        },
      },
    })
  );

  return data.saveProgressTracking;
};

export const fetchVideoAssignmentSubmissions = async (classId) => {
  const { data } = await API.graphql(
    graphqlOperation(getAllVideoAssignmentSubmissions, {
      classId,
    })
  );

  return data.getAllVideoAssignmentSubmissions;
};

export const fetchByUserIdAndVideoAssignmentId = async (userId, assignmentId) => {
  const { data } = await API.graphql(
    graphqlOperation(getByUserIdAndVideoAssignmentId, {
      input: {
        userId,
        assignmentId,
      },
    })
  );

  return data.getByUserIdAndVideoAssignmentId;
};

export const fetchByClassIdAndAssignmentId = async (classId, videoAssignmentId) => {
  const { data } = await API.graphql(
    graphqlOperation(getByClassIdAndVideoAssignmentId, {
      input: {
        classId,
        videoAssignmentId,
      },
    })
  );

  return data.getByClassIdAndVideoAssignmentId;
};

export const addVideoAssignmentSubmission = async (classId, assignmentId) => {
  const { data } = await API.graphql(
    graphqlOperation(createVideoAssignmentSubmission, {
      input: {
        classId,
        assignmentId,
      },
    })
  );

  return data;
};

export const editVideoAssignmentSubmission = async (submittedVideoId, submissionId) => {
  const { data } = await API.graphql(
    graphqlOperation(updateVideoAssignmentSubmission, {
      input: {
        submittedVideoId,
        submissionId,
      },
    })
  );

  return data;
};

export const editVideoAssignmentSubmissionRoomId = async (
  submissionId,
  roomId,
  recordingRoomId
) => {
  const { data } = await API.graphql(
    graphqlOperation(updateRoomId, {
      input: {
        roomId,
        recordingRoomId,
        submissionId,
      },
    })
  );

  return data;
};

export const addVideoFromCloudinaryToSubmission = async (video, submissionId, userId) => {
  const { data } = await API.graphql(
    graphqlOperation(addVideoFromCloudinaryToSubmissionMutation, {
      input: {
        video,
        submissionId,
        userId,
      },
    })
  );

  return data;
};
