import React from 'react';

export const PlayVideoIcon = ({ className, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 52 52"
    className={className}
  >
    <title>Play</title>
    <defs>
      <filter
        id="prefix__a"
        width="147.7%"
        height="147.7%"
        x="-23.9%"
        y="-23.9%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0.568627451 0 0 0 0 0.568627451 0 0 0 0 0.568627451 0 0 0 0.35 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd" filter="url(#prefix__a)" transform="translate(4.114 3)">
      <circle cx="21.78" cy="21.78" r="21.78" fill="#222" fillOpacity=".75" />
      <path
        fill="#FFF"
        d="M29.107 22.917l-10.333 6.526c-.506.32-1.176.168-1.495-.338-.11-.173-.168-.374-.168-.58V15.475c0-.599.486-1.084 1.084-1.084.205 0 .406.058.58.167l10.332 6.526c.506.32.657.99.338 1.496-.086.136-.202.252-.338.338z"
      />
    </g>
  </svg>
);
