import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store';
import { useRouter } from 'next/router';
import Link from 'next/link';

import groupClasses from 'utils/groupClasses';
import {
  NavBar,
  Logo,
  Right,
  AccountSection,
  MobileNav,
  MobileNavLeft,
  MobileNavRight,
  LogoMobile,
  HamburgerToggle,
} from './styles';
import AccountMenu from './AccountMenu';
import MobileMenu from './MobileMenu';

const Navigation = () => {
  const {
    rootStore: { dashboardStore, classDetailsStore, giftsStore },
  } = useStore();

  const router = useRouter();
  const [mobileMenuOpen, toggleMobileMenu] = useState(false);

  const validUser =
    (classDetailsStore.activeClass && classDetailsStore.activeClass.userType !== 'previewer') ||
    dashboardStore.loaded ||
    giftsStore.loaded;

  const classes = dashboardStore.libraryAccess
    ? dashboardStore.classesToUnlock
    : dashboardStore.classesToPurchase;
  const classMap = groupClasses(classes || []);

  const isHCC = classDetailsStore.isHCC && router.pathname.includes('classes/[slug]');

  return (
    <>
      <NavBar hcc={isHCC}>
        <div>
          <Link href="/user/classes">
            <a href="/user/classes" title="Dashboard" aria-label="Classes Dashboard">
              <Logo isHCC={isHCC} />
            </a>
          </Link>
          <Right>
            <a href="https://institute.mindbodygreen.com/functional-nutrition-training" aria-label="Functional Nutrition Coaching">
              functional nutrition coaching
            </a>
            <a href="https://www.mindbodygreen.com/classes" aria-label="Classes">
              Classes
            </a>
            <a href="https://shop.mindbodygreen.com/" aria-label="Supplements">
              Supplements
            </a>
            <AccountSection>
              <AccountMenu isLoggedIn={validUser} />
            </AccountSection>
          </Right>
        </div>

        <MobileNav>
          <MobileNavLeft>
            <HamburgerToggle
              type="button"
              open={mobileMenuOpen}
              onClick={() => toggleMobileMenu(!mobileMenuOpen)}
              aria-label="Toggle Menu"
            >
              <span />
              <span />
              <span />
            </HamburgerToggle>
          </MobileNavLeft>
          <Link href="/user/classes" passHref>
            {/* eslint-disable */}
            <a title="Dashboard" aria-label="Dashboard">
              <LogoMobile isHCC={isHCC} />
            </a>
            {/* eslint-enable */}
          </Link>
          <MobileNavRight />
        </MobileNav>
      </NavBar>
      {mobileMenuOpen ? (
        <MobileMenu
          categories={Object.keys(classMap)}
          toggleMenu={() => toggleMobileMenu(!mobileMenuOpen)}
        />
      ) : null}
    </>
  );
};

export default observer(Navigation);
