import React from 'react';

export const CheckmarkIcon = ({ width, height, className, color }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 29 22"
  >
    <g
      fill={`${color || '#000'}`}
      fillRule="evenodd"
      stroke={`${color || '#000'}`}
      strokeLinecap="square"
      strokeWidth="6"
    >
      <path d="M.859 6.859L7.5 13.5M20.782.218L7.5 13.5" transform="translate(4 4)" />
    </g>
  </svg>
);
