import React from 'react';

export const IconHealth = ({ width, height, title }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.2 16" width={width} height={height}>
    {title && <title>{title}</title>}
    <path
      fill="currentColor"
      d="M1.76 5.25a2.74 2.74 0 012.06-3A3.93 3.93 0 017.08 3a.35.35 0 00.47 0L8 2.47A.35.35 0 008 2a5.24 5.24 0 00-4.46-1A4.13 4.13 0 00.4 5.33a.34.34 0 01-.05.2.34.34 0 01-.18.13.25.25 0 00-.17.24v.87A.26.26 0 00.09 7a.25.25 0 00.16 0 1.67 1.67 0 001-.54 1.77 1.77 0 00.51-1.21zM18.09 3.87A4.84 4.84 0 0015.94.62C12.57-1.34 9.42 1.77 7.13 4l-.57.56a1.15 1.15 0 00-.23 1.5l.07.12a1 1 0 01.18.47c0 .35-.34.62-.66.72a.72.72 0 01-.83-.2 1.42 1.42 0 00-1-.74 1.35 1.35 0 00-.91.34L1.3 8.16a.65.65 0 00-.25.44.64.64 0 00.15.48 69.31 69.31 0 007.59 6.77.68.68 0 00.41.15.68.68 0 00.39-.13 33.42 33.42 0 005-4.23c2.18-2.28 4.1-4.81 3.5-7.77zM15.54 8.4c-.14.21-.3.41-.47.63l-.07.12c-.09.14-.23.34-.35.36a.18.18 0 01-.14 0l-.11-.11-.06-.07a3 3 0 01-.34-.46.47.47 0 010-.62 2.12 2.12 0 00.57-1.9 2.08 2.08 0 00-1.62-1.64 1.89 1.89 0 00-1.1.12 2.32 2.32 0 00-.46.26l-.14.12a1 1 0 01-.25.18c-.19.08-.39 0-.62-.26L10.26 5c-.14-.19-.35-.47-.63-.3a5.23 5.23 0 00-.54.52.31.31 0 00-.1.23c0 .21.23.43.33.53l.21.21a4.94 4.94 0 00.55.5 1.21 1.21 0 001.54 0 3.17 3.17 0 00.24-.22 1.64 1.64 0 01.42-.34.59.59 0 01.51 0 .72.72 0 01.39.47c.12.45-.14.68-.44.94a1.74 1.74 0 00-.48.57 1.17 1.17 0 00.14 1.14l.05.06 1.16 1.22a.15.15 0 010 .2 30.78 30.78 0 01-4.3 3.69.15.15 0 01-.18 0 73.92 73.92 0 01-6.29-5.55.14.14 0 010-.11.15.15 0 01.06-.11L3.79 8a.81.81 0 01.11-.06L4 8a2 2 0 002.36.73 2.18 2.18 0 001.62-2 2.23 2.23 0 00-.34-1.1.25.25 0 01.06-.08l.11-.11 2.6-2.55c1.22-1.19 3.4-1.9 4.93-1a3.52 3.52 0 011.5 2.34 5.58 5.58 0 01-1.3 4.17z"
    />
  </svg>
);
