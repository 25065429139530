/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $classId: Int!
    $moduleId: String!
    $title: String!
    $text: String!
  ) {
    createQuestion(
      classId: $classId
      moduleId: $moduleId
      title: $title
      text: $text
    ) {
      id
    }
  }
`;
export const createAnswer = /* GraphQL */ `
  mutation CreateAnswer(
    $classId: Int!
    $questionId: String!
    $answer: String!
  ) {
    createAnswer(classId: $classId, questionId: $questionId, answer: $answer) {
      id
    }
  }
`;
export const saveProgressTracking = /* GraphQL */ `
  mutation SaveProgressTracking($input: ProgressTrackingInput!) {
    saveProgressTracking(input: $input) {
      id
      classId
      createDate
      lastActivityDate
      termId
      currentSessionId
      totalProgress
      currentLessonCompleted
      sessionTracking {
        sessionId
        assessmentPassed
        assignmentSubmitted
        activityDate
        totalViews
        progress
        playerHead
      }
      newUnreadNotification {
        id
        type
        url
        qnaTitle
        classTitle
        classId
        text
        subText
        createDate
        qnaStartDate
        classCategory
        classUrlKey
      }
    }
  }
`;
export const createAssignment = /* GraphQL */ `
  mutation CreateAssignment($input: CreateAssignmentInput!) {
    createAssignment(input: $input) {
      id
    }
  }
`;
export const updateAssignment = /* GraphQL */ `
  mutation UpdateAssignment($input: UpdateAssignmentInput!) {
    updateAssignment(input: $input) {
      id
    }
  }
`;
export const createClassSubscription = /* GraphQL */ `
  mutation CreateClassSubscription($input: CreateClassSubscriptionInput!) {
    createClassSubscription(input: $input) {
      id
      previewToken
    }
  }
`;
export const updateClassSubscription = /* GraphQL */ `
  mutation UpdateClassSubscription($input: UpdateClassSubscriptionInput!) {
    updateClassSubscription(input: $input) {
      id
      previewToken
      classId
      type
      previewActivationDate
    }
  }
`;
export const saveAssessmentUserAnswer = /* GraphQL */ `
  mutation SaveAssessmentUserAnswer($input: AssessmentUserAnswerInput!) {
    saveAssessmentUserAnswer(input: $input) {
      id
      questionId
      userAnswerId
      correctAnswerId
    }
  }
`;
export const saveFavoriteLesson = /* GraphQL */ `
  mutation SaveFavoriteLesson($classId: Int!, $lessonId: String!) {
    saveFavoriteLesson(classId: $classId, lessonId: $lessonId) {
      classId
      lessonId
      active
    }
  }
`;
export const deleteFavoriteLesson = /* GraphQL */ `
  mutation DeleteFavoriteLesson($classId: Int!, $lessonId: String!) {
    deleteFavoriteLesson(classId: $classId, lessonId: $lessonId) {
      classId
      lessonId
      active
    }
  }
`;
export const updateUserNotification = /* GraphQL */ `
  mutation UpdateUserNotification($id: String!, $read: Boolean) {
    updateUserNotification(id: $id, read: $read) {
      success
    }
  }
`;
export const modifyGiftSubscription = /* GraphQL */ `
  mutation ModifyGiftSubscription($input: GiftSubscriptionInput!) {
    modifyGiftSubscription(input: $input) {
      id
      classId
      libraryId
      createDate
      recipientName
      senderName
      claimed
      claimDate
      notificationTime
      emailAddress
      senderEmail
      classTitle
      classImage
      subscriberId
      classSubscriptionId
      invitationKey
      message
    }
  }
`;
export const createVideoAssignmentSubmission = /* GraphQL */ `
  mutation CreateVideoAssignmentSubmission(
    $input: CreateVideoAssignmentSubmissionInput!
  ) {
    createVideoAssignmentSubmission(input: $input) {
      id
    }
  }
`;
export const updateVideoAssignmentSubmission = /* GraphQL */ `
  mutation UpdateVideoAssignmentSubmission(
    $input: UpdateVideoAssignmentSubmissionInput!
  ) {
    updateVideoAssignmentSubmission(input: $input) {
      id
    }
  }
`;
export const addVideoFromCloudinaryToSubmission = /* GraphQL */ `
  mutation AddVideoFromCloudinaryToSubmission(
    $input: AddVideoFromCloudinaryInput!
  ) {
    addVideoFromCloudinaryToSubmission(input: $input) {
      video {
        id
        createDate
        clips {
          path
          thumbnail
          ordinal
        }
        recordingRoomId
        failed
      }
      submissionId
      userId
    }
  }
`;
export const updateRoomId = /* GraphQL */ `
  mutation UpdateRoomId($input: UpdateVideoAssignmentSubmissionRoomIdInput!) {
    updateRoomId(input: $input) {
      id
    }
  }
`;
export const invokeCopyStepFunctions = /* GraphQL */ `
  mutation InvokeCopyStepFunctions($input: VideoCopyInput!) {
    invokeCopyStepFunctions(input: $input) {
      success
    }
  }
`;
export const createSendbirdUser = /* GraphQL */ `
  mutation CreateSendbirdUser($input: SendbirdUserInput!) {
    createSendbirdUser(input: $input) {
      user_id
      nickname
      profile_url
      is_active
    }
  }
`;
export const updateSendbirdCustomItems = /* GraphQL */ `
  mutation UpdateSendbirdCustomItems($input: SendbirdCustomItemInput!) {
    updateSendbirdCustomItems(input: $input) {
      room_id
      updated
      custom_items {
        recordingRoomId
      }
    }
  }
`;
export const uploadToZapier = /* GraphQL */ `
  mutation UploadToZapier(
    $user_id: String!
    $user_email: String
    $message: String
    $date: String!
    $issue_type: String!
    $room: String
    $video: String
    $screenshot_link: String
  ) {
    uploadToZapier(
      user_id: $user_id
      user_email: $user_email
      message: $message
      date: $date
      issue_type: $issue_type
      room: $room
      video: $video
      screenshot_link: $screenshot_link
    ) {
      attempt
      id
      request_id
      status
    }
  }
`;
export const addChatRecord = /* GraphQL */ `
  mutation AddChatRecord($input: ChatSessionInput!) {
    addChatRecord(input: $input) {
      attempt
      id
      request_id
      status
    }
  }
`;
export const processChatRequest = /* GraphQL */ `
  mutation ProcessChatRequest($input: HccChatRequestInput!) {
    processChatRequest(input: $input) {
      sessionState {
        dialogAction {
          type
        }
        sessionId
        intent {
          state
        }
      }
      messages {
        contentType
        content
        attributions
      }
    }
  }
`;
