import React from 'react';

export const CourseAccordionArrowIcon = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 13"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
    >
      <g
        id="Accordion-/-Module-/-Desktop-/-Inactive"
        transform="translate(-22.000000, -25.000000)"
        stroke="currentColor"
        strokeWidth="2.2"
      >
        <g id="Accordion-/-Module-/-Desktop">
          <g id="accordion-arrow-modules" transform="translate(24.000000, 27.000000)">
            <path d="M0.333333333,0.264461538 L8.74533333,9" id="Line-2" />
            <path d="M17.412,0 L8.74533333,9" id="Line-2" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
