import { types } from 'mobx-state-tree';

const VideoAssignment = types.model({
  id: types.string,
  maxLengthInSeconds: types.maybeNull(types.number),
  objectType: types.string,
  minLengthInSeconds: types.maybeNull(types.number),
  gsi_pk: types.string,
  active: types.boolean,
  termId: types.maybeNull(types.number),
  sk: types.string,
  pk: types.string,
  gradingNeeded: types.boolean,
  submissionDueDate: types.string,
  title: types.maybeNull(types.string),
});

export default VideoAssignment;
