type TranscriptEntry = {
  id: string;
  question: string;
  response: string;
  userFeedback: string;
};

const formatSpacing = (text: string): string => {
  return text.replace(/\n{3,}/g, '\n\n');
};

export const convertTranscriptToMarkdown = (transcript: TranscriptEntry[]) => {
  let markdown = '# Chat Transcript\n\n';

  transcript.forEach((entry, index) => {
    markdown += `## Question ${index + 1}\n\n`;
    markdown += `${entry.question}\n\n`;
    markdown += '### Response\n\n';
    markdown += `${formatSpacing(entry.response) || 'No response'}\n\n`;
    markdown += '### User Feedback\n\n';
    markdown += `${entry.userFeedback || 'None'}\n\n`;
  });

  return markdown;
};

export const convertTranscriptToPlainText = (transcript: TranscriptEntry[]) => {
  let text = '';

  transcript.forEach((entry, index) => {
    text += `QUESTION ${index + 1}\n`;
    text += `${entry.question}\n\n`;
    text += 'RESPONSE\n';

    const formattedResponse = formatSpacing(entry.response) || 'No response';
    text += formattedResponse.endsWith('\n\n') ? formattedResponse : `${formattedResponse}\n\n`;

    text += `USER FEEDBACK: ${entry.userFeedback || 'None'}\n\n\n`;
  });

  return text;
};
