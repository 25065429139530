import { types } from 'mobx-state-tree';
import format from 'date-fns/format';

const Answer = types
  .model({
    id: types.string,
    answer: types.string,
    likes: types.maybeNull(types.number),
    createDate: types.string,
  })
  .views(self => ({
    get localDate() {
      return format(new Date(self.createDate), 'M/d/yyyy');
    },
  }));

export default Answer;
