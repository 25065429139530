import { useEffect } from 'react';
import { useStore } from 'store';

import usePrevious from 'utils/usePrevious';

export const segmentIdentify = userId => {
  if (!sessionStorage.getItem('impersonateUserId')) {
    window.analytics.identify(userId);
  }
};
export const segmentEvent = (eventName, props) => {
  if (!sessionStorage.getItem('impersonateUserId')) {
    window.analytics.track(eventName, props);
  }
};

export const useClassesDashboardViewed = dashboardStore => {
  const { loaded, isAdmin, libraryAccess, classes } = dashboardStore;

  useEffect(() => {
    if (loaded) {
      segmentEvent('Classes Dashboard Viewed', {
        isAdmin,
        allAccess: libraryAccess,
        hasClasses: classes.length > 0,
      });
    }
  }, [loaded, isAdmin, libraryAccess, classes]);
};

export const useClassDashboardViewed = activeClass => {
  const {
    rootStore: {
      dashboardStore: { isAdmin },
    },
  } = useStore();
  useEffect(() => {
    if (activeClass) {
      segmentEvent('Class Dashboard Viewed', {
        classId: activeClass.id,
        isPreview: activeClass.userType === 'previewer',
        isAdmin: isAdmin || activeClass.userType === 'administrator',
      });
    }
  }, [activeClass]);
};

export const useClassNotificationRead = (menuActive, unreadNotifications) => {
  useEffect(() => {
    if (menuActive && !!unreadNotifications && unreadNotifications.length) {
      unreadNotifications.map(notification => {
        segmentEvent('Class Notification Read', {
          classId: notification.classId,
        });
      });
    }
  }, [menuActive, unreadNotifications]);
};

export const useClassStarted = videoPlayed => {
  const {
    rootStore: {
      classDetailsStore: { activeClass },
      dashboardStore,
    },
  } = useStore();

  const { id, classStarted, totalProgress } = activeClass;

  useEffect(() => {
    if (videoPlayed && !classStarted && !totalProgress) {
      segmentEvent('Class Started', {
        classId: id,
        allAccess: dashboardStore.libraryAccess,
      });
    }
  }, [videoPlayed, classStarted, id, totalProgress, dashboardStore.libraryAccess]);
};

export const useClassVideoViewed = videoPlayed => {
  const {
    rootStore: {
      classDetailsStore: { activeClass },
      dashboardStore: { isAdmin },
      videoStore,
    },
  } = useStore();

  const { id, userType } = activeClass;

  useEffect(() => {
    if (videoPlayed) {
      segmentEvent('Class Video Viewed', {
        classId: id,
        sessionId: videoStore.currentLessonId,
        isPreview: userType === 'previewer',
        isAdmin: isAdmin || activeClass.userType === 'administrator',
      });
    }
  }, [
    videoPlayed,
    videoStore.videoLink,
    id,
    videoStore.currentLessonId,
    userType,
    isAdmin,
    activeClass,
  ]);
};

export const useClassModuleCompleted = (completed, classId, moduleId) => {
  const previousCompleted = usePrevious(completed);

  useEffect(() => {
    if (completed && previousCompleted !== undefined && previousCompleted !== completed) {
      segmentEvent('Class Module Completed', {
        classId,
        moduleId,
      });
    }
  }, [completed, previousCompleted, classId, moduleId]);
};

export const useClassCompleted = (moduleCompleted, classId) => {
  const {
    rootStore: {
      classDetailsStore: { activeClass },
    },
  } = useStore();

  const previousModuleCompleted = usePrevious(moduleCompleted);

  useEffect(() => {
    if (
      moduleCompleted &&
      previousModuleCompleted !== undefined &&
      previousModuleCompleted !== moduleCompleted
    ) {
      const notCompleted = activeClass.modules.some(module => module.completed);
      if (!notCompleted) {
        segmentEvent('Class Completed', {
          classId,
        });
      }
    }
  }, [moduleCompleted, previousModuleCompleted, activeClass.modules, classId]);
};

export const useClassAssessmentCompleted = (classId, sessionId) => {
  const {
    rootStore: {
      quizStore: { assessmentProgress },
    },
  } = useStore();

  const finished = assessmentProgress?.finished;
  const previousFinished = usePrevious(finished);

  useEffect(() => {
    if (
      !!assessmentProgress &&
      finished &&
      previousFinished !== undefined &&
      finished !== previousFinished
    ) {
      segmentEvent('Class Assessment Completed', {
        classId,
        sessionId,
      });
    }
  }, [classId, sessionId, assessmentProgress, finished, previousFinished]);
};
