import axios from 'axios';

const instance = axios.create({
  withCredentials: true,
  baseURL: process.env.API_URL,
});

instance.interceptors.response.use(
  response => {
    return response;
  },
  ({ message, response: { status } }) => {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      status,
      message,
    });
  }
);

export default instance;
