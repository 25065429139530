import { types } from 'mobx-state-tree';
import { updateGiftDetails } from 'api';
import { compareAsc, parseISO, format } from 'date-fns';

const PurchasedGift = types
  .model({
    id: types.string,
    classId: types.maybeNull(types.number),
    libraryId: types.maybeNull(types.string),
    createDate: types.string,
    claimed: types.boolean,
    claimDate: types.maybeNull(types.string),
    classTitle: types.maybeNull(types.string),
    classImage: types.maybeNull(types.string),
    emailResent: false,
    updating: false,
    updated: false,
    senderEmail: types.maybeNull(types.string),
    // props below can be modified
    recipientName: types.maybeNull(types.string),
    senderName: types.maybeNull(types.string),
    notificationTime: types.maybeNull(types.string),
    emailAddress: types.maybeNull(types.string),
  })
  .views(self => ({
    get emailSent() {
      return compareAsc(new Date(self.notificationTime), new Date()) > -1;
    },

    get deliveryDate() {
      return new Date(self.notificationTime).toISOString();
    },
  }))
  .actions(self => ({
    async resendEmail() {
      const formData = new FormData();
      // Note: the delivery date is set to now in order to send email asap.
      formData.append('notificationTime', new Date().toISOString());

      formData.append('emailAddress', self.emailAddress);
      formData.append('senderName', self.senderName);
      formData.append('recipientName', self.recipientName);
      formData.append('id', self.id);
      formData.append('classId', self.classId);
      formData.append('libraryId', self.libraryId);
      await self.updateDetails(formData);
      self.resendEmailSuccess();
    },

    resendEmailSuccess() {
      self.emailResent = true;
    },

    async updateDetails(formData) {
      self.updating = true;
      self.updated = false;
      self.emailResent = false;

      // convert delivery date to GMT
      formData.set(
        'notificationTime',
        format(parseISO(formData.get('notificationTime')), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
      );

      try {
        const giftUpdated = Object.fromEntries(formData);

        // In case the giftUpdated contains a past delivery date or undefined value, set it to now.
        if(!giftUpdated.notificationTime || new Date(giftUpdated.notificationTime) < new Date()) {
          giftUpdated.notificationTime = new Date().toISOString();
        }

        if(giftUpdated.libraryId && giftUpdated.libraryId === 'null') delete giftUpdated.libraryId;
        if(giftUpdated.classId){
          if(giftUpdated.classId === 'null') delete giftUpdated.classId;
          else giftUpdated.classId = Number(giftUpdated.classId);
        }

        await updateGiftDetails(giftUpdated);
        self.updateDetailsSuccess(formData);
      } catch (error) {
        self.updateDetailsError();
      }
    },

    updateDetailsSuccess(formData) {
      self.updating = false;
      self.updated = true;
      self.notificationTime = formData.get('notificationTime');
      self.emailAddress = formData.get('emailAddress');
      self.senderName = formData.get('senderName');
      self.recipientName = formData.get('recipientName');
    },

    updateDetailsError() {
      self.updating = false;
    },
  }));

export default PurchasedGift;
