import React from 'react';

export const PlayerPrevIcon = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 12 14" xmlns="http://www.w3.org/2000/svg">
    <g
      id="Player"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
    >
      <g id="Play" transform="translate(-126.000000, -714.000000)" stroke="#FFFFFF">
        <g id="Group-5" transform="translate(126.000000, 714.000000)">
          <g id="Previous-Icon">
            <path d="M0.5,0.5 L0.5,12.5292065" id="Line-4" />
            <polygon id="Line-4" points="3.66501322 6.76460325 11.5 1 11.5 12.5292065" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
