import { useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store';
import Footer from 'components/footer';
import PageHead from '../page-head';
import Navigation from '../navigation';
import { GlobalStyle, Content, FullScreenContent } from './style';

const Layout = ({ warning: Warning, children }) => {
  const {
    rootStore: {
      layoutStore: { noNavigation, fullScreen },
      classDetailsStore,
    },
  } = useStore();

  return (
    <>
      {Warning && Warning}
      <GlobalStyle />
      <PageHead />
      {!noNavigation && !classDetailsStore.loading ? <Navigation /> : null}
      {fullScreen ? (
        <FullScreenContent>{children}</FullScreenContent>
      ) : (
        <>
          <Content noNavigation={noNavigation}>{children}</Content>
          {!classDetailsStore.loading ? <Footer /> : null}
        </>
      )}
    </>
  );
};

export default observer(Layout);
