import React from 'react';

export const IconHome = ({ width, height, title }) => (
  <svg viewBox="0 0 10.99 16" xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
    {title && <title>{title}</title>}
    <path
      fill="currentColor"
      d="M6.61 5.5a3.67 3.67 0 01-.7-2.33A4.49 4.49 0 017.75.12a.66.66 0 01.77 0l.07.06a4.1 4.1 0 011.72 3.15 3 3 0 01-1 2 .25.25 0 01-.19.07.27.27 0 01-.12-.11l-.46-.61a.31.31 0 010-.4 1.59 1.59 0 00.45-1 2.45 2.45 0 00-.79-1.63.13.13 0 00-.1 0 .13.13 0 00-.1 0 2.73 2.73 0 00-.78 1.62 2.83 2.83 0 001 2.09.17.17 0 01.06.18s-.05.09-.16.09L7 5.69a.48.48 0 01-.39-.19zm4.16.43a.66.66 0 00-.65-.14 17.16 17.16 0 01-3.75.29.4.4 0 01-.28-.14c-.12-.18-.22-.35-.33-.56l-.25-.45-.06-.11-.11-.18-.09-.1a.66.66 0 00-.49-.18 2.76 2.76 0 01-2.29-.63 1.77 1.77 0 01-.42-1 .64.64 0 010-.14 2.66 2.66 0 011.73.48 1.12 1.12 0 01.33.5.35.35 0 00.32.25h.77a.27.27 0 00.2-.09.24.24 0 00.06-.2 2.39 2.39 0 00-.77-1.4 4.25 4.25 0 00-3.31-.81h-.05a.66.66 0 00-.57.54 3.48 3.48 0 00.73 2.71A3.64 3.64 0 004.26 5.7a.34.34 0 01.26.15l.13.23h-.1A17 17 0 011 5.8l-.4-.12a.28.28 0 00-.34.19L0 6.6a.28.28 0 00.19.34l.38.11a20.85 20.85 0 004.9.35 37.76 37.76 0 003.86-.16.07.07 0 01.09.1l-.3 1.23a.28.28 0 01-.2.19A23 23 0 015.51 9c-1.13 0-2.16 0-3.06-.13a.23.23 0 01-.19-.17l-.08-.4a.37.37 0 00-.3-.3l-.82-.1a.21.21 0 00-.24.26l.06.32a.08.08 0 010 .07.08.08 0 01-.07 0H.73a.28.28 0 00-.33.21l-.2.76a.28.28 0 00.21.33l.37.09L1 10a.26.26 0 01.2.19l1 5a.66.66 0 00.41.49 8.62 8.62 0 002.9.39 11.51 11.51 0 003.1-.37.66.66 0 00.39-.56L9.91 11a.21.21 0 00-.24-.26l-.83.1a.38.38 0 00-.31.28l-.68 3.18a.28.28 0 01-.2.2 11.93 11.93 0 01-2.18.19 9.88 9.88 0 01-1.93-.17.28.28 0 01-.19-.2l-.79-4a.1.1 0 010-.08.1.1 0 01.08 0c.85.07 1.81.1 2.84.1a15.59 15.59 0 004.34-.37.66.66 0 00.43-.47L11 6.57a.66.66 0 00-.23-.64z"
    />
  </svg>
);
