import { types, getRoot } from 'mobx-state-tree';

const FavoriteLesson = types
  .model({
    id: types.string,
    title: types.string,
    type: types.string,
    videoUrl: types.maybeNull(types.string),
    image: types.maybeNull(types.string),
    videoDurationInSec: types.maybeNull(types.number),
  })
  .views(self => ({
    get class() {
      const { classStore } = getRoot(self);
      const classes = [...classStore.classesForFavoriteLessons.values()];
      return classes.find(cls => cls.containsLesson(self.id));
    },
  }));

export default FavoriteLesson;
