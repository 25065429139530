import React from 'react';

export const CloseIcon = ({ width, height, title, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13" width={width} height={height}>
    {title && <title>{title}</title>}
    <path
      fill={`${color || '#000'}`}
      d="M0,11.5L11.5,0L13,1.4L1.4,13L0,11.5z M1.4,0L13,11.5L11.5,13L0,1.4L1.4,0z"
    />
  </svg>
);
