// group classes by category and sort the category keys
const groupClasses = classes => {
  if (!classes?.length) return {};

  const mapping = classes.reduce((agg, cls) => {
    // if a cls has no categories, set the default categories to ['Wellness']
    const keys = cls.categories.length ? cls.categories : ['Wellness'];

    keys.forEach(key => {
      // eslint-disable-next-line no-param-reassign
      agg = {
        ...agg,
        // eslint-disable-next-line no-extra-parens
        [key]: [...(agg[key] || []), cls],
      };
    });

    return agg;
  }, {});

  return Object.keys(mapping)
    .sort()
    .reduce((agg, next) => {
      return {
        ...agg,
        [next]: mapping[next],
      };
    }, {});
};

export default groupClasses;
