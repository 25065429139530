import React from 'react';

export const IconSpirituality = ({ width, height, title }) => (
  <svg viewBox="0 0 9.45 16" xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
    {title && <title>{title}</title>}
    <path
      fill="currentColor"
      d="M9.27 7.41c0-.32.08-.63.13-.85a2.17 2.17 0 00.05-.4.66.66 0 00-.72-.6h-.05a24.64 24.64 0 01-3.31.15l-5-.08a.43.43 0 00-.35.17 2.55 2.55 0 000 1 .4.4 0 00.37.2l5 .08C6.15 7 6.93 7 7.68 7a.26.26 0 01.24.13.3.3 0 010 .18v.07a28.73 28.73 0 000 5.16 2.06 2.06 0 01-.58 1.64 3.65 3.65 0 01-2.48.5h-.7a4.09 4.09 0 01-1.91-.27 1.16 1.16 0 01-.3-.22 1.39 1.39 0 01-.25-.38 3 3 0 01-.2-1.4v-.24c0-.65 0-1.34-.06-2v-.25a1.58 1.58 0 01.08-.95.28.28 0 01.32-.14c.27.09.31.46.32.81v.33a5.79 5.79 0 000 .61 3 3 0 00.23 1 2 2 0 00.21.36 1.26 1.26 0 001.12.48 1.49 1.49 0 001.14-.79 3.61 3.61 0 00.33-1.25v-.41c0-.1 0-.46.2-.5a.13.13 0 01.12 0c.11.09.11.36.11.6v.14a3 3 0 00.23 1.15 1.62 1.62 0 001.1.9.41.41 0 00.35-.09.4.4 0 00.14-.33v-.54a.42.42 0 00-.25-.35h-.07a1.27 1.27 0 01-.26-.92v-.31a1.69 1.69 0 00-.78-1.44 1.33 1.33 0 00-1.77.57A3.59 3.59 0 004 10.17c0 .16 0 .32-.05.48a.64.64 0 01-.08.24.22.22 0 01-.14.08.15.15 0 01-.12 0 .55.55 0 01-.13-.31 2.54 2.54 0 010-.35v-.18a7.27 7.27 0 000-.83c-.09-.86-.54-1.8-1.53-1.87a1.51 1.51 0 00-1.19.42 2.28 2.28 0 00-.57 1.7v.13c0 .91.09 1.78.06 2.66a3.59 3.59 0 00.81 2.76 2.48 2.48 0 00.65.49 5.19 5.19 0 002.51.41h1.19a4.25 4.25 0 002.86-.83 3.34 3.34 0 001-2.74 27.51 27.51 0 010-4.92zM3.26 4.31a.36.36 0 00.29 0l.56-.22a.36.36 0 00.21-.44 1.83 1.83 0 010-1.19 3.13 3.13 0 01.32-.58.16.16 0 01.13-.07.15.15 0 01.13.07 3.91 3.91 0 01.32 3 .35.35 0 000 .32.36.36 0 00.29.15h.54a.36.36 0 00.32-.19A4.13 4.13 0 005.64.65l-.1-.11-.12-.13-.1-.1-.11-.13L5.1.09a.68.68 0 00-.9.21L4 .62A6 6 0 003.12 2a3.15 3.15 0 000 2.12.36.36 0 00.14.19z"
    />
  </svg>
);
