export const QuizFalseIcon = ({ className, width, height }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 16.5 16.504"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1=".75"
      x2="15.75"
      y1=".75"
      y2="15.754"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <line
      x1="15.75"
      x2=".762"
      y1=".754"
      y2="15.754"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);
