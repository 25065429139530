import React from 'react';

export const AccountIcon = ({ width, height, title }) => (
  <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
    {title && <title>{title}</title>}
    <path
      fill="currentColor"
      d="m10.755 10.638-0.9-0.216-0.306-0.081c-1.2915-0.19762-2.5134-0.71421-3.555-1.503-0.44916-0.43342-0.77792-0.97617-0.954-1.575-0.36624-1.2299-0.14009-2.5602 0.612-3.6 0.7586-1.0081 1.9339-1.6173 3.195-1.656 1.2763 0.034748 2.4665 0.65143 3.231 1.674 0.75209 1.0398 0.97824 2.3701 0.612 3.6-0.31669 0.61073-0.73692 1.1619-1.242 1.629-0.078709 0.062927-0.11416 0.16554-0.091079 0.26364 0.023081 0.098093 0.10057 0.17414 0.19908 0.19536l1.26 0.423c0.25814 0.073338 0.53565-0.0029773 0.72-0.198 0.47028-0.52653 0.83672-1.1373 1.08-1.8 0.54025-1.8255 0.20816-3.7981-0.9-5.346-1.1618-1.5194-2.9565-2.4217-4.869-2.448-1.9015 0.052075-3.6749 0.97029-4.815 2.493-1.1069 1.5485-1.4389 3.5205-0.9 5.346 0.27633 0.93478 0.79121 1.7815 1.494 2.457 0.15613 0.14827 0.32146 0.28654 0.495 0.414 0.198 0.144 0.18 0.297-0.063 0.36-1.971 0.486-3.528 1.062-4.158 1.953s-0.9 3.06-0.9 4.5c3.1225e-17 0.24853 0.20147 0.45 0.45 0.45h1.071c0.24501-0.0049011 0.44105-0.20494 0.441-0.45-0.043445-1.1498 0.15271-2.2961 0.576-3.366 0.477-0.657 3.96-1.377 5.274-1.647l0.693-0.144c0.15718-0.038954 0.31643-0.069001 0.477-0.09l0.297 0.081 0.972 0.234c1.215 0.279 3.744 0.855 4.266 1.593 0.42329 1.0699 0.61945 2.2162 0.576 3.366-4.9e-5 0.24506 0.19599 0.4451 0.441 0.45h1.107c0.24853 0 0.45-0.20147 0.45-0.45 0-1.467-0.225-3.51-0.9-4.5-0.882-1.287-2.997-1.854-5.436-2.412z"
    />
  </svg>
);
