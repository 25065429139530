import { API, graphqlOperation } from 'aws-amplify';
import { processChatRequest as processChatRequestMutation } from './graphql/mutations';

interface ProcessChatRequestProps {
  input: string;
  sessionId?: string;
}

export const processChatRequest = async (body: ProcessChatRequestProps) => {
  const { data } = await API.graphql(graphqlOperation(processChatRequestMutation, { input: body }));

  return data;
};
