/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($userId: Int) {
    getUser(userId: $userId) {
      userId
      emailAddress
      username
      firstName
      lastName
    }
  }
`;
export const getLoggedInUser = /* GraphQL */ `
  query GetLoggedInUser {
    getLoggedInUser {
      userId
      emailAddress
      username
      firstName
      lastName
    }
  }
`;
export const getIsUserAdmin = /* GraphQL */ `
  query GetIsUserAdmin {
    getIsUserAdmin
  }
`;
export const getUserClasses = /* GraphQL */ `
  query GetUserClasses {
    getUserClasses {
      libraryAccess
      classesTaking {
        id
        title
        urlKey
        type
        curriculumType
        image {
          path
          crops {
            path
            dimensions
            width
            height
          }
        }
        instructors {
          id
          image
          biography
          name {
            honorific
            first
            last
            suffix
            nickname
            credentials
            fullName
          }
        }
        instructorDisplay
        teachingAssistants {
          id
          image
          biography
          name {
            honorific
            first
            last
            suffix
            nickname
            credentials
            fullName
          }
        }
        resources {
          id
          type
          title
          url
          termId
          featureOnDashboard
        }
        modules {
          id
          title
          description
          currentModule
          image
          lessons {
            id
            classId
            moduleId
            termId
            title
            currentLesson
            image
            description
            type
            instructorIds
            resources {
              id
              type
              title
              url
              termId
              featureOnDashboard
            }
            videoId
            videoDurationInSec
            videoUrl
            mediaUrl
            videoPlayerHead
            completed
            optional
            assignmentQuestions {
              id
              questionText
              questionChoices {
                id
                text
              }
              answerId
            }
            assessmentQuestions {
              id
              questionText
              questionChoices {
                id
                text
              }
              answerId
              randomize
            }
            instances {
              id
              link
              host
              startDate
              videoId
              mediaUrl
            }
            quizInProgress
          }
          modules {
            id
            title
            description
            currentModule
            image
            lessons {
              id
              classId
              moduleId
              termId
              title
              currentLesson
              image
              description
              type
              instructorIds
              resources {
                id
                type
                title
                url
                termId
                featureOnDashboard
              }
              videoId
              videoDurationInSec
              videoUrl
              mediaUrl
              videoPlayerHead
              completed
              optional
              assignmentQuestions {
                id
                questionText
                answerId
              }
              assessmentQuestions {
                id
                questionText
                answerId
                randomize
              }
              instances {
                id
                link
                host
                startDate
                videoId
                mediaUrl
              }
              quizInProgress
            }
            modules {
              id
              title
              description
              currentModule
              image
              lessons {
                id
                classId
                moduleId
                termId
                title
                currentLesson
                image
                description
                type
                instructorIds
                videoId
                videoDurationInSec
                videoUrl
                mediaUrl
                videoPlayerHead
                completed
                optional
                quizInProgress
              }
              modules {
                id
                title
                description
                currentModule
                image
              }
            }
          }
        }
        favoriteLessons {
          id
          classId
          moduleId
          termId
          title
          currentLesson
          image
          description
          type
          instructorIds
          resources {
            id
            type
            title
            url
            termId
            featureOnDashboard
          }
          videoId
          videoDurationInSec
          videoUrl
          mediaUrl
          videoPlayerHead
          completed
          optional
          assignmentQuestions {
            id
            questionText
            questionChoices {
              id
              text
            }
            answerId
          }
          assessmentQuestions {
            id
            questionText
            questionChoices {
              id
              text
            }
            answerId
            randomize
          }
          instances {
            id
            link
            host
            startDate
            videoId
            mediaUrl
          }
          quizInProgress
        }
        officeHours {
          id
          startDateTime
          endDateTime
          title
          registrationLink
          recordedVideoLink
          embedCode
          transcript {
            title
            description
            file {
              url
              fileName
              contentType
            }
          }
          instructorNames
          instructorImages
        }
        term {
          id
          title
          startDate
          signupCutOffDate
        }
        terms {
          id
          title
          startDate
          signupCutOffDate
        }
        price
        totalProgress
        demoCloudinaryImage {
          path
          crops {
            path
            dimensions
            width
            height
          }
        }
        curriculum
        categories
        userType
        freeTrialInfo {
          token
          activated
          totalSecondsConsumed
          freeTrialLimit
        }
        notifications {
          unread {
            id
            type
            url
            qnaTitle
            classTitle
            classId
            text
            subText
            createDate
            qnaStartDate
            classCategory
            classUrlKey
          }
          read {
            id
            type
            url
            qnaTitle
            classTitle
            classId
            text
            subText
            createDate
            qnaStartDate
            classCategory
            classUrlKey
          }
        }
        description
        classMaterials
        classStarted
        videoAssignments {
          id
          maxLengthInSeconds
          objectType
          minLengthInSeconds
          gsi_pk
          active
          termId
          sk
          pk
          gradingNeeded
          submissionDueDate
          title
        }
      }
      favoriteLessons {
        id
        classId
        moduleId
        termId
        title
        currentLesson
        image
        description
        type
        instructorIds
        resources {
          id
          type
          title
          url
          termId
          featureOnDashboard
        }
        videoId
        videoDurationInSec
        videoUrl
        mediaUrl
        videoPlayerHead
        completed
        optional
        assignmentQuestions {
          id
          questionText
          questionChoices {
            id
            text
          }
          answerId
        }
        assessmentQuestions {
          id
          questionText
          questionChoices {
            id
            text
          }
          answerId
          randomize
        }
        instances {
          id
          link
          host
          startDate
          videoId
          mediaUrl
        }
        quizInProgress
      }
      notifications {
        unread {
          id
          type
          url
          qnaTitle
          classTitle
          classId
          text
          subText
          createDate
          qnaStartDate
          classCategory
          classUrlKey
        }
        read {
          id
          type
          url
          qnaTitle
          classTitle
          classId
          text
          subText
          createDate
          qnaStartDate
          classCategory
          classUrlKey
        }
      }
    }
  }
`;
export const getClassesTeaching = /* GraphQL */ `
  query GetClassesTeaching {
    getClassesTeaching {
      classesTeaching {
        id
        title
        urlKey
        type
        image {
          path
          crops {
            path
            dimensions
            width
            height
          }
        }
        publishDate
        userType
        unreadAssignmentsCount
      }
      favoriteLessons {
        id
        classId
        moduleId
        termId
        title
        currentLesson
        image
        description
        type
        instructorIds
        resources {
          id
          type
          title
          url
          termId
          featureOnDashboard
        }
        videoId
        videoDurationInSec
        videoUrl
        mediaUrl
        videoPlayerHead
        completed
        optional
        assignmentQuestions {
          id
          questionText
          questionChoices {
            id
            text
          }
          answerId
        }
        assessmentQuestions {
          id
          questionText
          questionChoices {
            id
            text
          }
          answerId
          randomize
        }
        instances {
          id
          link
          host
          startDate
          videoId
          mediaUrl
        }
        quizInProgress
      }
    }
  }
`;
export const getUserAccessibleClasses = /* GraphQL */ `
  query GetUserAccessibleClasses {
    getUserAccessibleClasses {
      id
      title
      urlKey
      type
      curriculumType
      image {
        path
        crops {
          path
          dimensions
          width
          height
        }
      }
      instructors {
        id
        image
        biography
        name {
          honorific
          first
          last
          suffix
          nickname
          credentials
          fullName
        }
      }
      instructorDisplay
      teachingAssistants {
        id
        image
        biography
        name {
          honorific
          first
          last
          suffix
          nickname
          credentials
          fullName
        }
      }
      resources {
        id
        type
        title
        url
        termId
        featureOnDashboard
      }
      modules {
        id
        title
        description
        currentModule
        image
        lessons {
          id
          classId
          moduleId
          termId
          title
          currentLesson
          image
          description
          type
          instructorIds
          resources {
            id
            type
            title
            url
            termId
            featureOnDashboard
          }
          videoId
          videoDurationInSec
          videoUrl
          mediaUrl
          videoPlayerHead
          completed
          optional
          assignmentQuestions {
            id
            questionText
            questionChoices {
              id
              text
            }
            answerId
          }
          assessmentQuestions {
            id
            questionText
            questionChoices {
              id
              text
            }
            answerId
            randomize
          }
          instances {
            id
            link
            host
            startDate
            videoId
            mediaUrl
          }
          quizInProgress
        }
        modules {
          id
          title
          description
          currentModule
          image
          lessons {
            id
            classId
            moduleId
            termId
            title
            currentLesson
            image
            description
            type
            instructorIds
            resources {
              id
              type
              title
              url
              termId
              featureOnDashboard
            }
            videoId
            videoDurationInSec
            videoUrl
            mediaUrl
            videoPlayerHead
            completed
            optional
            assignmentQuestions {
              id
              questionText
              questionChoices {
                id
                text
              }
              answerId
            }
            assessmentQuestions {
              id
              questionText
              questionChoices {
                id
                text
              }
              answerId
              randomize
            }
            instances {
              id
              link
              host
              startDate
              videoId
              mediaUrl
            }
            quizInProgress
          }
          modules {
            id
            title
            description
            currentModule
            image
            lessons {
              id
              classId
              moduleId
              termId
              title
              currentLesson
              image
              description
              type
              instructorIds
              resources {
                id
                type
                title
                url
                termId
                featureOnDashboard
              }
              videoId
              videoDurationInSec
              videoUrl
              mediaUrl
              videoPlayerHead
              completed
              optional
              assignmentQuestions {
                id
                questionText
                answerId
              }
              assessmentQuestions {
                id
                questionText
                answerId
                randomize
              }
              instances {
                id
                link
                host
                startDate
                videoId
                mediaUrl
              }
              quizInProgress
            }
            modules {
              id
              title
              description
              currentModule
              image
              lessons {
                id
                classId
                moduleId
                termId
                title
                currentLesson
                image
                description
                type
                instructorIds
                videoId
                videoDurationInSec
                videoUrl
                mediaUrl
                videoPlayerHead
                completed
                optional
                quizInProgress
              }
              modules {
                id
                title
                description
                currentModule
                image
              }
            }
          }
        }
      }
      favoriteLessons {
        id
        classId
        moduleId
        termId
        title
        currentLesson
        image
        description
        type
        instructorIds
        resources {
          id
          type
          title
          url
          termId
          featureOnDashboard
        }
        videoId
        videoDurationInSec
        videoUrl
        mediaUrl
        videoPlayerHead
        completed
        optional
        assignmentQuestions {
          id
          questionText
          questionChoices {
            id
            text
          }
          answerId
        }
        assessmentQuestions {
          id
          questionText
          questionChoices {
            id
            text
          }
          answerId
          randomize
        }
        instances {
          id
          link
          host
          startDate
          videoId
          mediaUrl
        }
        quizInProgress
      }
      officeHours {
        id
        startDateTime
        endDateTime
        title
        registrationLink
        recordedVideoLink
        embedCode
        transcript {
          title
          description
          file {
            url
            fileName
            contentType
          }
        }
        instructorNames
        instructorImages
      }
      term {
        id
        title
        startDate
        signupCutOffDate
      }
      terms {
        id
        title
        startDate
        signupCutOffDate
      }
      price
      totalProgress
      demoCloudinaryImage {
        path
        crops {
          path
          dimensions
          width
          height
        }
      }
      curriculum
      categories
      userType
      freeTrialInfo {
        token
        activated
        totalSecondsConsumed
        freeTrialLimit
      }
      notifications {
        unread {
          id
          type
          url
          qnaTitle
          classTitle
          classId
          text
          subText
          createDate
          qnaStartDate
          classCategory
          classUrlKey
        }
        read {
          id
          type
          url
          qnaTitle
          classTitle
          classId
          text
          subText
          createDate
          qnaStartDate
          classCategory
          classUrlKey
        }
      }
      description
      classMaterials
      classStarted
      videoAssignments {
        id
        maxLengthInSeconds
        objectType
        minLengthInSeconds
        gsi_pk
        active
        termId
        sk
        pk
        gradingNeeded
        submissionDueDate
        title
      }
    }
  }
`;
export const getUserInaccessibleClasses = /* GraphQL */ `
  query GetUserInaccessibleClasses {
    getUserInaccessibleClasses {
      id
      title
      urlKey
      type
      curriculumType
      image {
        path
        crops {
          path
          dimensions
          width
          height
        }
      }
      instructors {
        id
        image
        biography
        name {
          honorific
          first
          last
          suffix
          nickname
          credentials
          fullName
        }
      }
      instructorDisplay
      teachingAssistants {
        id
        image
        biography
        name {
          honorific
          first
          last
          suffix
          nickname
          credentials
          fullName
        }
      }
      resources {
        id
        type
        title
        url
        termId
        featureOnDashboard
      }
      modules {
        id
        title
        description
        currentModule
        image
        lessons {
          id
          classId
          moduleId
          termId
          title
          currentLesson
          image
          description
          type
          instructorIds
          resources {
            id
            type
            title
            url
            termId
            featureOnDashboard
          }
          videoId
          videoDurationInSec
          videoUrl
          mediaUrl
          videoPlayerHead
          completed
          optional
          assignmentQuestions {
            id
            questionText
            questionChoices {
              id
              text
            }
            answerId
          }
          assessmentQuestions {
            id
            questionText
            questionChoices {
              id
              text
            }
            answerId
            randomize
          }
          instances {
            id
            link
            host
            startDate
            videoId
            mediaUrl
          }
          quizInProgress
        }
        modules {
          id
          title
          description
          currentModule
          image
          lessons {
            id
            classId
            moduleId
            termId
            title
            currentLesson
            image
            description
            type
            instructorIds
            resources {
              id
              type
              title
              url
              termId
              featureOnDashboard
            }
            videoId
            videoDurationInSec
            videoUrl
            mediaUrl
            videoPlayerHead
            completed
            optional
            assignmentQuestions {
              id
              questionText
              questionChoices {
                id
                text
              }
              answerId
            }
            assessmentQuestions {
              id
              questionText
              questionChoices {
                id
                text
              }
              answerId
              randomize
            }
            instances {
              id
              link
              host
              startDate
              videoId
              mediaUrl
            }
            quizInProgress
          }
          modules {
            id
            title
            description
            currentModule
            image
            lessons {
              id
              classId
              moduleId
              termId
              title
              currentLesson
              image
              description
              type
              instructorIds
              resources {
                id
                type
                title
                url
                termId
                featureOnDashboard
              }
              videoId
              videoDurationInSec
              videoUrl
              mediaUrl
              videoPlayerHead
              completed
              optional
              assignmentQuestions {
                id
                questionText
                answerId
              }
              assessmentQuestions {
                id
                questionText
                answerId
                randomize
              }
              instances {
                id
                link
                host
                startDate
                videoId
                mediaUrl
              }
              quizInProgress
            }
            modules {
              id
              title
              description
              currentModule
              image
              lessons {
                id
                classId
                moduleId
                termId
                title
                currentLesson
                image
                description
                type
                instructorIds
                videoId
                videoDurationInSec
                videoUrl
                mediaUrl
                videoPlayerHead
                completed
                optional
                quizInProgress
              }
              modules {
                id
                title
                description
                currentModule
                image
              }
            }
          }
        }
      }
      favoriteLessons {
        id
        classId
        moduleId
        termId
        title
        currentLesson
        image
        description
        type
        instructorIds
        resources {
          id
          type
          title
          url
          termId
          featureOnDashboard
        }
        videoId
        videoDurationInSec
        videoUrl
        mediaUrl
        videoPlayerHead
        completed
        optional
        assignmentQuestions {
          id
          questionText
          questionChoices {
            id
            text
          }
          answerId
        }
        assessmentQuestions {
          id
          questionText
          questionChoices {
            id
            text
          }
          answerId
          randomize
        }
        instances {
          id
          link
          host
          startDate
          videoId
          mediaUrl
        }
        quizInProgress
      }
      officeHours {
        id
        startDateTime
        endDateTime
        title
        registrationLink
        recordedVideoLink
        embedCode
        transcript {
          title
          description
          file {
            url
            fileName
            contentType
          }
        }
        instructorNames
        instructorImages
      }
      term {
        id
        title
        startDate
        signupCutOffDate
      }
      terms {
        id
        title
        startDate
        signupCutOffDate
      }
      price
      totalProgress
      demoCloudinaryImage {
        path
        crops {
          path
          dimensions
          width
          height
        }
      }
      curriculum
      categories
      userType
      freeTrialInfo {
        token
        activated
        totalSecondsConsumed
        freeTrialLimit
      }
      notifications {
        unread {
          id
          type
          url
          qnaTitle
          classTitle
          classId
          text
          subText
          createDate
          qnaStartDate
          classCategory
          classUrlKey
        }
        read {
          id
          type
          url
          qnaTitle
          classTitle
          classId
          text
          subText
          createDate
          qnaStartDate
          classCategory
          classUrlKey
        }
      }
      description
      classMaterials
      classStarted
      videoAssignments {
        id
        maxLengthInSeconds
        objectType
        minLengthInSeconds
        gsi_pk
        active
        termId
        sk
        pk
        gradingNeeded
        submissionDueDate
        title
      }
    }
  }
`;
export const getUserClass = /* GraphQL */ `
  query GetUserClass($classId: Int, $classSlug: String, $termId: Int) {
    getUserClass(classId: $classId, classSlug: $classSlug, termId: $termId) {
      id
      title
      urlKey
      type
      curriculumType
      image {
        path
        crops {
          path
          dimensions
          width
          height
        }
      }
      instructors {
        id
        image
        biography
        name {
          honorific
          first
          last
          suffix
          nickname
          credentials
          fullName
        }
      }
      instructorDisplay
      teachingAssistants {
        id
        image
        biography
        name {
          honorific
          first
          last
          suffix
          nickname
          credentials
          fullName
        }
      }
      resources {
        id
        type
        title
        url
        termId
        featureOnDashboard
      }
      modules {
        id
        title
        description
        currentModule
        image
        lessons {
          id
          classId
          moduleId
          termId
          title
          currentLesson
          image
          description
          type
          instructorIds
          resources {
            id
            type
            title
            url
            termId
            featureOnDashboard
          }
          videoId
          videoDurationInSec
          videoUrl
          mediaUrl
          videoPlayerHead
          completed
          optional
          assignmentQuestions {
            id
            questionText
            questionChoices {
              id
              text
            }
            answerId
          }
          assessmentQuestions {
            id
            questionText
            questionChoices {
              id
              text
            }
            answerId
            randomize
          }
          instances {
            id
            link
            host
            startDate
            videoId
            mediaUrl
          }
          quizInProgress
        }
        modules {
          id
          title
          description
          currentModule
          image
          lessons {
            id
            classId
            moduleId
            termId
            title
            currentLesson
            image
            description
            type
            instructorIds
            resources {
              id
              type
              title
              url
              termId
              featureOnDashboard
            }
            videoId
            videoDurationInSec
            videoUrl
            mediaUrl
            videoPlayerHead
            completed
            optional
            assignmentQuestions {
              id
              questionText
              questionChoices {
                id
                text
              }
              answerId
            }
            assessmentQuestions {
              id
              questionText
              questionChoices {
                id
                text
              }
              answerId
              randomize
            }
            instances {
              id
              link
              host
              startDate
              videoId
              mediaUrl
            }
            quizInProgress
          }
          modules {
            id
            title
            description
            currentModule
            image
            lessons {
              id
              classId
              moduleId
              termId
              title
              currentLesson
              image
              description
              type
              instructorIds
              resources {
                id
                type
                title
                url
                termId
                featureOnDashboard
              }
              videoId
              videoDurationInSec
              videoUrl
              mediaUrl
              videoPlayerHead
              completed
              optional
              assignmentQuestions {
                id
                questionText
                answerId
              }
              assessmentQuestions {
                id
                questionText
                answerId
                randomize
              }
              instances {
                id
                link
                host
                startDate
                videoId
                mediaUrl
              }
              quizInProgress
            }
            modules {
              id
              title
              description
              currentModule
              image
              lessons {
                id
                classId
                moduleId
                termId
                title
                currentLesson
                image
                description
                type
                instructorIds
                videoId
                videoDurationInSec
                videoUrl
                mediaUrl
                videoPlayerHead
                completed
                optional
                quizInProgress
              }
              modules {
                id
                title
                description
                currentModule
                image
              }
            }
          }
        }
      }
      favoriteLessons {
        id
        classId
        moduleId
        termId
        title
        currentLesson
        image
        description
        type
        instructorIds
        resources {
          id
          type
          title
          url
          termId
          featureOnDashboard
        }
        videoId
        videoDurationInSec
        videoUrl
        mediaUrl
        videoPlayerHead
        completed
        optional
        assignmentQuestions {
          id
          questionText
          questionChoices {
            id
            text
          }
          answerId
        }
        assessmentQuestions {
          id
          questionText
          questionChoices {
            id
            text
          }
          answerId
          randomize
        }
        instances {
          id
          link
          host
          startDate
          videoId
          mediaUrl
        }
        quizInProgress
      }
      officeHours {
        id
        startDateTime
        endDateTime
        title
        registrationLink
        recordedVideoLink
        embedCode
        transcript {
          title
          description
          file {
            url
            fileName
            contentType
          }
        }
        instructorNames
        instructorImages
      }
      term {
        id
        title
        startDate
        signupCutOffDate
      }
      terms {
        id
        title
        startDate
        signupCutOffDate
      }
      price
      totalProgress
      demoCloudinaryImage {
        path
        crops {
          path
          dimensions
          width
          height
        }
      }
      curriculum
      categories
      userType
      freeTrialInfo {
        token
        activated
        totalSecondsConsumed
        freeTrialLimit
      }
      notifications {
        unread {
          id
          type
          url
          qnaTitle
          classTitle
          classId
          text
          subText
          createDate
          qnaStartDate
          classCategory
          classUrlKey
        }
        read {
          id
          type
          url
          qnaTitle
          classTitle
          classId
          text
          subText
          createDate
          qnaStartDate
          classCategory
          classUrlKey
        }
      }
      description
      classMaterials
      classStarted
      videoAssignments {
        id
        maxLengthInSeconds
        objectType
        minLengthInSeconds
        gsi_pk
        active
        termId
        sk
        pk
        gradingNeeded
        submissionDueDate
        title
      }
    }
  }
`;
export const getClassSubscriptions = /* GraphQL */ `
  query GetClassSubscriptions(
    $classId: Int
    $libraryId: String
    $type: String
    $status: ClassSubscriptionStatus
    $previewToken: String
  ) {
    getClassSubscriptions(
      classId: $classId
      libraryId: $libraryId
      type: $type
      status: $status
      previewToken: $previewToken
    ) {
      id
      createDate
      type
      status
      orderId
      classId
      libraryId
      termId
      previewToken
      previewActivationDate
      subscriptionStartDate
      subscriptionEndDate
    }
  }
`;
export const getClass = /* GraphQL */ `
  query GetClass($id: Int, $slug: String, $includeDetails: Boolean) {
    getClass(id: $id, slug: $slug, includeDetails: $includeDetails) {
      id
      urlKey
      active
      training
      title
      totalDuration
      shortTitle
      instructorDisplay
      modules {
        id
        title
        sessions {
          id
          title
          moduleId
          video {
            width
            height
            duration
            thumbnail
            mediaId
            title
          }
          videoAssignmentUpload
          active
          image
          availableBeforeTerm
          description
          gradingNeeded
          type
          questions {
            id
            question
            choices {
              id
              choice
              answer
            }
          }
        }
        modules {
          id
          title
          sessions {
            id
            title
            moduleId
            video {
              width
              height
              duration
              thumbnail
              mediaId
              title
            }
            videoAssignmentUpload
            active
            image
            availableBeforeTerm
            description
            gradingNeeded
            type
            questions {
              id
              question
              choices {
                id
                choice
                answer
              }
            }
          }
          modules {
            id
            title
            sessions {
              id
              title
              moduleId
              video {
                width
                height
                duration
                thumbnail
                mediaId
                title
              }
              videoAssignmentUpload
              active
              image
              availableBeforeTerm
              description
              gradingNeeded
              type
              questions {
                id
                question
              }
            }
            modules {
              id
              title
              sessions {
                id
                title
                moduleId
                videoAssignmentUpload
                active
                image
                availableBeforeTerm
                description
                gradingNeeded
                type
              }
              modules {
                id
                title
              }
            }
          }
        }
      }
      bundleIds
      assistantUserIds
      image {
        path
        crops {
          path
          dimensions
          width
          height
        }
      }
      categories
      availableForPurchase
      previewVideos {
        width
        height
        duration
        thumbnail
        mediaId
        title
      }
      description
      relatedClasses
      tags
      instructors
    }
  }
`;
export const getModules = /* GraphQL */ `
  query GetModules($moduleIds: [String]!) {
    getModules(moduleIds: $moduleIds) {
      id
      title
      sessions {
        id
        title
        moduleId
        video {
          width
          height
          duration
          thumbnail
          mediaId
          title
        }
        videoAssignmentUpload
        active
        image
        availableBeforeTerm
        description
        gradingNeeded
        type
        questions {
          id
          question
          choices {
            id
            choice
            answer
          }
        }
      }
      modules {
        id
        title
        sessions {
          id
          title
          moduleId
          video {
            width
            height
            duration
            thumbnail
            mediaId
            title
          }
          videoAssignmentUpload
          active
          image
          availableBeforeTerm
          description
          gradingNeeded
          type
          questions {
            id
            question
            choices {
              id
              choice
              answer
            }
          }
        }
        modules {
          id
          title
          sessions {
            id
            title
            moduleId
            video {
              width
              height
              duration
              thumbnail
              mediaId
              title
            }
            videoAssignmentUpload
            active
            image
            availableBeforeTerm
            description
            gradingNeeded
            type
            questions {
              id
              question
              choices {
                id
                choice
                answer
              }
            }
          }
          modules {
            id
            title
            sessions {
              id
              title
              moduleId
              video {
                width
                height
                duration
                thumbnail
                mediaId
                title
              }
              videoAssignmentUpload
              active
              image
              availableBeforeTerm
              description
              gradingNeeded
              type
              questions {
                id
                question
              }
            }
            modules {
              id
              title
              sessions {
                id
                title
                moduleId
                videoAssignmentUpload
                active
                image
                availableBeforeTerm
                description
                gradingNeeded
                type
              }
              modules {
                id
                title
              }
            }
          }
        }
      }
    }
  }
`;
export const getSessions = /* GraphQL */ `
  query GetSessions($sessionIds: [String]!) {
    getSessions(sessionIds: $sessionIds) {
      id
      title
      moduleId
      video {
        width
        height
        duration
        thumbnail
        mediaId
        title
      }
      videoAssignmentUpload
      active
      image
      availableBeforeTerm
      description
      gradingNeeded
      type
      questions {
        id
        question
        choices {
          id
          choice
          answer
        }
      }
    }
  }
`;
export const getQuestions = /* GraphQL */ `
  query GetQuestions(
    $classId: Int!
    $moduleId: String
    $size: Int
    $cursor: String
    $sortBy: String
  ) {
    getQuestions(
      classId: $classId
      moduleId: $moduleId
      size: $size
      cursor: $cursor
      sortBy: $sortBy
    ) {
      questions {
        id
        title
        body
        username
        userimage
        createDate
        lastActivityDate
        type
        classId
        moduleId
        termId
        answeredByInstructor
        answeredByTA
        responses {
          id
          user {
            id
            emailAddress
            username
            userimage
            type
          }
          answerer {
            id
            emailAddress
            username
            userimage
            type
          }
          answer
          createDate
          classId
          likes
        }
      }
      paginationCursor {
        questionId
        hasAfter
      }
    }
  }
`;
export const getAnswers = /* GraphQL */ `
  query GetAnswers($classId: Int!, $questionId: String!) {
    getAnswers(classId: $classId, questionId: $questionId) {
      id
      user {
        id
        emailAddress
        username
        userimage
        type
      }
      answerer {
        id
        emailAddress
        username
        userimage
        type
      }
      answer
      createDate
      classId
      likes
    }
  }
`;
export const getRecommendedClasses = /* GraphQL */ `
  query GetRecommendedClasses {
    getRecommendedClasses {
      id
      urlKey
      active
      training
      title
      totalDuration
      shortTitle
      instructorDisplay
      modules {
        id
        title
        sessions {
          id
          title
          moduleId
          video {
            width
            height
            duration
            thumbnail
            mediaId
            title
          }
          videoAssignmentUpload
          active
          image
          availableBeforeTerm
          description
          gradingNeeded
          type
          questions {
            id
            question
            choices {
              id
              choice
              answer
            }
          }
        }
        modules {
          id
          title
          sessions {
            id
            title
            moduleId
            video {
              width
              height
              duration
              thumbnail
              mediaId
              title
            }
            videoAssignmentUpload
            active
            image
            availableBeforeTerm
            description
            gradingNeeded
            type
            questions {
              id
              question
              choices {
                id
                choice
                answer
              }
            }
          }
          modules {
            id
            title
            sessions {
              id
              title
              moduleId
              video {
                width
                height
                duration
                thumbnail
                mediaId
                title
              }
              videoAssignmentUpload
              active
              image
              availableBeforeTerm
              description
              gradingNeeded
              type
              questions {
                id
                question
              }
            }
            modules {
              id
              title
              sessions {
                id
                title
                moduleId
                videoAssignmentUpload
                active
                image
                availableBeforeTerm
                description
                gradingNeeded
                type
              }
              modules {
                id
                title
              }
            }
          }
        }
      }
      bundleIds
      assistantUserIds
      image {
        path
        crops {
          path
          dimensions
          width
          height
        }
      }
      categories
      availableForPurchase
      previewVideos {
        width
        height
        duration
        thumbnail
        mediaId
        title
      }
      description
      relatedClasses
      tags
      instructors
    }
  }
`;
export const getAssignments = /* GraphQL */ `
  query GetAssignments($classId: Int!, $termId: Int, $moduleId: String) {
    getAssignments(classId: $classId, termId: $termId, moduleId: $moduleId) {
      id
      createDate
      lastActivityDate
      questionId
      questionTitle
      response
      classId
      moduleId
      lessonId
      termId
      termTitle
      user {
        id
        emailAddress
        username
        userimage
        type
      }
      feedback {
        assignmentId
        user {
          id
          emailAddress
          username
          userimage
          type
        }
        createDate
        feedback
      }
      status
      readByFaculty
    }
  }
`;
export const getAssessment = /* GraphQL */ `
  query GetAssessment($classId: Int!, $lessonId: String!, $termId: Int) {
    getAssessment(classId: $classId, lessonId: $lessonId, termId: $termId) {
      id
      classId
      lessonId
      termId
      finished
      passed
      createDate
      lastUpdate
      progressMap {
        questionId
        userAnswerId
        correctAnswerId
      }
    }
  }
`;
export const getUserGiftSubscriptions = /* GraphQL */ `
  query GetUserGiftSubscriptions {
    getUserGiftSubscriptions {
      id
      classId
      libraryId
      createDate
      recipientName
      senderName
      claimed
      claimDate
      notificationTime
      emailAddress
      senderEmail
      classTitle
      classImage
      subscriberId
      classSubscriptionId
      invitationKey
      message
    }
  }
`;
export const getAllVideoAssignmentSubmissions = /* GraphQL */ `
  query GetAllVideoAssignmentSubmissions($classId: Int!) {
    getAllVideoAssignmentSubmissions(classId: $classId) {
      id
      objectType
      createDate
      assignmentId
      status
      submittedVideoId
      statusHistory {
        event
        videoId
        createDate
      }
      videos {
        id
        createDate
        clips {
          path
          thumbnail
          ordinal
        }
        recordingRoomId
        failed
      }
      roomId
      recordingRoomId
      submittedDate
    }
  }
`;
export const getByUserIdAndVideoAssignmentId = /* GraphQL */ `
  query GetByUserIdAndVideoAssignmentId(
    $input: GetVideoSubmissionByUserAndAssignmentId!
  ) {
    getByUserIdAndVideoAssignmentId(input: $input) {
      id
      objectType
      createDate
      assignmentId
      status
      submittedVideoId
      statusHistory {
        event
        videoId
        createDate
      }
      videos {
        id
        createDate
        clips {
          path
          thumbnail
          ordinal
        }
        recordingRoomId
        failed
      }
      roomId
      recordingRoomId
      submittedDate
    }
  }
`;
export const getByClassIdAndVideoAssignmentId = /* GraphQL */ `
  query GetByClassIdAndVideoAssignmentId(
    $input: GetVideoSubmissionByClassAndAssignmentId!
  ) {
    getByClassIdAndVideoAssignmentId(input: $input) {
      videos {
        id
        createDate
        clips {
          path
          thumbnail
          ordinal
        }
        recordingRoomId
        failed
      }
      assignmentId
      objectType
      submittedVideoId
      status
      statusHistory {
        event
        videoId
        createDate
      }
      classId
      gsi_pk
      createDate
      userId
      sk
      id
      pk
      user {
        email
        name
      }
      instructor {
        email
        name
      }
      submittedDate
    }
  }
`;
export const getSendbirdUser = /* GraphQL */ `
  query GetSendbirdUser($input: GetSendbirdUser!) {
    getSendbirdUser(input: $input) {
      user_id
      nickname
      profile_url
      is_active
    }
  }
`;
export const getSendbirdRoom = /* GraphQL */ `
  query GetSendbirdRoom($input: GetSendbirdRoom!) {
    getSendbirdRoom(input: $input) {
      created_by
      deleted_by
      state
      room_id
      room_type
      custom_items {
        recordingRoomId
      }
      cloud_recordings {
        recording_id
        target_event_type
        room_id
        media_type
        status
      }
    }
  }
`;
export const getVideoAssignmentByTermId = /* GraphQL */ `
  query GetVideoAssignmentByTermId($termId: Int!) {
    getVideoAssignmentByTermId(termId: $termId) {
      id
      title
    }
  }
`;
export const getTermsByClassId = /* GraphQL */ `
  query GetTermsByClassId($classId: Int!) {
    getTermsByClassId(classId: $classId) {
      id
      title
      startDate
      signupCutOffDate
    }
  }
`;
