import { types, getRoot } from 'mobx-state-tree';
import format from 'date-fns/format';
import formatDistance from 'date-fns/formatDistance';
import compareAsc from 'date-fns/compareAsc';
import addHours from 'date-fns/addHours';

const Notification = types
  .model({
    id: types.maybeNull(types.string),
    text: types.maybeNull(types.string),
    subText: types.maybeNull(types.string),
    createDate: types.maybeNull(types.string),
    type: types.string,
    classTitle: types.maybeNull(types.string),
    classId: types.maybeNull(types.number),
    classUrlKey: types.maybeNull(types.string),
    qnaTitle: types.maybeNull(types.string),
    qnaStartDate: types.maybeNull(types.string),
    url: types.maybeNull(types.string),
  })
  .views(self => ({
    get topTitle() {
      if (self.type === 'user') return self.classTitle;
      if (self.type === 'qna') {
        return format(new Date(self.qnaStartDate), 'MMMM d, ha');
      }
      return '';
    },

    get mainTitle() {
      if (self.type === 'user') return self.text;
      if (self.type === 'qna') return self.qnaTitle;
      return '';
    },

    // upcoming QNAs we keep the register now link for up to 6 hours after they started
    get isUpcomingQNA() {
      if (self.type !== 'qna') return false;
      return compareAsc(addHours(new Date(self.qnaStartDate), 6), new Date()) > 0;
    },

    get subTitle() {
      if (self.type === 'user') return self.subText;
      if (self.isUpcomingQNA) return 'Register now';

      return '';
    },

    get timeAgo() {
      if (self.createDate) return formatDistance(new Date(), new Date(self.createDate));
      if (self.qnaStartDate && !self.isUpcomingQNA)
        return formatDistance(new Date(), new Date(self.qnaStartDate));
      return '';
    },

    get officeHour() {
      const {
        classDetailsStore: { activeClass },
        classStore,
      } = getRoot(self);
      const findClass = activeClass || classStore.classesById.get(self.classId);

      if (findClass?.officeHours) {
        const officeHour = findClass.officeHours.find(oh => oh.id === self.id);
        return officeHour || null;
      }

      return null;
    },
  }));

export default Notification;
