import React from 'react';

export const CheckmarkCircle = ({
  width,
  height,
  color,
}: {
  width?: number;
  height?: number;
  color?: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 8.5C17 13.1944 13.1944 17 8.5 17C3.80558 17 0 13.1944 0 8.5C0 3.80558 3.80558 0 8.5 0C13.1944 0 17 3.80558 17 8.5ZM4.66436 8.14149C5.03128 7.72871 5.66335 7.69153 6.07614 8.05845L8.16977 9.91945L11.4567 4.83957C11.7568 4.37589 12.3759 4.24322 12.8396 4.54325C13.3033 4.84328 13.4359 5.46239 13.1359 5.92607L9.3335 11.8025C9.03347 12.2662 8.41436 12.3988 7.95068 12.0988C7.91809 12.0777 7.88713 12.0551 7.85785 12.031C7.6413 12.0183 7.4273 11.9354 7.25259 11.7801L4.74741 9.55327C4.33463 9.18635 4.29745 8.55428 4.66436 8.14149Z"
      fill={`${color || '#fff'}`}
    />
  </svg>
);
