import { types } from 'mobx-state-tree';
import { parseISO, format, compareAsc, addHours } from 'date-fns';
import QASessionTranscript from './QASessionTranscript';

const QASession = types
  .model({
    id: types.string,
    embedCode: types.maybeNull(types.string),
    instructorNames: types.maybeNull(types.array(types.string)),
    instructorImages: types.maybeNull(types.array(types.optional(types.string, ''))),
    title: types.maybeNull(types.string),
    recordedVideoLink: types.maybeNull(types.string),
    registrationLink: types.maybeNull(types.string),
    startDateTime: types.maybeNull(types.string),
    transcript: types.maybeNull(QASessionTranscript),
  })
  .views(self => ({
    get startTime() {
      return format(parseISO(self.startDateTime), 'MMMM d, ha');
    },

    // adds 6 hours to start date time
    get isUpcoming() {
      return compareAsc(addHours(parseISO(self.startDateTime), 6), new Date()) > 0;
    },
  }));

export default QASession;
