export const getUserClasses = /* GraphQL */ `
  query GetUserClasses {
    getUserClasses {
      libraryAccess
      classesTaking {
        id
        title
        terms {
          id
          title
          startDate
          signupCutOffDate
        }
        urlKey
        type
        curriculumType
        image {
          path
          crops {
            path
            dimensions
            width
            height
          }
        }
        instructors {
          id
          image
          biography
          name {
            honorific
            first
            last
            suffix
            nickname
            credentials
            fullName
          }
        }
        instructorDisplay
        teachingAssistants {
          id
          image
          biography
          name {
            honorific
            first
            last
            suffix
            nickname
            credentials
            fullName
          }
        }
        resources {
          id
          type
          title
          url
          termId
          featureOnDashboard
        }
        modules {
          id
          title
          description
          currentModule
          image
          lessons {
            id
            classId
            moduleId
            termId
            title
            currentLesson
            image
            description
            type
            instructorIds
            resources {
              id
              type
              title
              url
            }
            videoId
            videoDurationInSec
            videoUrl
            mediaUrl
            videoPlayerHead
            completed
            optional
            assignmentQuestions {
              id
              questionText
              questionChoices {
                id
                text
              }
              answerId
            }
            assessmentQuestions {
              id
              questionText
              questionChoices {
                id
                text
              }
              answerId
            }
            instances {
              id
              link
              host
              startDate
              videoId
              mediaUrl
            }
            quizInProgress
          }
          modules {
            id
            title
            description
            currentModule
            image
            lessons {
              id
              classId
              moduleId
              termId
              title
              currentLesson
              image
              description
              type
              instructorIds
              resources {
                id
                type
                title
                url
              }
              videoId
              videoDurationInSec
              videoUrl
              mediaUrl
              videoPlayerHead
              completed
              optional
              assignmentQuestions {
                id
                questionText
                questionChoices {
                  id
                  text
                }
                answerId
              }
              assessmentQuestions {
                id
                questionText
                questionChoices {
                  id
                  text
                }
                answerId
              }
              instances {
                id
                link
                host
                startDate
                videoId
                mediaUrl
              }
              quizInProgress
            }
            modules {
              id
              title
              description
              currentModule
              image
              lessons {
                id
                classId
                moduleId
                termId
                title
                currentLesson
                image
                description
                type
                instructorIds
                videoId
                videoDurationInSec
                videoUrl
                mediaUrl
                videoPlayerHead
                completed
                optional
              }
              modules {
                id
                title
                description
                currentModule
                image
              }
            }
          }
        }
        favoriteLessons {
          id
          classId
          moduleId
          title
          currentLesson
          image
          description
          type
          instructorIds
          resources {
            id
            type
            title
            url
          }
          videoId
          videoDurationInSec
          videoUrl
          mediaUrl
          videoPlayerHead
          completed
          optional
          assignmentQuestions {
            id
            questionText
            questionChoices {
              id
              text
            }
            answerId
          }
          assessmentQuestions {
            id
            questionText
            questionChoices {
              id
              text
            }
            answerId
          }
          instances {
            id
            link
            host
            startDate
            videoId
            mediaUrl
          }
          quizInProgress
        }
        officeHours {
          id
          startDateTime
          endDateTime
          title
          registrationLink
          recordedVideoLink
          embedCode
          transcript {
            title
            description
            file {
              url
              fileName
              contentType
            }
          }
          instructorNames
          instructorImages
        }
        term {
          id
          title
          startDate
          signupCutOffDate
        }
        price
        totalProgress
        demoCloudinaryImage {
          path
          crops {
            path
            dimensions
            width
            height
          }
        }
        curriculum
        categories
        userType
        freeTrialInfo {
          token
          activated
          totalSecondsConsumed
          freeTrialLimit
        }
        notifications {
          unread {
            id
            type
            url
            qnaTitle
            classTitle
            classId
            text
            subText
            createDate
            qnaStartDate
            classCategory
            classUrlKey
          }
          read {
            id
            type
            url
            qnaTitle
            classTitle
            classId
            text
            subText
            createDate
            qnaStartDate
            classCategory
            classUrlKey
          }
        }
        description
        classMaterials
        classStarted
        videoAssignments {
          id
          maxLengthInSeconds
          objectType
          minLengthInSeconds
          gsi_pk
          active
          termId
          sk
          pk
          gradingNeeded
          submissionDueDate
          title
        }
      }
      favoriteLessons {
        id
        classId
        moduleId
        title
        currentLesson
        image
        description
        type
        instructorIds
        resources {
          id
          type
          title
          url
        }
        videoId
        videoDurationInSec
        videoUrl
        mediaUrl
        videoPlayerHead
        completed
        optional
        assignmentQuestions {
          id
          questionText
          questionChoices {
            id
            text
          }
          answerId
        }
        assessmentQuestions {
          id
          questionText
          questionChoices {
            id
            text
          }
          answerId
        }
        instances {
          id
          link
          host
          startDate
          videoId
          mediaUrl
        }
        quizInProgress
      }
      notifications {
        unread {
          id
          type
          url
          qnaTitle
          classTitle
          classId
          text
          subText
          createDate
          qnaStartDate
          classCategory
          classUrlKey
        }
        read {
          id
          type
          url
          qnaTitle
          classTitle
          classId
          text
          subText
          createDate
          qnaStartDate
          classCategory
          classUrlKey
        }
      }
    }
  }
`;
