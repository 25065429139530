import { types } from 'mobx-state-tree';

const ApiError = types
  .model('ApiError', {
    type: types.string,
    message: types.maybeNull(types.string),
  })
  .preProcessSnapshot(snapshot => {
    if (!snapshot) return snapshot;
    const { message, type } = snapshot;
    return { message, type };
  });

export default ApiError;
