import { types } from 'mobx-state-tree';

// https://docs.embed.ly/v1.0/docs/oembed#response-attributes
const LinkPreview = types.model({
  type: types.string,
  version: types.string,
  title: types.maybeNull(types.string),
  author_name: types.maybeNull(types.string),
  author_url: types.maybeNull(types.string),
  provider_name: types.maybeNull(types.string),
  provider_url: types.maybeNull(types.string),
  cache_age: types.maybeNull(types.number),
  thumbnail_url: types.maybeNull(types.string),
  thumbnail_width: types.maybeNull(types.number),
  thumbnail_height: types.maybeNull(types.number),
  description: types.string,
});

export default LinkPreview;
