export default function handleApiError({ errors }) {
  if (!errors?.length) {
    return { type: 'API_ERROR' };
  }

  const { errorType, message } = errors[0];

  if (errorType === 'UNAUTHORIZED') {
    //we handle redirection on _app.js file line 52.
    return { type: 'UNAUTHORIZED', message };
  }

  return { type: errorType || 'API_ERROR', message };
}
