import { types, getRoot } from 'mobx-state-tree';
import { compareDesc } from 'date-fns';
import { submitAssignment, fetchSubmittedAssignments, addAssignmentFeedback } from 'api';
import { segmentEvent } from 'utils/segmentTrackers';
import Assignment from './models/Assignment';

export const AssignmentsStore = types
  .model('AssignmentsStore', {
    loading: false,
    submitting: false,
    submitSuccess: false,
    submitFailure: false,
    submittedAssignments: types.maybeNull(types.array(Assignment)),
    savingAssignmentId: types.maybeNull(types.string),
    savedAssignmentId: types.maybeNull(types.string),
  })
  .actions(self => ({
    async submit(moduleId, lessonId, questionId, response) {
      const {
        classDetailsStore: { activeClass },
      } = getRoot(self);
      self.submitting = true;
      self.submitSuccess = false;
      self.submitFailure = false;

      try {
        const {
          id,
          currentSessionId,
          newUnreadNotification,
          totalProgress,
        } = await submitAssignment(
          activeClass.id,
          moduleId,
          lessonId,
          questionId,
          activeClass.term ? activeClass.term.id : undefined,
          response
        );
        activeClass.changeCurrentLesson(currentSessionId);
        activeClass.updateProgress(totalProgress);
        if (newUnreadNotification) activeClass.addUnreadNotification(newUnreadNotification);

        self.onSubmitSuccess(activeClass.id, moduleId, lessonId, questionId, id, response);
      } catch (error) {
        self.onSubmitFailure();
        console.error(error);
      }
    },

    /* eslint-disable no-shadow */
    onSubmitSuccess(classId, moduleId, lessonId, questionId, assignmentId, response) {
      self.submitting = false;
      self.submitSuccess = true;

      // Segment Analytics
      segmentEvent('Class Assignment Submitted', {
        classId,
        sessionId: lessonId,
      });

      // mark lesson as completed
      const {
        classDetailsStore: { activeClass },
      } = getRoot(self);
      const idx = activeClass.allLessons.findIndex(l => l.id === lessonId);
      activeClass.markLessonCompleted(idx);

      // add to submitted assignments
      if (!self.submittedAssignments) {
        self.submittedAssignments = [];
      }

      self.submittedAssignments.push({
        id: assignmentId,
        status: 'submitted',
        response,
        moduleId,
        createDate: new Date().toISOString(),
        questionTitle: activeClass.allLessons[idx].assignmentQuestions.find(
          aq => aq.id === questionId
        ).questionText,
        user: { username: 'me' },
        termId: activeClass.term ? activeClass.term.id : undefined,
        feedback: [],
        readByFaculty: false,
      });
    },

    onSubmitFailure() {
      self.submitting = false;
      self.submitFailure = true;
    },

    async loadSubmittedAssignments() {
      const {
        classDetailsStore: { activeClass },
      } = getRoot(self);

      self.loading = true;

      try {
        const data = await fetchSubmittedAssignments(
          activeClass.id,
          activeClass.term ? activeClass.term.id : undefined
        );
        self.submittedAssignmentsSuccess(data);
      } catch (error) {
        console.error(error);
      }
    },

    submittedAssignmentsSuccess(data) {
      self.loading = false;
      self.submittedAssignments = data;
    },

    submittedAssignmentsByModule(moduleId) {
      if (!self.submittedAssignments) return [];

      return self.submittedAssignments
        .filter(a => a.moduleId === moduleId)
        .sort((a, b) => compareDesc(new Date(a.createDate), new Date(b.createDate)));
    },

    // userId is not sent, so that the current user is used
    async addFeedback(assignmentId, feedback) {
      self.savedAssignmentId = null;
      self.savingAssignmentId = assignmentId;

      const {
        classDetailsStore: { activeClass },
      } = getRoot(self);

      try {
        await addAssignmentFeedback(activeClass.id, assignmentId, feedback, undefined);
        self.addFeedbackSuccess(assignmentId, feedback);
      } catch (error) {
        console.error(error);
        self.addFeedbackFailure();
      }
    },

    addFeedbackSuccess(assignmentId, feedback) {
      self.submittedAssignments
        .find(a => a.id === assignmentId)
        .addFeedbackItem({
          feedback,
          assignmentId,
          createDate: new Date().toISOString(),
          user: { username: 'me' },
        });
      self.savedAssignmentId = assignmentId;
      self.savingAssignmentId = null;
    },

    addFeedbackFailure() {
      self.savedAssignmentId = null;
      self.savingAssignmentId = null;
    },
  }));
