import Head from 'next/head';
import Segment from '../../segment';

  /* Note: The site.webmanifest file is loaded from the root site (www.mindbodygreen.com) 
   Therefore, the site.webmanifest file does not exist in this repository. */

export default function PageHead() {
  return (
    <Head>
      <title>mindbodygreen</title>
      <meta name="description" content="mindbodygreen: A 360 degree approach to wellness." />
      <meta name="viewport" content="initial-scale=1, viewport-fit=cover, user-scalable=no" />
      <meta name="apple-mobile-web-app-status-bar-style" content="white" />
      <meta name="theme-color" content="#fff" />
      <link rel="manifest" href="/site.webmanifest" />
      <link
        rel="stylesheet"
        href={
          process.env.NODE_ENV === 'production' ? '/_next-assets/fonts.css' : '/static/fonts.css'
        }
      />
      <Segment />
    </Head>
  );
}
