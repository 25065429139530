import { types, getRoot } from 'mobx-state-tree';

import Answer from 'store/models/Answer';

const Question = types
  .model({
    id: types.string,
    title: types.string,
    body: types.maybeNull(types.string),
    createDate: types.string,
    username: types.maybeNull(types.string),
    responses: types.maybeNull(types.array(Answer)),

    // topAnswer: types.maybeNull(Answer),
    // totalAnswers: types.number,
    linkIndex: types.maybeNull(types.number),
  })
  .views(self => ({
    get topAnswer() {
      const answer = self.responses && self.responses.length && self.responses[0];
      if (answer && answer.answer.length > 200) {
        return { ...answer, answer: `${answer.answer.slice(0, 200)}...` };
      }

      return answer;
    },
  }))
  .actions(self => ({
    afterCreate() {
      if (self.linkIndex) return;
      const { title, body } = self;
      const regex = new RegExp(/(https?|ftp):\/\/(-\.)?([^\s/?\.#-]+\.?)+(\/[^\s]*)?/gi); // eslint-disable-line
      const match = title.match(regex) || (body && body.match(regex));
      const { linkPreviewStore } = getRoot(self);
      if (match) {
        self.linkIndex = linkPreviewStore.addNewLink();
        linkPreviewStore.fetchLink(self.linkIndex, match[0]);
      }
    },
  }));

export default Question;
