import React, { useState, useCallback } from 'react';
import Link from 'next/link';
import { AccountIcon } from 'components/common/icons';
import { Menu, MenuLink, AccountText, MenuDiv } from './styles';

const AccountMenu = ({ isLoggedIn }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const focusOnClick = useCallback(
    (ev) => {
      if (!menuOpen) ev.target.focus();
    },
    [menuOpen]
  );

  if (!isLoggedIn)
    return (
      <MenuLink href="#">
        <AccountIcon height={18} title="Log in" />
        <AccountText>Log in</AccountText>
      </MenuLink>
    );

  return (
    <MenuDiv
      tabIndex={0}
      onClick={focusOnClick}
      onFocus={() => setMenuOpen(true)}
      onBlur={() => setMenuOpen(false)}
      aria-label="My account"
      role="menu"
    >
      <AccountIcon height={18} title="My account" />
      <AccountText>My account</AccountText>
      <Menu expanded={menuOpen}>
        <li>
          <a href="https://www.mindbodygreen.com/user/home" rel="nofollow" aria-label="Profile">
            Profile
          </a>
        </li>
        <li>
          <a
            href="https://www.mindbodygreen.com/user/saved-items"
            rel="nofollow"
            aria-label="Saved Articles"
          >
            Saved articles
          </a>
        </li>
        <li>
          <a
            href="https://www.mindbodygreen.com/user/orders-and-tracking"
            rel="nofollow"
            aria-label="Orders and Tracking"
          >
            Orders & Tracking
          </a>
        </li>
        <li>
          <Link href="/user/classes" passHref>
            {/* eslint-disable */}
            <a rel="nofollow" aria-label="Classes Dashboard">
              Classes
            </a>
            {/* eslint-enable */}
          </Link>
        </li>
        <li>
          <a href="/user/classes/gifts" rel="nofollow" aria-label="My Purchased Gifts">
            My Purchased Gifts
          </a>
        </li>
        <li>
          <a
            href="mailto:support@mindbodygreen.com?subject=Site+Help"
            rel="nofollow"
            aria-label="Contact support"
          >
            Contact support
          </a>
        </li>
        <li onClick={() => (window.location.href = 'https://www.mindbodygreen.com/account/logout')}>
          <a
            rel="nofollow"
            aria-label="Log out"
          >
            Log out
          </a>
        </li>
      </Menu>
    </MenuDiv>
  );
};

export default AccountMenu;
