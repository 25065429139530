import { types } from 'mobx-state-tree';

const File = types.model({
  url: types.string,
  fileName: types.maybeNull(types.string),
  contentType: types.maybeNull(types.string),
});

const QASessionTranscript = types.model({
  title: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  file: types.maybeNull(File),
});

export default QASessionTranscript;
