import { types } from 'mobx-state-tree';
import ChatSession from './models/HccAIChat';
import { processChatRequest } from 'api/hccAi';
import { addChatRecord } from 'api/uploadToZapier';
import {
  convertTranscriptToMarkdown,
  convertTranscriptToPlainText,
} from 'utils/convertTranscriptToMarkdown';

const defaultChatSession = {
  sessionId: '',
  transcript: [],
  responses: 0,
  likes: 0,
  dislikes: 0,
  botName: 'HCC Help Bot',
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
};

export const HccAiStore = types
  .model('HccAiStore', {
    chatSession: types.optional(ChatSession, defaultChatSession),
    loading: false,
    submitting: false,
    newConversationAdded: false,
    inputHasError: '',
    currentSessionId: types.maybeNull(types.string),
  })
  .views((self) => ({
    get currentTranscript() {
      return self.chatSession.transcript;
    },
    getTranscriptEntry(id) {
      return self.currentTranscript.find((entry) => entry.id === id);
    },
  }))
  .actions((self) => ({
    startConversation(conversation, currentUser) {
      self.loading = true;
      self.submitting = true;
      self.newConversationAdded = true;

      if (self.chatSession.responses === 0) {
        self.chatSession = {
          ...self.chatSession,
          userId: currentUser.userId,
          email: currentUser.emailAddress,
        };
      }

      self.chatSession.sessionId = self.currentSessionId || '';
      self.chatSession.transcript.push({
        id: conversation.id,
        question: conversation.question,
      });

      self.submitting = true;
      self.getResponse(conversation);
    },

    async getResponse(conversation) {
      let response;
      try {
        response = await processChatRequest({
          input: conversation.question,
          sessionId: self.currentSessionId || '',
        });
      } catch (error) {
        console.error('Error processing chat request:', error.message);
        self.responseHasError();
        return;
      } finally {
        self.applyChatResponse(conversation.id, response);
      }
    },

    applyChatResponse(id, response) {
      const responseContent = response.processChatRequest.messages[0].content;
      self.currentSessionId = response.processChatRequest.sessionState.sessionId;

      const transcriptEntry = self.getTranscriptEntry(id);
      if (transcriptEntry) {
        transcriptEntry.response = responseContent;

        self.chatSession.responses += 1;

        if (self.chatSession.sessionId === '') {
          self.chatSession.sessionId = self.currentSessionId;
        }
      }
      self.stopLoading();
      self.addChatRecordToZapier();
    },

    async addChatRecordToZapier() {
      try {
        const transcriptAsMarkdown = convertTranscriptToMarkdown(self.chatSession.transcript);
        const transcriptAsPlainText = convertTranscriptToPlainText(self.chatSession.transcript);

        await addChatRecord({
          input: {
            ...self.chatSession,
            transcript: transcriptAsMarkdown,
            plainTextTranscript: transcriptAsPlainText,
          },
        });
      } catch (error) {
        console.error('Error adding chat record to Zapier:', error);
      }
    },

    resetNewConversationAdded() {
      self.newConversationAdded = false;
    },

    stopLoading() {
      self.loading = false;
      self.submitting = false;
    },

    setInputError(value) {
      self.inputHasError = value;
    },

    responseHasError() {
      self.stopLoading();
    },

    setResponseFeedback(transcriptId, feedback) {
      const transcriptEntry = self.getTranscriptEntry(transcriptId);
      if (transcriptEntry) {
        const currentFeedback = transcriptEntry.userFeedback;
        transcriptEntry.userFeedback = feedback;

        if (currentFeedback !== feedback) {
          self.updateFeedbackCounts(currentFeedback, feedback);
        }

        self.addChatRecordToZapier();
      }
    },

    updateFeedbackCounts(oldFeedback, newFeedback) {
      if (newFeedback === 'positive') {
        self.chatSession.likes += 1;
        if (oldFeedback === 'negative') {
          self.chatSession.dislikes -= 1;
        }
      } else {
        self.chatSession.dislikes += 1;
        if (oldFeedback === 'positive') {
          self.chatSession.likes -= 1;
        }
      }
    },

    setLoading(loading) {
      self.loading = loading;
    },
  }));
