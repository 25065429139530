export const QuizTrueIcon = ({ className, width, height }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 16.5 13.407"
    xmlns="http://www.w3.org/2000/svg"
  >
    <polyline
      points="0.75 7.665 6.496 12.657 15.75 0.75"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);
