import { types } from 'mobx-state-tree';
import shuffle from 'lodash.shuffle';

const QuestionChoice = types.model({
  id: types.string,
  text: types.maybeNull(types.string),
});

const AssessmentQuestion = types
  .model({
    questionText: types.string,
    id: types.string,
    questionChoices: types.array(QuestionChoice),
    randomize: types.maybeNull(types.boolean),
  })
  .actions(self => ({
    randomizeChoices() {
      if (self.randomize) {
        const choices = self.questionChoices.toJSON();
        self.questionChoices = shuffle(choices);
        // move "All of the above" choice to the last position.
        const allOfTheAboveChoice = choices.findIndex(c => !!c.text.match(/^all of the/i));
        if (allOfTheAboveChoice !== -1) {
          choices.push(choices.splice(allOfTheAboveChoice, 1)[0]);
          self.questionChoices = choices;
        }
      }
    },
  }));

export default AssessmentQuestion;
