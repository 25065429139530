import styled, { createGlobalStyle } from 'styled-components';
import { when } from 'utils';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;

    /* font smoothing */
    /* https://www.webfwd.co.uk/blog/how-to-smooth-website-fonts-using-css3/ */
    -webkit-font-smoothing: antialiased;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
    -moz-osx-font-smoothing: grayscale;
  }

  html,
  body,
  body > div {
    margin: 0;
    padding: 0;
  }

  body {
    font-family: Maax, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 1.6rem;
    font-weight: normal;
  }

  a {
    text-decoration: none;
    color: inherit;
    font-family: inherit;
  }

  button {
    border: 0;
    color: inherit;
    font-family: inherit;
    background: none;
    font-weight: inherit;
    cursor: pointer;
  }
`;

export const Content = styled.div`
  padding: 0;
  padding-top: 6.8rem;
  padding-bottom: 5rem;
  margin: 0 auto;
  max-width: 144rem;
  min-height: 90vh;
  height: auto;

  ${when('noNavigation')`
    padding-top: 0;
  `}
`;

export const FullScreenContent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow-y: auto;
`;
