import React from 'react';

export const IconFilter = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-165.000000, -716.000000)" fill="currentColor" fillRule="nonzero">
        <g transform="translate(48.000000, 703.000000)">
          <g transform="translate(117.000000, 13.000000)">
            <path d="M5.4,1.22479804e-13 C6.74200671,1.22479804e-13 7.86962773,0.917894303 8.18929766,2.16011753 L20.16,2.16 L20.16,3.6 L8.18903892,3.60088716 C7.86901966,4.84260058 6.74164489,5.76 5.4,5.76 C4.05835511,5.76 2.93098034,4.84260058 2.61096108,3.60088716 L8.83915163e-12,3.6 L8.83915163e-12,2.16 L2.61070234,2.16011753 C2.93037227,0.917894303 4.05799329,1.22479804e-13 5.4,1.22479804e-13 Z M5.4,1.44 C4.60470996,1.44 3.96,2.08470996 3.96,2.88 L3.96,2.86 L3.96014026,2.90030676 C3.97035894,3.63960705 4.53770692,4.24426277 5.26131839,4.31340809 L5.4,4.32 C6.19529004,4.32 6.84,3.67529004 6.84,2.88 C6.84,2.08470996 6.19529004,1.44 5.4,1.44 Z" />
            <path d="M5.04,12.96 C6.38200671,12.96 7.50962773,13.8778943 7.82929766,15.1201175 L20.16,15.12 L20.16,16.56 L7.82903892,16.5608872 C7.50901967,17.8026006 6.38164489,18.72 5.04,18.72 C3.69835511,18.72 2.57098034,17.8026006 2.25096108,16.5608872 L3.33955974e-11,16.56 L3.33955974e-11,15.12 L2.25070234,15.1201175 C2.57037227,13.8778943 3.69799329,12.96 5.04,12.96 Z M5.04,14.4 C4.24470996,14.4 3.6,15.04471 3.6,15.84 C3.6,16.63529 4.24470996,17.28 5.04,17.28 C5.83529004,17.28 6.48,16.63529 6.48,15.84 C6.48,15.04471 5.83529004,14.4 5.04,14.4 Z" />
            <path d="M14.76,6.48 C16.1020067,6.48 17.2296277,7.3978943 17.5492977,8.64011753 L20.16,8.64 L20.16,10.08 L17.5490389,10.0808872 C17.2290197,11.3226006 16.1016449,12.24 14.76,12.24 C13.4183551,12.24 12.2909803,11.3226006 11.9709611,10.0808872 L-6.22364382e-12,10.08 L-6.22364382e-12,8.64 L11.9707023,8.64011753 C12.2903723,7.3978943 13.4179933,6.48 14.76,6.48 Z M14.76,7.92 C13.96471,7.92 13.32,8.56470996 13.32,9.36 C13.32,10.15529 13.96471,10.8 14.76,10.8 C15.55529,10.8 16.2,10.15529 16.2,9.36 C16.2,8.56470996 15.55529,7.92 14.76,7.92 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
