import { types } from 'mobx-state-tree';

const ProgressMap = types.model({
  userAnswerId: types.string,
  questionId: types.string,
  correctAnswerId: types.string,
});

const AssessmentProgress = types
  .model({
    id: types.string,
    finished: false,
    passed: false,
    progressMap: types.array(ProgressMap),
  })
  .actions(self => ({
    addProgressEntry({ questionId, userAnswerId, correctAnswerId }) {
      self.progressMap.push({ questionId, userAnswerId, correctAnswerId });
    },
  }));

export default AssessmentProgress;
