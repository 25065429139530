import { types, getRoot } from 'mobx-state-tree';
import { trackVideoProgress } from 'api';
import Router from 'next/router';

export const VideoStore = types
  .model('VideoStore', {
    videoLink: types.maybeNull(types.string),
    mediaUrl: types.maybeNull(types.string),
    videoThumb: types.maybeNull(types.string),
    videoOffset: 0,
    autoplay: true,
    lessonIndex: -1,
    moduleIndex: -1,
    nestedModuleIndex: -1,
    currentLessonId: types.maybeNull(types.string),
    totalVideoDuration: 0,
    currentLiveSessionOption: 0,
    doNotTrack: false,
    isVideoPageActive: false,
    // firstAccess: true,
  })
  .views(self => ({
    /* eslint-disable no-plusplus */
    get prevLesson() {
      if (self.lessonIndex < 0) return null;

      const {
        classDetailsStore: {
          activeClass: { allLessons, isLessonViewableMap },
        },
      } = getRoot(self);

      let cursor = self.lessonIndex;

      // works only if the lesson has two instances
      // if first lesson is the live session instance
      if (
        cursor === 0 &&
        self.lessonPlaying?.type === 'Live Session' &&
        self.lessonPlaying?.instances?.length
      ) {
        const instance = self.lessonPlaying.instances[0];
        // if the first instance is playing or if no mediaUrl, return null
        // otherwise return the first instance
        if (!self.currentLiveSessionOption || !instance.mediaUrl) return null;
        return {
          ...instance,
          type: 'Video',
          liveSession: true,
          id: self.lessonPlaying.id,
          instanceIdx: 0,
          title: self.lessonPlaying.title,
        };
      }

      while (--cursor >= 0) {
        const { id } = allLessons[cursor];
        if (isLessonViewableMap[id]) {
          const lesson = allLessons[cursor];

          // live session instances share the same lesson ID
          if (lesson.type === 'Live Session' && lesson.instances?.length) {
            // if no valid instances, check the next lesson
            const hasInstancesWithVideo = lesson.instances.some(ins => ins.mediaUrl);
            if (!hasInstancesWithVideo) {
              // eslint-disable-next-line
              continue;
            }

            // get the last instance
            let instanceIdx = lesson.instances.length - 1;
            let instance = [...lesson.instances].pop();
            // if no mediaUrl, try with the first instance
            if (!instance?.mediaUrl) {
              instanceIdx = 0;
              // eslint-disable-next-line
              instance = lesson.instances[0];
            }
            // if no mediaUrl don't return instance
            if (instance?.mediaUrl) {
              return {
                ...instance,
                type: 'Video',
                liveSession: true,
                id: lesson.id,
                instanceIdx,
                title: lesson.title,
              };
            }
            return null;
          }

          return lesson;
        }
      }

      return null;
    },

    get nextLesson() {
      if (self.lessonIndex < 0) return null;

      const {
        classDetailsStore: {
          activeClass: { allLessons, isLessonViewableMap },
        },
      } = getRoot(self);

      let cursor = self.lessonIndex;

      while (++cursor < allLessons.length) {
        const lesson = allLessons[cursor];

        if (isLessonViewableMap[lesson.id]) {
          // works only if the lesson has two instances
          if (
            self.lessonPlaying?.type === 'Live Session' &&
            self.lessonPlaying?.instances?.length &&
            self.currentLiveSessionOption === 0
          ) {
            const instance = [...self.lessonPlaying.instances].pop();
            if (instance?.mediaUrl) {
              return {
                ...instance,
                type: 'Video',
                liveSession: true,
                id: self.lessonPlaying.id,
                instanceIdx: 1,
                title: self.lessonPlaying.title,
              };
            }
          }

          if (
            lesson.type === 'Live Session' &&
            lesson.instances?.length &&
            lesson.id !== self.lessonPlaying?.id
          ) {
            // if no valid instances, check the next lesson
            const hasInstancesWithVideo = lesson.instances.some(ins => ins.mediaUrl);
            if (!hasInstancesWithVideo) {
              // eslint-disable-next-line
              continue;
            }

            let instance = lesson.instances[0];
            // if no mediaUrl, try with the second instance
            if (!instance?.mediaUrl) {
              // eslint-disable-next-line
              instance = lesson.instances[1];
            }

            if (instance?.mediaUrl) {
              return {
                ...instance,
                type: 'Video',
                liveSession: true,
                id: lesson.id,
                instanceIdx: 0,
                title: lesson.title,
              };
            }
          }
          return lesson;
        }
      }

      return null;
    },

    get lessonPlaying() {
      const {
        classDetailsStore: { activeClass },
      } = getRoot(self);
      return self.lessonIndex > -1 ? activeClass.allLessons[self.lessonIndex] : null;
    },
  }))
  .actions(self => ({
    // resets the store to its defaults during client-side navigations
    // where the store object is kept in memory.
    reset() {
      self.autoplay = true;
      self.lessonIndex = -1;
      self.moduleIndex = -1;
      self.nestedModuleIndex = -1;
      self.currentLessonId = null;
      self.totalVideoDuration = 0;
      self.videoLink = null;
      self.videoThumb = null;
      // self.videoOffset = 0;
      self.doNotTrack = false;
      // self.firstAccess = true;
    },

    updateDetails(newIdx) {
      const { classDetailsStore } = getRoot(self);
      self.lessonIndex = newIdx;
      const lesson = classDetailsStore.activeClass.allLessons[newIdx];
      const {
        id,
        videoUrl,
        mediaUrl,
        image,
        videoDurationInSec,
        videoPlayerHead = 0,
        type,
        instances,
      } = lesson;

      self.doNotTrack = false;

      // for JWPlayer
      self.videoThumb = image;
      if (type === 'Live Session' && instances?.length) {
        self.doNotTrack = true;
        const instance = instances[self.currentLiveSessionOption];
        if (instance) {
          self.mediaUrl = instance.mediaUrl;
        }
      } else {
        self.videoLink = videoUrl;
        self.mediaUrl = mediaUrl;
      }

      // restore video playback when first entering dashboard;
      // but only if the playerHead is not at the end of video
      self.videoOffset = 0;
      if (videoPlayerHead / videoDurationInSec < 0.9) {
        self.videoOffset = videoPlayerHead || 0;
      }

      // used for displaying current module in the sidebar
      self.moduleIndex = classDetailsStore.activeClass.modules.findIndex(m =>
        m.containsLessonId(id)
      );
      const currentModule =
        self.moduleIndex !== -1 ? classDetailsStore.activeClass.modules[self.moduleIndex] : null;
      self.nestedModuleIndex = currentModule?.modules?.length
        ? currentModule.modules.findIndex(m => m.containsLessonId(id))
        : -1;

      self.currentLessonId = id;
      self.totalVideoDuration = videoDurationInSec || 0;
    },

    // initialize the store with the lesson obtained from slug, or redirect if lesson is not viewable
    init(initialLesson, isMobile = false) {
      const {
        classDetailsStore: { activeClass },
      } = getRoot(self);

      let idx = activeClass.nextVideoLessonIdx;
      if (initialLesson) {
        if (activeClass.isLessonViewableMap[initialLesson]) {
          idx = activeClass.allLessons.findIndex(l => l.id === initialLesson);
        } else {
          const as = `/user/classes/[slug]${isMobile ? '' : '/watch'}`;
          const href = `/user/classes/${activeClass.urlKey}${isMobile ? '' : '/watch'}]`;

          Router.replace(as, href, {
            shallow: true,
          });
        }
      }

      self.updateDetails(idx);
    },

    toggleAutoplay() {
      self.autoplay = !self.autoplay;
    },

    async onVideoProgress(secondsPassed) {
      if (self.doNotTrack) return;

      const {
        classDetailsStore: { activeClass },
        dashboardStore,
      } = getRoot(self);
      if (!['student', 'previewer', 'administrator'].includes(activeClass.userType)) return;
      if (activeClass.allLessons[self.lessonIndex]) {
        activeClass.allLessons[self.lessonIndex].setPlayerHead(secondsPassed);
      }

      try {
        if (secondsPassed >= self.totalVideoDuration) {
          activeClass.markLessonCompleted(self.lessonIndex);
        }

        const response = await trackVideoProgress(
          activeClass.id,
          self.currentLessonId,
          activeClass.term ? activeClass.term.id : undefined,
          secondsPassed,
          activeClass.freeTrialInfo && activeClass.freeTrialInfo.activated
            ? activeClass.freeTrialInfo.totalSecondsConsumed
            : null
        );

        activeClass.changeCurrentLesson(response.currentSessionId);
        activeClass.updateProgress(response.totalProgress);
        if (response.newUnreadNotification) {
          activeClass.addUnreadNotification(response.newUnreadNotification);
        }

        dashboardStore.addToMyClasses(activeClass);
      } catch (error) {
        console.log(error);
      }
    },

    addSecondConsumed(videoPos) {
      const {
        classDetailsStore: { activeClass },
      } = getRoot(self);

      if (!activeClass.freeTrialInfo || !activeClass.freeTrialInfo.activated) return;
      activeClass.incrementFreeTrialSeconds();

      // make sure to finalize free trial on server too
      if (activeClass.freeTrialInfo.secondsRemaining < 1) {
        self.onVideoProgress(videoPos);
      }
    },

    setCurrentLiveSessionOption(lessonId, index) {
      const {
        classDetailsStore: { activeClass },
      } = getRoot(self);

      self.currentLiveSessionOption = index;
      // live session instances share the same lessonId
      // so we need to refresh the player and load new instance.mediaUrl
      if (self.lessonPlaying?.id === lessonId) {
        const idx = activeClass.allLessons.findIndex(l => l.id === lessonId);
        self.updateDetails(idx);
      }
    },
    setVideoPageActive(isActive) {
      self.isVideoPageActive = isActive;
    },
  }));
