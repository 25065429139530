import axios from 'axios';
import { API, graphqlOperation } from 'aws-amplify';
import { getUser as getUserQuery } from './graphql/queries';

export const fetchCartInfo = async () => {
  const { data } = await axios('/account/personalize', { withCredentials: true });
  return data;
};

export const getUser = async (userId) => {
  const { data } = await API.graphql(
    graphqlOperation(getUserQuery, {
      userId,
    })
  );

  return data.getUser;
};
