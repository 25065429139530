import React, { useEffect, useState } from 'react';

import slugify from 'utils/slugify';
import useScroll from 'utils/useScroll';
import { useRouter } from 'next/router';
import { MobileMenu, MobileMenuItems, MobileMenuItem } from './styles';

const Menu = ({ categories, toggleMenu }) => {
  const [sections, setSections] = useState([]);
  const { pathname, push } = useRouter();

  const onDashboard = pathname === '/user/classes';

  useEffect(() => {
    if (categories?.length) {
      const newSections = categories.map((category) => {
        const node = document.getElementById(slugify(category));
        return node;
      });
      setSections(newSections);
    }
  }, [categories]);

  // disable body scroll when menu is open
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  });

  const { scrollY } = useScroll();
  const actualScrollY = scrollY + 68 * 2;
  const activeCategory = sections.find(
    (section) =>
      section &&
      actualScrollY >= section.offsetTop &&
      actualScrollY <= section.offsetTop + section.offsetHeight
  );

  return (
    <MobileMenu>
      <MobileMenuItems>
        <MobileMenuItem
          active={!activeCategory && onDashboard}
          onClick={() => {
            toggleMenu();
            push('/user/classes');
          }}
        >
          <span>Class Dashboard</span>
        </MobileMenuItem>
        {/* dashboard categories */}
        {onDashboard ? (
          <>
            {categories.map((category) => (
              <MobileMenuItem
                active={activeCategory && activeCategory.id === slugify(category)}
                key={slugify(category)}
                aria-label={category}
                onClick={() => {
                  toggleMenu();
                  setTimeout(() => {
                    document.getElementById(slugify(category)).scrollIntoView();
                  }, 100);
                }}
              >
                <span>{category}</span>
              </MobileMenuItem>
            ))}
          </>
        ) : null}

        <MobileMenuItem dividerTop>
          <a
            href="https://www.mindbodygreen.com/"
            title="mbg home"
            aria-label="mind body green home page"
          >
            mbg home
          </a>
        </MobileMenuItem>
        <MobileMenuItem>
          <a
            href="https://institute.mindbodygreen.com/functional-nutrition-training"
            title="Functional Nutrition"
            aria-label="Functional Nutrition"
          >
            functional nutrition program
          </a>
        </MobileMenuItem>
        <MobileMenuItem>
          <a href="https://shop.mindbodygreen.com/" title="Supplements" aria-label="Supplements">
            supplements
          </a>
        </MobileMenuItem>
        <MobileMenuItem>
          <a href="https://www.mindbodygreen.com/user/home" title="Account" aria-label="Account">
            account
          </a>
        </MobileMenuItem>
      </MobileMenuItems>
    </MobileMenu>
  );
};

export default Menu;
